
// import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
// import { AppSettings } from './../app.settings';
import { Subject } from 'rxjs';
@Injectable()
export class SidebarService {
  menuItems: any;
  private sideSubject = new Subject<any>();
  sideState = this.sideSubject.asObservable();
  adminSideBar = [];
  corpId;
  userDetails;
  accessDetailsList: any;

  constructor() {
  }

}
