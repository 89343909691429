import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';
import { UiService } from '../../../services/ui.service';
import { StorageService } from '../../../services/storage.service';
import { AppSettings } from '../../../app.settings';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.css']
})
export class TemplateComponent implements OnInit {

  @Input() template: any;
  @Output() updateTemplate: EventEmitter<any> = new EventEmitter();
  emailsubmitted = false;
  emailTemplateForm: UntypedFormGroup;

  constructor(
    private franchiseService: FranchiseService,
    private uiService: UiService,
    private storageService: StorageService,
    private formBuilder: UntypedFormBuilder,
    private commonService: CommonBindingDataService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.emailTemplateForm = this.formBuilder.group({
      from: [{ value: this.template ? this.template.fromEmailId : '', disabled: true }],
      cc: [this.template ? this.template.ccEmailId : '',
      [Validators.minLength(1), Validators.maxLength(120), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      bcc: [this.template ? this.template.bccEmailId : '',
      [Validators.minLength(1), Validators.maxLength(120), Validators.pattern(AppSettings.EMAIL_PATTERN)]],
      subject: [this.template ? this.template.subject : '', [Validators.required]],
      template: [this.template ? this.template.template : '', [Validators.required]],
      footer: [this.template ? this.template.footer : '', [Validators.required]],
      franchiseId: [this.template ? this.template.franchiseId : ''],
      createdAt: [this.template ? this.template.createdAt : ''],
      updatedAt: [this.template ? this.template.updatedAt : ''],
      type: [this.template ? this.template.type : ''],
      id: [this.template ? this.template.id : ''],
      sysDefault: false
    });
  }

  checkFormIsValid() {
    if (!this.emailTemplateForm.valid) {
      this.uiService.showMessage(this.commonService.getLabel('error_fill_required_field'), AppSettings.MESSAGE_TYPE.ERROR);
    }
    return this.emailTemplateForm.valid;
  }

  getFormData() {
    const formControl = this.emailTemplateForm.controls;
    const editableModel: any = {
      id: formControl.id.value,
      createdAt: formControl.createdAt.value,
      updatedAt: formControl.updatedAt.value,
      type: formControl.type.value,
      template: formControl.template.value,
      subject: formControl.subject.value,
      franchiseId: formControl.franchiseId.value,
      footer: formControl.footer.value,
      bccEmailId: formControl.bcc.value,
      ccEmailId: formControl.cc.value,
      sysDefault: formControl.sysDefault.value,
      fromEmailId: formControl.from.value
    };
    return editableModel;
  }

  onSave() {
    this.emailsubmitted = true;
    if (this.checkFormIsValid()) {
      if (this.emailTemplateForm.valid) {
        const formModel = this.getFormData();
        this.franchiseService.updateEmailTemplate(formModel, formModel.id).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.updateTemplate.emit();
        }, (error) => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      }
    }
  }

  onCancel() {
    this.goBack();
  }

  goBack() {
    window.history.back();
  }
}
