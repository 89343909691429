import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { StorageService } from './../../services/storage.service';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';

@Component({
  selector: 'app-navtab',
  templateUrl: './navtab.component.html'
})
export class NavtabComponent implements OnInit {
  @Input() type;
  public settingsNavs: MenuItem[];
  activeItem: MenuItem;
  constructor(
    private router: Router,
    private commonBindingDataService: CommonBindingDataService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.settingsNavs = [];

    // const currentInc = this;
    setTimeout(function() {
      // currentInc.showCurrentMenu();
    }, 200);
  }

  // showCurrentMenu() {
  //   switch (this.router.url) {
  //   }
  // }
}
