import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { Chart} from 'chart.js/auto';

@Component({
  selector: 'app-chart-sales-by-industry',
  templateUrl: './chart-sales-by-industry.component.html',
  styleUrls: ['./chart-sales-by-industry.component.scss']
})
export class ChartSalesByIndustryComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;
  actualValue = '';
  comparedValue = '';
  @Input() inputDataObject;
  zeroArray = false;

  chartOptions = {
    radius: '100%',
    plugins: {
      legend: {
        position: 'right',
        align: 'center',
        title: {
          display: true,
          text: 'Industry Type',
          font: {
            family: 'Montserrat',
            size: 15,
            weight: 'bold',
          },
          padding: 5
        },
        labels: {
          usePointStyle: true,
          font: {
            family: 'Montserrat'
          },
        },
      },
      tooltip: {
        titleFont: {
          family: 'Montserrat',
        },
        titleMarginBottom: 6,
        bodyFont: {
          family: 'Montserrat',
        },
        padding: 8,
        boxPadding: 4,
        callbacks: {
          title: () => {
            return 'Total Sales';
          },
          label: (context) => {
            return `${context.label}: $${context.formattedValue}`;
          }
        },
      },
    },
    animation: {
      animateRotate: true,
      animateScale: true,
    }
  };

  constructor() { }

  ngOnInit(): void {
    // this.getSetLastValues();
    if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
      document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
    }
  }

  ngAfterViewInit(): void {
    this.barChartMethod();
  }

  ngOnChanges() {
    this.checkForNonZero();
    console.log(this.inputDataObject);
    this.barChartMethod();
  }

  barChartMethod() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'pie',
      data: {
        labels: this.inputDataObject.labels,
        datasets: [{
          label: 'Sales',
          data: this.inputDataObject.eventSalesDataSet1,
          // weight: 4,
          // backgroundColor: this.getRandomColor(),
          backgroundColor: [
            FILL_COLORS.red,
            FILL_COLORS.pink,
            FILL_COLORS.magenta,
            FILL_COLORS.voilet,
            FILL_COLORS.blue,
            FILL_COLORS.aqua,
            FILL_COLORS.orange,
            FILL_COLORS.yellow,
            FILL_COLORS.green,
            // FILL_COLORS.lime,
            FILL_COLORS.grey,
          ],
        },
        ]
      },
      options: {
        radius: '100%',
        plugins: {
          legend: {
            position: 'right' as any,
            align: 'center',
            title: {
              display: true,
              text: 'Industry Type',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 5
            },
            labels: {
              usePointStyle: true,
              font: {
                family: 'Montserrat'
              },
            },
          },
          tooltip: {
            titleFont: {
              family: 'Montserrat',
            },
            titleMarginBottom: 6,
            bodyFont: {
              family: 'Montserrat',
            },
            padding: 8,
            boxPadding: 4,
            callbacks: {
              title: () => {
                return 'Total Sales';
              },
              label: (context) => {
                return `${context.label}: $${context.formattedValue}`;
              }
            },
          },
        },
        animation: {
          animateRotate: true,
          animateScale: true,
        }
      },
    });
  }

  getRandomColor() {
    const colorList = [];
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < this.inputDataObject.eventSalesDataSet1.length; i++) {
      color += letters[Math.floor(Math.random() * 16)];
      colorList.push(color);
    }

    return colorList;
  }

  generateXAxisLabel(mode) {
    if (mode === "Event Count") {
      return mode;
    } else {
      return mode + " ( in USD )";
    }
  }

  generateUnit(mode?) {
    const selectedMode = mode ? mode : this.inputDataObject.mode;
    if (selectedMode === "Event Count") {
      return '';
    } else {
      return 'US$';
    }
  }

  // getSetLastValues() {
  //   let set1 = this.inputDataObject.eventSalesDataSet1;
  //   let set2 = this.inputDataObject.eventSalesDataSet2;
  //   this.actualValue = set1[set1.length - 1];
  //   this.comparedValue = set2[set2.length - 1];
  // }

  checkForNonZero() {
    this.zeroArray = this.inputDataObject.eventSalesDataSet1.every(item => item === 0);
  }

  ngOnDestroy() {
    this.barChart.destroy();
  }
}

export const FILL_COLORS = {
  red: 'rgb(255, 99, 132)',
  pink: 'rgb(220, 95, 171)',
  magenta: 'rgb(192, 94, 219)',
  voilet: 'rgb(131, 88, 218)',
  blue: 'rgb(54, 162, 235)',
  aqua: 'rgb(0, 255, 255)',
  orange: 'rgb(255, 159, 64)',
  yellow: 'rgb(255, 205, 86)',
  green: 'rgb(100, 200, 125)',
  lime: 'rgb(0, 255, 0)',
  grey: 'rgb(201, 203, 207)',
};
