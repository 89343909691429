import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import zh from '@mobiscroll/angular/dist/js/i18n/zh';
import Chart from 'chart.js/auto';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-bar-chart-prepay-events',
  templateUrl: './bar-chart-prepay-events.component.html',
  styleUrls: ['./bar-chart-prepay-events.component.scss']
})

export class BarChartPrepayEventsComponent implements OnInit, AfterViewInit, OnChanges {

  @ViewChild('barCanvas') barCanvas: ElementRef | undefined;
  barChart: any;
  actualValue = '';
  comparedValue = '';
  @Input() inputDataObject: any =[];
  constructor() { }

  ngOnInit(): void {
    this.getSetLastValues();
    if (document.getElementsByTagName('body')[0].classList.contains('removeAllPadding')) {
      document.getElementsByTagName('body')[0].classList.remove('removeAllPadding');
    }
  }

  ngAfterViewInit(): void {
    this.barChartMethod();
  }

  ngOnChanges() {
    this.barChartMethod();
    console.log('inputDataObject', this.inputDataObject);
  }

  barChartMethod() {
    this.barChart = new Chart(this.barCanvas?.nativeElement, {
      type: 'bar',
      data: {
        labels: this.inputDataObject.labels,
        datasets: [
          {
            xAxisID: 'A',
            label: 'Total Sales',
            data: this.inputDataObject.eventSalesCollected,
            backgroundColor: FILL_COLORS.blue,
            borderColor: BORDER_COLORS.blue,
            borderWidth: 1,
            barThickness: 25,
            maxBarThickness: 40,
          },
          {
            xAxisID: 'B',
            label: 'Online Sales',
            data: this.inputDataObject.onlineSales,
            backgroundColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
            borderColor: AppSettings.GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR,
            borderWidth: 1,
            barThickness: 25,
            maxBarThickness: 40,
          },
          // {
          //   axis: 'y',
          //   xAxisID: 'A',
          //   label: 'Orders',
          //   data: this.inputDataObject.orders,
          //   backgroundColor: FILL_COLORS.green,
          //   borderColor: BORDER_COLORS.green,
          //   borderWidth: 1,
          //   barThickness: 30,
          //   maxBarThickness: 30,
          // }
        ]
      },
      options: {
        indexAxis: 'y',
        scales: {
          A: {
            beginAtZero: true,
            type: 'linear',
            position: 'top',
            max: this.getHighestValue(),
            title: {
              color: 'red',
              display: true,
              text: 'Sales (in USD)',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 5
            },
             grid: {
              // color: 'black',
              // lineWidth: 1,
            },
            border: {
              color: 'black',
              width: 2,
            },
            ticks: {
              display: true,
              // source: 'auto',
              font: {
                family: 'Montserrat',
              },
            }
          },
          B: {
            beginAtZero: true,
            type: 'linear',
            position: 'bottom',
            max: this.getHighestValue(),
            title: {
              color: 'red',
              display: true,
              text: 'Sales (in USD)',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 5
            },
            grid: {
              // color: 'black',
              // lineWidth: 1,
            },
            border: {
              color: 'black',
              width: 2,
            },
            ticks: {
              display: true,
              font: {
                family: 'Montserrat',
              },
              // source: 'auto',
            }
          },
          y: {
            min: 0,
            max: 4,
            title: {
              color: 'red',
              display: true,
              text: 'Events',
              font: {
                family: 'Montserrat',
                size: 15,
                weight: 'bold',
              },
              padding: 15,
            },
            grid: {
              display: false,
              offset: false,
              // color: 'black',
              // lineWidth: 1,
            },
            border: {
              color: 'black',
              width: 2,
            },
            ticks: {
              display: true,

              font: {
                family: 'Montserrat',
              },
              source: 'auto',
            }
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true
            },
          },
          tooltip: {
            yAlign: 'bottom',
            titleFont: {              
                family: 'Montserrat',
                // size: 16,
                weight: 'bold',
            },
            titleMarginBottom: -6,
            bodySpacing: 6,
            bodyFont: {
                family: 'Montserrat',
            },
            padding: 12,
            callbacks: {
              title: (context) => {
                return context[0].label.replace(/,/g, ' ');
              },
              beforeBody: (context) => {
                return '_'.repeat(20);
              },
              label: (context) => {
                // console.log(context);
                const label = [];
                label.push(context.dataset.label === 'Total Sales' ? `Total Sales: $${context.formattedValue}` : `Online Sales: $${context.formattedValue}`);
                if(context.dataset.label == 'Online Sales'){
                  label.push(`Order Count: ${this.inputDataObject.orders[context.dataIndex]}`);
                }
                return label;
              }
            }
          }
        },
        layout: {
          autoPadding: true
        },
        events: ['mousemove', 'mouseout', 'click'],
        interaction: {
          mode: 'nearest',
          axis: 'y'
        },
      },
      plugins: [{
        id: 'barChart',
        beforeInit: function (chart: any) {
          chart.data.labels.forEach(function (value: any, index: number, array: [any]) {
            // var a = [];
            // a.push(value.slice(0, 10));
            // var i = 1;
            // while (value.length > (i * 10)) {
            //   a.push(value.slice(i * 10, (i + 1) * 10));
            //   i++;
            // }
            // array[index] = a;
            if (/\s/.test(value)) {
              array[index] = value.split(/\s/);
            }
          });
        }
      }]
    });

    const scroller = (scroll: { deltaY: number; }, chart: any) => {
      // console.log(scroll);
      // console.log(this.barChart.data.labels);

      const dataLength = this.barChart.data.labels.length;
      if (scroll.deltaY > 0) {
        if (this.barChart.config.options.scales.y.max >= dataLength) {
          this.barChart.config.options.scales.y.min = dataLength - 4;
          this.barChart.config.options.scales.y.max = dataLength;
        } else {
          this.barChart.config.options.scales.y.min += 1;
          this.barChart.config.options.scales.y.max += 1;
        }
      } else if (scroll.deltaY < 0) {
        // console.log('lesser > 0');
        // console.log(this.barChart.config.options.scales.y.min);
        if (this.barChart.config.options.scales.y.min <= 0) {
          this.barChart.config.options.scales.y.min = 0;
          this.barChart.config.options.scales.y.max = 4;
        } else {
          this.barChart.config.options.scales.y.min -= 1;
          this.barChart.config.options.scales.y.max -= 1;
        }
      } else {
        // do nothing
      }
      this.barChart.update();
    };

    this.barChart.canvas.addEventListener('wheel', (e: { deltaY: number; }) => {
      // console.log('wheel');
      scroller(e, this.barChart);
    });
  }

  generateXAxisLabel(mode) {
    if (mode === "Event Count") {
      return mode;
    } else {
      return mode + " ( in USD )";
    }
  }

  generateUnit(mode?) {
    return 'US$';
    // let selectedMode = mode?mode:this.inputDataObject.mode;
    // if (selectedMode === "Event Count") {
    //   return '';
    // } else {
    //   return  'US$';
    // }
  }

  getSetLastValues() {
    const set1 = this.inputDataObject.onlineSales;
    const set2 = this.inputDataObject.eventSalesCollected;
    this.actualValue = set1[set1.length - 1];
    this.comparedValue = set2[set2.length - 1];
  }

  loadMore(type) {
    if (type === 'up') {
      this.barChart.options.scales.y.min = this.barChart.options.scales.y.min - 4;
      this.barChart.options.scales.y.max = this.barChart.options.scales.y.max - 4;
      // console.log('left');

      if (this.barChart.options.scales.y.min <= 0) {
        this.barChart.options.scales.y.min = 0;
        this.barChart.options.scales.y.max = 4;
      }
    }

    if (type === 'down') {
      const dataLength = this.barChart.data.labels.length;

      this.barChart.options.scales.y.min = this.barChart.options.scales.y.min + 4;
      this.barChart.options.scales.y.max = this.barChart.options.scales.y.max + 4;
      // console.log('right');

      if (this.barChart.options.scales.y.max >= dataLength) {
        this.barChart.options.scales.y.min = dataLength - 4;
        this.barChart.options.scales.y.max = dataLength;
      }
    }
    this.barChart.update();
  }

  // getHighestValue() {
  //   let max = Math.max(...this.inputDataObject.eventSalesCollected, ...this.inputDataObject.onlineSales);
  //   return Math.ceil(max) + 1;
  // }

  getHighestValue() {
    // replace this with actual array
    const max = Math.max(...this.inputDataObject.eventSalesCollected, ...this.inputDataObject.onlineSales);
    const digit = Math.floor(Math.log10(max)) + 1;
    let multiplier;
    if (digit === 1) {
      return Math.ceil(max) + 1;
    } else if (digit === 2) {
      multiplier = 1;
    } else if (digit > 2) {
      const str = "1" + "0".repeat(digit - 2);
      multiplier = Number(str);
    }
    multiplier = multiplier * 5;
    // console.log(`Multiplier: ${multiplier}`);
    let roundedMax = Math.round((max) / multiplier) * multiplier;
    if (roundedMax <= max) {
      roundedMax = roundedMax + multiplier;
    }
    // console.log('Rounded: Max' + roundedMax);
    return roundedMax;
  }

  ngOnDestroy() {
    this.barChart.destroy();
  }
}

export const FILL_COLORS = {
  blue: 'rgb(25, 138, 215, 0.7)',
  yellow: 'rgb(247, 212, 32, 0.7)',
  green: 'rgb(77, 179, 96, 0.7)',
};

export const BORDER_COLORS = {
  blue: 'rgb(25, 138, 215, 1)',
  yellow: 'rgb(247, 212, 32, 1)',
  green: 'rgb(77, 179, 96, 1)',
};
