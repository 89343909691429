<div class = "page-layout">
<div class="grid form-container">

   
    <div class="form-field ui-g-12 ui-md-12 lg:col-12">
      <div class="form-errors alert alert-danger" [hidden]='!forgotPasswordErrors[0]["general"]'>
        <div *ngFor="let ErrorMessage of forgotPasswordErrors['general']">
          {{ErrorMessage.message}}
        </div>
      </div>
  
      <form [formGroup]='editforgotForm'>
  
        <div class="form-field col-12 md:col-12 lg:col-12">
          <div class="form-label">
            <label for="username" translate>Username</label>
          </div>
          <div class="form-control">
            <input formControlName="username" type="text" class = "email-lower" pInputText appPreventSomeSpecialCharacters allowQuote="no"/>
          </div>
          <div class="form-errors" *ngIf="editforgotForm.controls.username.errors  && (editforgotForm.controls.username.dirty || editforgotForm.controls.username.touched)">
            <div [hidden]="!editforgotForm.controls.username.errors.required" translate>
                error_email_required
            </div>
            <div [hidden]="!editforgotForm.controls.username.errors.pattern" translate>
                error_email_invalid
            </div>
          </div>
        </div>
  
        <div class="form-buttons col-12 col-nopad">
          <!-- <button class="negative-button1" pButton type="button" icon="fa fa-close" label="Cancel" (click)="closeEditForgot($event)"></button>
          <button pButton type="button" label="Submit" icon="fa fa-check" [disabled]="!(editforgotForm.valid && !submitOnce)" (click)="changePassword($event)"></button> -->

          <button class="negative-button1 cancel-btn" pButton type="button" label="Cancel" (click)="closeEditForgot()" style="width:100px"></button>
          <button class="save-btn" pButton type="button" label="Submit"  (click)="changePassword()" style="width:100px"></button>
        </div>
      </form>
    </div>
  
  </div>
</div>