import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { AdminService } from '../../../admin/services/admin.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-menu-editable-template',
  templateUrl: './menu-editable-template.component.html',
  styleUrls: ['./menu-editable-template.component.scss']
})
export class MenuEditableTemplateComponent implements OnInit {

  eventForm: FormGroup;
  eventDetails;
  templateModelGet;
  submitted = false;
  franchiseId = JSON.parse(this.storageService.getItem('FRANCHISE_ID'));
  templateId;
  pageHeading = this.commonService.getLabel('label_add_template');

  itemDetails;
  menuDetails;
  allItems;
  allSelected = [];
  files: TreeNode[];
  selectedNodes: TreeNode[];
  cols: any[];
  primaryTreeStructure = [];
  storedMenuForTemplate;
  storedSelectedMenu: TreeNode[];
  storedFoodExtraForMenu: any = [];
  storageFormData;
  previousSelectedNodeArray = [];
  loginData;
  isMenuValid = false;
  isUniqueError = false;
  isRequireError = false;
  breadCrumbArray = [];
  brandNameOptions = [];
  loggedInUserId;
  loginUserRole;
  showUpdateClientBtn: boolean = false;
  accessClientReadWrite = this.commonService.checkIfHavePermission('MANAGE_CLIENT', true, true);
  accessMenuReadWrite = this.commonService.checkIfHavePermission('MANAGE_MENU', true, true);
  

  constructor(private commonService: CommonBindingDataService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
    private adminService: AdminService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,) {
      this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
     }

  ngOnInit(): void {
    this.loginUserRole = this.commonService.getLoginRole();
    this.cols = [
      { field: 'name', header: 'Name' },
      { field: 'price', header: 'Price' },
      { field: 'sequence', header: 'Sequence' },
      { field: 'manage', header: 'Manage Food Extra' },
    ];
    const tempSelectedNodes = this.storageService.getItem(AppSettings.MENU_ITEMS_SELECTED_FOR_TEMPLATE);
    const tempAllNodes = this.storageService.getItem(AppSettings.MENU_ITEMS_FOR_TEMPLATE);
    if (tempSelectedNodes) {
      this.storedSelectedMenu = JSON.parse(JSON.parse(this.storageService.getItem(AppSettings.MENU_ITEMS_SELECTED_FOR_TEMPLATE)));
    }
    if (tempAllNodes) {
      this.storedMenuForTemplate = JSON.parse(JSON.parse(this.storageService.getItem(AppSettings.MENU_ITEMS_FOR_TEMPLATE)));
    }
    this.getBrandList(this.loggedInUserId);

    this.templateId = this.route.snapshot.queryParams.id;
    this.initForm();
    if (this.storedMenuForTemplate) {
      console.log('selected', this.storedSelectedMenu);
      // console.log('previous files', this.storedMenuForTemplate)
      this.selectedNodes = [];
      this.selectedNodes = <TreeNode[]>[...this.storedSelectedMenu];
      this.files = <TreeNode[]>this.storedMenuForTemplate;
      this.primaryTreeStructure = <TreeNode[]>this.storedMenuForTemplate;
      this.checkSelectedSequenceValid();
    } else {
      this.getAllMenuItem();
    }

    if (this.templateId) {
      this.pageHeading = this.commonService.getLabel('label_edit_manage_template');
      if (!this.storedMenuForTemplate) {
        this.getTemplateDetails();
      }
      if(this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || AppSettings.STAFF_ROLES['Manager'] && this.templateId){
        this.showUpdateClientBtn = true;
      }
    }
    this.loginData = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA));
    this.createBreadCrumb();
  }

  initForm() {
    const storageFormData = JSON.parse(this.storageService.getItem(AppSettings.TEMPLATE_FORM_RAW_DATA));
    if (storageFormData) {
      this.eventForm = this.formBuilder.group({
        name: [storageFormData ? storageFormData.name : '', [Validators.required]],
        pos: [storageFormData ? storageFormData.pos : ''], // set value as it is from storage
        defaultTemplate: [storageFormData ? storageFormData.defaultTemplate : false],
        brandId: [storageFormData ? storageFormData.brandId : ''],
      });
    } else {
      this.eventForm = this.formBuilder.group({
        name: [this.eventDetails ? this.eventDetails.name : '', [Validators.required]],
        pos: [this.eventDetails ? !this.eventDetails.pos : ''], // pos is false ==> online is true
        defaultTemplate: [this.eventDetails ? this.eventDetails.defaultTemplate : false],
        brandId: [this.eventDetails ? this.eventDetails.brandId : ''],
      });
    }
    if(this.templateId){
      this.eventForm.controls.brandId.disable();
    }
    
  }

  getTemplateDetails() {
    this.adminService.getTemplateByTemplateId(this.templateId).subscribe((result) => {
      this.eventDetails = result;
      // this.getBrandList(this.loggedInUserId, this.eventDetails.brandId);
      this.getSelectedBrand(this.eventDetails);
      this.eventForm.controls.brandId.disable();
      if (this.eventDetails.itemsDtoList && !this.storedMenuForTemplate) {
        this.findAlreadySelected();
      }
      this.initForm();
    });
  }

  findAlreadySelected() {
    this.storageService.removeItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED);
    const storedFoodExtraForMenu = [];
    this.allSelected = [];
    this.eventDetails.itemsDtoList.forEach(eachMenu => {
      if (eachMenu.itemCategoryId && eachMenu.activated) {
        const menu = {
          // parent: eachMenu.foodExtraCategoryId,
          key: 'previouslySelected',
          "data": {
            id: eachMenu.id,
            categoryId: eachMenu.itemCategoryId,
            name: eachMenu.name,
            price: eachMenu.price.toFixed(2),
            sequence: eachMenu.sequence > 0 ? eachMenu.sequence : '',
            signUrl: eachMenu.signUrl,
            isUniqueSequence: true,
            manage: true,
          }
        };
        this.allSelected.push(menu);
      }

      if (eachMenu.foodCategoryItemMappingList) {
        const foodExtraForMenuItem = {
          templateId: this.templateId,
          categoryId: eachMenu.itemCategoryId,
          itemId: eachMenu.id,
          foodExtra: [...eachMenu.foodCategoryItemMappingList]
        };
        storedFoodExtraForMenu.push(foodExtraForMenuItem);
      }
    });
    this.storageService.setItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED, storedFoodExtraForMenu);

    const tempSelected = [];
    this.allSelected.forEach(element => {
      const menuExistIndex = tempSelected.find(e => (e.data.categoryId == element.data.categoryId && e.data.id == element.data.id));
      if (!menuExistIndex) {
        tempSelected.push(element);
      }
    });
    this.allSelected = [];
    this.allSelected = [...tempSelected];

    console.log('tempSelected', tempSelected);
    this.selectedNodes = <TreeNode[]>this.allSelected;
    this.prepareTreeStructure();
  }

  getAllMenuItem(brandId?) {
    // let selectedBrand;
    // this.franchiseService.getmenuItemsItemsWithCategory().subscribe((result) => {
    // selectedBrand = this.eventForm.controls.brandId.value?this.eventForm.controls.brandId.value:
    // console.log(Brand)
    if(brandId){
      this.franchiseService.getmenuItemsItemsWithCategoryWithBrands(brandId).subscribe((result) => {
        this.allItems = result.data;
        // this.getMenuItemDetails();
        this.prepareTreeStructure();
      });
    }
  }

  prepareTreeStructure() {
    this.primaryTreeStructure = [];
    const primaryTree = [];
    if (this.allItems) {
      this.allItems.forEach(eachCat => {
        // console.log('eachCat', eachCat);
        if (eachCat.activated == true) {
          const firstLevel = {
            // partialSelected: true,
            data: {
              id: eachCat.id,
              name: eachCat.categoryName,
              price: '--',
              sequence: '',
              signUrl: '',
              isCategory: true,
              manage: false,
            },
            children: []
          };

          const temp = eachCat.id;
          eachCat.itemList = this.commonService.sortItems(eachCat.itemList, 'name');
          eachCat.itemList.forEach(eachFirstLevelChild => {
            if (eachFirstLevelChild.activated == true) {
              let node = { data: {} };
              node = this.generateTreeNode(eachFirstLevelChild, temp);
              firstLevel['children'].push(node);
            }
          });

          if (firstLevel.children.length > 0) {
            primaryTree.push(firstLevel);
          }
        }
      });
      // console.log('primaryTreeStructure',this.primaryTreeStructure);
      this.prepareFinalTreeStructure(primaryTree);
    }
    // this.files = <TreeNode[]>primaryTree;
    // console.log('files', this.files);

  }

  generateTreeNode(eachItem, eachCatId, key?) {
    // let node;
    // console.log('cat', eachCatId);
    const node = {
      key: key ? key : '',
      data: {
        id: eachItem.id,
        categoryId: eachCatId,
        name: eachItem.name,
        price: eachItem.price.toFixed(2),
        sequence: eachItem.sequence ? eachItem.sequence : 0,
        signUrl: eachItem.signUrl,
        manage: true,
        isUniqueSequence: true,
      }
    };
    return node;
  }

  prepareFinalTreeStructure(primaryTree) {
    // const tree = this.primaryTreeStructure;
    primaryTree.forEach(eachCat => {
      let selectedMenuCount = 0;
      eachCat.children.forEach(eachMenu => {
        if (this.selectedNodes && this.selectedNodes.length > 0) {
          const isMenuSelected = this.selectedNodes.find(menu => (menu.data.id === eachMenu.data.id &&
            menu.data.categoryId === eachMenu.data.categoryId));
          if (isMenuSelected) {
            const changedData = {
              id: isMenuSelected.data.id,
              categoryId: isMenuSelected.data.categoryId,
              name: isMenuSelected.data.name,
              price: isMenuSelected.data.price,
              sequence: isMenuSelected.data.sequence,
              signUrl: isMenuSelected.data.signUrl,
              manage: true,
              isUniqueSequence: true,
            };
            eachMenu.data = changedData;
            selectedMenuCount++;
          }
        }
      });
      if (selectedMenuCount > 0 && eachCat.children.length > 0) {
        if (selectedMenuCount < eachCat.children.length) {
          eachCat.partialSelected = true;
        }
        if (selectedMenuCount >= eachCat.children.length) {
          const tempObj = { ...eachCat.data };
          const tempNode = { data: tempObj };
          this.allSelected.push(tempNode);
        }
      }
    });
    primaryTree = this.sortItems(primaryTree);
    this.primaryTreeStructure = [...primaryTree];
    // console.log('updated primaryTreeStructure', this.primaryTreeStructure);

    this.files = <TreeNode[]>this.primaryTreeStructure;
  }

  sortItems(array) {
    array.sort((a, b) => {
      const fa = a['data']['name'].toLowerCase(),
        fb = b['data']['name'].toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return array;
  }


  findSelectedItems() {
    const selected = [];
    if (this.selectedNodes) {
      this.selectedNodes.forEach(eachElement => {
        if (!eachElement.data.isCategory) {
          selected.push({ key: 'previouslySelected', data: eachElement.data });
        } else {
          selected.push({ data: eachElement.data });
        }
      });
      this.previousSelectedNodeArray = [...selected];
      // console.log('previousSelectedNodeArray', this.previousSelectedNodeArray);
      return this.previousSelectedNodeArray;
    } else {
      return false;
    }

  }

  getMenuItemDetails(event) {
    // console.log('event',event);
    if (event.node.children && event.node.children.length > 0) {
      event.node.children.forEach(eachNode => {
        const selectedMenuId = eachNode.data.id;
        const selectedMenuCatId = eachNode.data.categoryId;
        this.franchiseService.getMenuItemDetails(selectedMenuId).subscribe((result) => {
          const menuDetails = result;
          // this.menuDetails = result;
          this.findAlreadySelectedFoodExtra(menuDetails, selectedMenuCatId, selectedMenuId);
        });
      });
    } else {
      const selectedMenuId = event.node.data.id;
      const selectedMenuCatId = event.node.data.categoryId;
      this.franchiseService.getMenuItemDetails(selectedMenuId).subscribe((result) => {
        const menuDetails = result;
        // this.menuDetails = result;
        this.findAlreadySelectedFoodExtra(menuDetails, selectedMenuCatId, selectedMenuId);
      });
    }

  }

  findAlreadySelectedFoodExtra(menuDetails, selectedMenuCatId, selectedMenuId) {
    const allfoodExtraSelected = [];
    menuDetails.foodCategoryItemMappingList.forEach(eachCat => {
      eachCat.foodItemsIds.forEach(eachMenu => {
        const menu = {
          // partialSelected: true,
          parent: eachCat.foodExtraCategoryId,
          key: 'previouslySelected',
          "data": {
            id: eachMenu.id,
            categoryId: eachCat.foodExtraCategoryId,
            name: eachMenu.foodExtraItemName,
            price: eachMenu.sellingPrice,
            sequence: eachMenu.sequence,
            signUrl: eachMenu.signUrl,
            isUniqueSequence: true,
          }
        };
        allfoodExtraSelected.push(menu);
      });
    });
    this.saveFoodExtraForSelectedMenu(allfoodExtraSelected, selectedMenuCatId, selectedMenuId);
  }

  saveFoodExtraForSelectedMenu(allfoodExtraSelected, selectedMenuCatId, selectedMenuId) {
    const treeStructure = [];
    allfoodExtraSelected.forEach(eachSelected => {
      // let menuInDataSet;
      if ((!eachSelected.children && eachSelected.parent) || (eachSelected.key == 'previouslySelected')) {
        const parentId = (eachSelected.key == 'previouslySelected') ? eachSelected.parent : eachSelected.parent.data.id;
        const parentExistIndex = treeStructure.findIndex(eachParent => eachParent.foodExtraCategoryId == parentId);
        if (parentExistIndex > -1) {
          const childObject = {
            id: eachSelected.data.id,
            sellingPrice: +eachSelected.data.price,
            sequence: +eachSelected.data.sequence
          };
          treeStructure[parentExistIndex].foodItemsIds.push(childObject);
        } else {
          const completeObject = {
            foodExtraCategoryId: parentId,
            foodItemsIds: [
              {
                id: eachSelected.data.id,
                sellingPrice: +eachSelected.data.price,
                sequence: +eachSelected.data.sequence
              },
            ]
          };
          treeStructure.push(completeObject);
        }
      }
      // }
    });
    // console.log('required tree', treeStructure);
    this.saveForTemplate(treeStructure, selectedMenuCatId, selectedMenuId);
  }

  saveForTemplate(treeStructure, selectedMenuCatId, selectedMenuId) {
    this.storedFoodExtraForMenu = [];
    this.storedFoodExtraForMenu = JSON.parse(this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED));
    const foodExtraForMenuItem = {
      templateId: this.templateId ? this.templateId : '',
      categoryId: selectedMenuCatId,
      itemId: selectedMenuId,
      foodExtra: [...treeStructure]
    };
    if (this.storedFoodExtraForMenu) {
      const existItemIndex = this.storedFoodExtraForMenu.findIndex(e =>
      (e.templateId == foodExtraForMenuItem.templateId && e.categoryId == foodExtraForMenuItem.categoryId
        && e.itemId == foodExtraForMenuItem.itemId));
      if (existItemIndex > -1) {
        if (this.storedFoodExtraForMenu[existItemIndex].foodExtra.length == 0) {
          this.storedFoodExtraForMenu[existItemIndex] = foodExtraForMenuItem;
        }
      } else {
        this.storedFoodExtraForMenu.push(foodExtraForMenuItem);
      }
    } else {
      const tempArr = [{ ...foodExtraForMenuItem }];
      this.storedFoodExtraForMenu = [...tempArr];
    }
    this.storageService.setItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED, this.storedFoodExtraForMenu);
  }


  checkIfCategoryForSequence(event) {
    if (event.node.data.isCategory) {
      let sequenceCount = 1;
      event.node.children.forEach(element => {
        element.data.sequence = element.data.sequence ? element.data.sequence : sequenceCount;
        sequenceCount++;
      });
    }
  }

  checkInNodesTree(event, sequence) {
    this.primaryTreeStructure.forEach(eachCat => {
      // const selectedMenuCount = 0;
      eachCat.children.forEach(eachMenu => {
        if (eachMenu.data.id === event.node.data.id &&
          eachMenu.data.categoryId === event.node.data.categoryId) {
          eachMenu.data.sequence = +eachMenu.data.sequence > 0 ? eachMenu.data.sequence : sequence;
          eachMenu.data['isUniqueSequence'] = (sequence == 0) ? true : eachMenu.data['isUniqueSequence'];
        }
        if (event.node.data.isCategory && eachMenu.data.categoryId === event.node.data.id) {
          eachMenu.data.sequence = +eachMenu.data.sequence ? eachMenu.data.sequence : sequence;
          eachMenu.data['isUniqueSequence'] = (sequence == 0) ? true : eachMenu.data['isUniqueSequence'];
        }
      });

    });
    this.files = <TreeNode[]>this.primaryTreeStructure;
    this.checkSelectedSequenceValid();
  }

  checkSelectedSequenceValid() {
    let isExist = null;
    this.isUniqueError = false;
    this.isRequireError = false;
    if (!this.selectedNodes || this.selectedNodes.length == 0) {
      this.isMenuValid = false;
      return;
    }
    // console.log('selected nodes',this.selectedNodes);
    this.selectedNodes.forEach(element => {
      if (element.data && !element.data.isCategory && !element.data.isUniqueSequence) {
        isExist = element;
        this.isUniqueError = true;
      }
      if (element.data && !element.data.isCategory && (element.data.sequence == '' || element.data.sequence == null)) {
        isExist = element;
        this.isRequireError = true;
      }
      if (element.key && element.key == 'previouslySelected') {
        isExist = null;
      }
    });
    if (isExist || this.isUniqueError || this.isRequireError) {
      this.isMenuValid = false;
    } else {
      this.isMenuValid = true;
    }

    // console.log('isExist', isExist);
  }

  valueChanged(changedNode) {

    const nodeIndexInSelected = this.selectedNodes.findIndex(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == changedNode.categoryId &&
      eachNode.data.id == changedNode.id);
    if (nodeIndexInSelected > -1) {
      this.selectedNodes[nodeIndexInSelected].data.sequence = +changedNode.sequence > 0 ? changedNode.sequence : '';
      this.selectedNodes[nodeIndexInSelected].data.price = changedNode.price;
    }

    const allSelected = [...this.selectedNodes];
    const catId = changedNode.isCategory ? changedNode.id : changedNode.categoryId;
    const nodesInCategory = allSelected.filter(eachNode => eachNode.data.categoryId && eachNode.data.categoryId == catId);
    nodesInCategory.forEach(eachNodeInCat => {
      const isExist = nodesInCategory.find(eachNode => eachNode.data.categoryId == eachNodeInCat.data.categoryId &&
        eachNode.data.id !== eachNodeInCat.data.id && +eachNode.data.sequence == +eachNodeInCat.data.sequence &&
        eachNode.data.sequence > 0);
      if (isExist) {
        eachNodeInCat.data['isUniqueSequence'] = false;
        this.checkInNodesTreeForFlag(eachNodeInCat, false);
      } else {
        eachNodeInCat.data['isUniqueSequence'] = true;
        this.checkInNodesTreeForFlag(eachNodeInCat, true);
      }
    });
    this.selectedNodes = [...allSelected];

    this.checkSelectedSequenceValid();
    // console.log('selected nodes', this.selectedNodes);
  }

  checkInNodesTreeForFlag(node, uniqueFlag) {
    // console.log('node:',node, uniqueFlag)
    this.primaryTreeStructure.forEach(eachCat => {
      // const selectedMenuCount = 0;
      eachCat.children.forEach(eachMenu => {
        if (eachMenu.data.id === node.data.id &&
          eachMenu.data.categoryId === node.data.categoryId) {
          // console.log('node found:',eachMenu)
          eachMenu.data['sequence'] = node.data.sequence > 0 ? node.data.sequence : '';
          eachMenu.data['isUniqueSequence'] = uniqueFlag;
        }
      });
    });
    this.files = <TreeNode[]>this.primaryTreeStructure;
    this.checkSelectedSequenceValid();
  }

  nodeSelect(event) {
    // console.log('name selected', event)
    // console.log('selected nodes', this.selectedNodes)
    this.getMenuItemDetails(event);
    this.checkIfCategoryForSequence(event);
    this.checkInNodesTree(event, '');
    this.valueChanged(event.node.data);
    // this.findSelectedItems();
    // console.log('files', this.files);
  }

  nodeUnselect(event) {
    // console.log('name unSelected', event)
    // console.log('selected nodes11', this.selectedNodes)
    this.checkInNodesTree(event, 0);
    this.valueChanged(event.node.data);
    // this.findSelectedItems();
    // console.log('files', this.files);
  }

  onClickManage(menu) {
    const eventFormTempRawData = this.eventForm.getRawValue();
    // console.log('manage menu', menu);
    const selectedMenu = {
      templateId: this.templateId ? this.templateId : '',
      menu: menu,
      from: 'template'
    };
    // console.log("this.files" + this.files);
    this.storageService.setItem(AppSettings.MENU_ITEM_SELECTED, selectedMenu);
    const allMenusInStringTree = this.stringify(this.files);
    const selectedMenusInStringTree = this.stringify(this.findSelectedItems());
    if (selectedMenusInStringTree == 'false') {
      this.uiService.showMessage(this.commonService.getLabel('Please select menu items!'), AppSettings.MESSAGE_TYPE.ERROR);
      return false;
    } else {
      this.storageService.setItem(AppSettings.MENU_ITEMS_FOR_TEMPLATE, allMenusInStringTree);
      this.storageService.setItem(AppSettings.MENU_ITEMS_SELECTED_FOR_TEMPLATE, selectedMenusInStringTree);
      this.storageService.setItem(AppSettings.TEMPLATE_FORM_RAW_DATA, eventFormTempRawData);
      // console.log('menuTree', this.files);
      if (this.loginData) {
        const data = this.loginData;
        if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']) {
          this.router.navigate(['franchise/menu-section/menu-items/menu-details'], { queryParams: { id: menu.id } });
        } else {
          this.router.navigate(['admin/menu-section/menu-items/menu-details'], { queryParams: { id: menu.id } });
        }
      }
    }

  }

  stringify(obj) {
    let cache = [];
    const str = JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }

  onSaveClientTemp(){
    this.submitted = true;
    this.checkSelectedSequenceValid();
    if (this.eventForm.valid) {
      if (this.isMenuValid) {
        // console.log('selected11', this.selectedNodes);
        const templateModel = this.getFormData();
        const menuItemsToSend = this.prepareMenuForRequestBody();
        templateModel.itemsDtoList = [...menuItemsToSend];
        // console.log('requesttreeMenu', menuItemsToSend)
        // return;
        templateModel.updateRefrenceClients = true;
        if (this.templateId) {
          this.confirmationService.confirm({
            message: `This will update all the client menus which have used ${this.eventDetails?.name?this.eventDetails.name:'template'}. Do you want to proceed?`,
            accept: () => {
              this.adminService.updateTemplateDetails(templateModel).subscribe((result) => {
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                if (this.loginData) {
                  const data = this.loginData;
                  if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.USER_ROLE_MANAGER) {
                    this.router.navigate(['franchise/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
                  } else {
                    this.router.navigate(['admin/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
                  }
                }
              }, (error) => {
                console.log(error);
              });
            }
          });
        }
      } else {
        if (this.isRequireError) {
          this.uiService.showMessage(this.commonService.getLabel('error_menu_sequence_required'), AppSettings.MESSAGE_TYPE.ERROR);
          return;
        }
        if (this.isUniqueError) {
          this.uiService.showMessage(this.commonService.getLabel('error_menu_sequence_unique'), AppSettings.MESSAGE_TYPE.ERROR);
          return;
        }
        this.uiService.showMessage(this.commonService.getLabel('error_invalid_menu'), AppSettings.MESSAGE_TYPE.ERROR);
      }
    }
  }

  onSave() {
    this.submitted = true;
    this.checkSelectedSequenceValid();
    if (this.eventForm.valid) {
      if (this.isMenuValid) {
        // console.log('selected11', this.selectedNodes);
        const templateModel = this.getFormData();
        const menuItemsToSend = this.prepareMenuForRequestBody();
        templateModel.itemsDtoList = [...menuItemsToSend];
        // console.log('requesttreeMenu', menuItemsToSend)
        // return;
        templateModel.updateRefrenceClients = false;
        if (this.templateId) {
          this.confirmationService.confirm({
            message: this.commonService.getLabel('msg_edit_template'),
            accept: () => {
              this.adminService.updateTemplateDetails(templateModel).subscribe((result) => {
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                if (this.loginData) {
                  const data = this.loginData;
                  if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.USER_ROLE_MANAGER) {
                    this.router.navigate(['franchise/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
                  } else {
                    this.router.navigate(['admin/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
                  }
                }
              }, (error) => {
                console.log(error);
              });
            }
          });
        } else {
          this.confirmationService.confirm({
            message: this.commonService.getLabel('msg_add_template'),
            accept: () => {
              this.adminService.addTemplates(templateModel).subscribe((result) => {
                this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
                if (this.loginData) {
                  const data = this.loginData;
                  if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.USER_ROLE_MANAGER) {
                    this.router.navigate(['franchise/menu-section/templatelist']);
                  } else {
                    this.router.navigate(['admin/menu-section/templatelist']);
                  }
                }

              }, (error) => {
                console.log(error);
              });
            }
          });
        }
      } else {
        if (this.isRequireError) {
          this.uiService.showMessage(this.commonService.getLabel('error_menu_sequence_required'), AppSettings.MESSAGE_TYPE.ERROR);
          return;
        }
        if (this.isUniqueError) {
          this.uiService.showMessage(this.commonService.getLabel('error_menu_sequence_unique'), AppSettings.MESSAGE_TYPE.ERROR);
          return;
        }
        this.uiService.showMessage(this.commonService.getLabel('error_invalid_menu'), AppSettings.MESSAGE_TYPE.ERROR);
      }
    }
  }

  getFormData() {
    const formControl = this.eventForm.controls;
    const templateModel: any = {
      name: formControl.name.value,
      pos: !formControl.pos.value,
      'brandId': formControl.brandId.value,
      defaultTemplate: formControl.defaultTemplate.value,
      franchiseId: this.franchiseId,
      description: null,
      type: null,
      activated: true,
      // itemCategoryIdList: null
      itemsDtoList: [],
    };
    if (this.eventDetails && this.eventDetails.id || this.templateId) {
      templateModel.id = this.eventDetails ? this.eventDetails.id : this.templateId;
    }
    return templateModel;
  }

  prepareMenuForRequestBody() {
    const treeStructure = [];
    if (this.selectedNodes) {
      this.selectedNodes.forEach(eachSelected => {
        // let menuInDataSet;
        if ((eachSelected && !eachSelected.data.isCategory) || (eachSelected.key == 'previouslySelected')) {
          // const tempSelected = eachSelected;
          const childObject = {
            id: eachSelected.data.id,
            itemCategoryId: eachSelected.data.categoryId,
            price: +eachSelected.data.price,
            sequence: +eachSelected.data.sequence,
            gift: false,
            foodCategoryItemMappingList: [],
          };
          childObject.foodCategoryItemMappingList = this.prepareFoodExtraForRequestBody(eachSelected);
          treeStructure.push(childObject);
        }
      });
    }

    return treeStructure;
  }

  prepareFoodExtraForRequestBody(eachSelected) {
    let foodExtraCat = [];

    const tempStoredExtraNodes = this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED);

    if (tempStoredExtraNodes) {
      const tempSelectedNodes = JSON.parse(this.storageService.getItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED));
      // console.log('tempSelectedNodes', tempSelectedNodes)
      const foodExtrasForMenu = tempSelectedNodes.find(e => (e.categoryId == eachSelected.data.categoryId &&
        e.itemId == eachSelected.data.id));
      if (foodExtrasForMenu) {
        foodExtraCat = [...foodExtrasForMenu.foodExtra];
      }
    }
    return foodExtraCat;
  }

  onCancelAndGoBack() {
    if (this.loginData) {
      const data = this.loginData;
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']) {
        this.router.navigate(['franchise/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
      } else {
        this.router.navigate(['admin/menu-section/templatelist'], { queryParams: { fromCancel: 'fromCancel' } });
      }
    }
  }

  createBreadCrumb() {
    this.breadCrumbArray = [
      {
        label: this.commonService.getLabel('label_manage_template'),
        route: '',
        queryParams: { fromCancel: 'fromCancel' }
      },
      {
        label: this.pageHeading,
        route: '',
      }
    ];
    if (this.loginData) {
      const data = this.loginData;
      this.breadCrumbArray.forEach(bcrum => {
        if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']) {
          bcrum.route = 'franchise/menu-section/templatelist';
        } else {
          bcrum.route = 'admin/menu-section/templatelist';
        }
      });
    }
  }

  onChangeBrand(event) {
    // let selectedBrand;
    // selectedBrand = this.eventForm.controls.brandId.value?this.eventForm.controls.brandId.value:
    this.getAllMenuItem(event.value);
  }

  getBrandList(id) {
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      this.franchiseService.getBrandList(id).subscribe((result) => {
        this.brandNameOptions = result.map(element => ({ label: element.brandName, 
          value: element.brandId,
          activated: element.activated,
          disabled: !element.activated,
          deleted: element.deleted, inactive: false }));
          setTimeout(() => {
            this.getSelectedBrand();
          }, 900);
      });
    }else {
      this.adminService.getAllBrandsList().subscribe((result) => {
        this.brandNameOptions = result.map(element => ({ label: element.brandName, 
          value: element.id,
          activated: element.activated, 
          deleted: element.deleted, inactive: false }));
          setTimeout(() => {
            this.getSelectedBrand();
          }, 900);
      });
    }
  }

  getSelectedBrand(details?) {
    if(details) {
      this.eventForm.controls.brandId.setValue(details?.brandId);
      if (!this.storedMenuForTemplate) {
        this.getAllMenuItem(details?.brandId);
      }
    } else {
      const selectedBrand = this.eventForm.controls.brandId.value?this.eventForm.controls.brandId.value:
      this.brandNameOptions[0].value;
      if (!this.storedMenuForTemplate) {
        this.getAllMenuItem(selectedBrand);
      }
    }
  }

}
