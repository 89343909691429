import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AdminService } from '../../../admin/services/admin.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';

@Component({
  selector: 'app-manage-template-list', //**  used in super admin */
  templateUrl: './manage-template-list.component.html',
  styleUrls: ['./manage-template-list.component.scss']
})
export class ManageTemplateListComponent implements OnInit {
  franchiseId = JSON.parse(this.storageService.getItem('FRANCHISE_ID'));
  show: boolean = true;

  params = '?searchText=&limit=10&offset=0';
  gridDefaultUrl = '/secure/templates/grid-data';
  gridurl = `/secure/templates`;
  //gridurl1 =`/secure/templates +${this.franchiseId}'
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  exportButton = 'top';
  ID;
  search;
  selectedStatus;
  // params;
  valuesOptions: any = [];
  selectedValue = '';
  timer = null;
  uniqueId = 'menu_Admin_Template_List_01';
  saveFromCancelOffset: boolean = false;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  brandNameOptions = [];
  selectedBrandFromFilter;
  loginUserRole;
  isInputBrandId: boolean = false;
  previousBrand = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private adminService: AdminService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,) {
  }

  ngOnInit() {
    this.loginUserRole = this.commonBindingDataService.getLoginRole();
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
      this.isInputBrandId = true;
    }else {
      this.isInputBrandId = false;
    }
    this.commonBindingDataService.clearTemplateStorage();
    this.getBrandList();
    this.ID = this.route.snapshot.queryParams.id;
    // this.getMenuImagesList();
    this.route.queryParams
    .subscribe(params => {
      if (params.fromCancel) {
        this.saveFromCancelOffset = this.franchiseService.onlyPageOffSetSave;
        this.checkIfPreviousFilters();
      }else {
        this.saveFromCancelOffset = false;
        this.franchiseService.MenuCommonSearchFilters.search = '';
      }
    });

    this.createGrid();
    setTimeout(() => {
      this.franchiseService.onlyPageOffSetSave = false;
      this.saveFromCancelOffset = false;
    }, 1000);
  }
  postData: any = {
    sortColumn: null,
    sortType: null,
    searchText: '',
    franchiseId: null,
    activated: '',
    applyActivatedStatus: '',
    assetTypeIdList: [],
  };
  createGrid() {
    this.show = true;
    this.columns.push({
      field: 'name', width: '140px',
      header: this.commonBindingDataService.getLabel('label_template_name'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'brandName', width: '100px',
      header: this.commonBindingDataService.getLabel('label_brandName'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'pos', width: '140px',
      header: this.commonBindingDataService.getLabel('Online Menu'), sort: true, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const value = data.pos === true ? 'No' : 'Yes';
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      }
    });

    this.columns.push({
      field: 'defaultTemplate', width: '120px',
      header: this.commonBindingDataService.getLabel('Default Menu'), sort: true, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        data['defaultTemplateBoolean'] = data.defaultTemplate ? true : false;
        const value = data.defaultTemplate === true ? 'Yes' : 'No';
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      }

    });

    this.buttons.push({ icon: '', class: 'edit-pencil-icon', label: 'Edit' });
    this.buttons.push({
      field: 'eventHold',
      label: '',
      class: 'event-hold-icon', show: (data) => {
        if (data.activated) {
          return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
        } else {
          return '<div class="event-open-icon activate" title = "Activate"></div>';
        }
      }
    });
    this.buttons.push({ icon: '', class: 'event-delete-icon', label: 'Delete Template' });
    // this.buttons.push({ icon: '', class: 'details-icon', label: 'View Template Details' });
  }

  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'edit-pencil-icon':
          this.franchiseService.onlyPageOffSetSave = true;
          this.router.navigate(['admin/menu-section/templatelist/add-templatelist'], { queryParams: { id: data.id } });
          break;
        case 'event-hold-icon':
          this.changeEventStatus(data);
          break;
        case 'details-icon':
          this.router.navigate(['admin/menu-section/templatelist/manage-categories'],
            { queryParams: { id: data.id } });
          break;
          case 'event-delete-icon':
            this.deleteTemplate (data);
          break;
      }
    }
  }

  deleteTemplate(data) {
    let objectToSend = {};
    if (data.defaultTemplateBoolean) {
      const msg = this.commonBindingDataService.getLabel('error_please_set_other_as_default_template_delete');
      this.uiService.showMessage(msg, AppSettings.MESSAGE_TYPE.ERROR);
      return;
    }
    objectToSend = {
      id: data.id,
      deleted: true,
    };
    this.confirmationService.confirm({
      message: this.commonBindingDataService.getLabel('msg_template_delete'),
      accept: () => {
        this.adminService.deleteTemplates(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.updatedUrl = this.gridurl + this.params;
          this.gridRef.updateUrl(this.updatedUrl);
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  changeEventStatus(data) {
    let objectToSend = {};
    if (data.defaultTemplateBoolean) {
      const msg = this.commonBindingDataService.getLabel('error_please_set_other_as_default_template_deactive');
      this.uiService.showMessage(msg, AppSettings.MESSAGE_TYPE.ERROR);
      return;
    }
    let msg = this.commonBindingDataService.getLabel('msg_template_activate');
    if (data.activated) {
      msg = this.commonBindingDataService.getLabel('msg_template_hold');
    }
    objectToSend = {
      id: data.id,
      activated: !data.activated,
    };
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.adminService.activeInactiveTemplates(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.updatedUrl = this.gridurl + this.params;
          this.gridRef.updateUrl(this.updatedUrl);
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  getBrandList() {
    this.adminService.getAllBrandsList().subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.id, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      this.onClickFilter();
    });
  }

  onSelectBrand(e) {
    if(this.selectedBrandFromFilter.length === 0){
      this.previousBrand[0] = e.itemValue;
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
      }, 300);
    }
    setTimeout(() => {
      this.onClickFilter();
    }, 300);
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  onAdd() {
    this.router.navigate(['admin/menu-section/templatelist/add-templatelist']);
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^' + qoutes + ' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.postData.searchText = this.search;
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  checkIfPreviousFilters() {
    this.saveFromCancelOffset = true;
    const previousFilters = this.franchiseService.MenuCommonSearchFilters;
    if (previousFilters.search) {
      this.search = previousFilters.search ? previousFilters.search.trim() : '';
      setTimeout(() => this.applyFilter('apply', true), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  applyFilter(event?, fromCancel?) {
    if (fromCancel !== true) {
      this.storageService.removeItem('listNavigatedOffset');
      this.gridRef.offset = 0;
    }
    if (event === 'apply') {
      this.params = '';

      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE) {
        this.params = '';
      }else {
        this.params += `?brandIds=${this.selectedBrandFromFilter}`;
      }

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
        this.franchiseService.MenuCommonSearchFilters.search = this.search.trim();
      }

      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }
  goBack() {
    window.history.back();
  }

}
