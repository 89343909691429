import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { AdminService } from "../../../admin/services/admin.service";
import { AppSettings } from "../../../shared/app.settings";
import { MiGridComponent } from "../../../shared/components/mi-grid/mi-grid.component";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { StorageService } from "../../../shared/services/storage.service";
import { UiService } from "../../../shared/services/ui.service";
import { FranchiseService } from "../../services/franchise-service.service";

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.scss"],
})
export class CustomerListComponent implements OnInit {
  //params;
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = "top";
  isExportVisible = false;
  loginData: any;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  // Form params
  defaultToday = new Date();
  franchiseList = [];
  search;
  franchiseName;
  timer = null;
  // franchiseId;
  showGenerateReport: boolean;
  generalStatusOptions = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS;
  StatusOptions = AppSettings.STATUS_LIST_FILTER_OPTIONS;
  selectedStatus = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS[0].value;
  postData: any = {
    sortColumn: null,
    sortType: null,
    searchText: "",
    franchiseId: null,

    activated: this.selectedStatus,
    // toDate: new Date((new Date(new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth(), this.defaultToday.getDate()).setDate(0))).getTime()).setHours(23, 59, 59),
    //fromDate: new Date((new Date(new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth()-1,1, this.defaultToday.getDate()).setDate(1))).getTime()).setHours(0, 0, 1),
    //franchiseId,
    //applyActivatedStatus: '',
    assetTypeIdList: [],
  };
  activated: boolean;
  gridurl1;

  franchiseId = JSON.parse(this.storageService.getItem("FRANCHISE_ID"));

  params =
    "?applyActivatedStatus=true&activated=true&franchiseId=" + this.franchiseId;

  gridDefaultUrl = "/secure/customers/admin/grid-data";
  // Grid params
  gridurl = `${this.gridDefaultUrl}${this.params}`;

  exportBaseUrl = "/secure/customers/admin/export-xls";
  exportCompleteUrl = "/secure/customers/admin/export-xls";
  updatedUrl = "";
  constructor(
    private router: Router,
    // private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    // private errorBindingService: ErrorBindingService,
    private uiService: UiService,
    private adminService: AdminService,
    private franchiseService: FranchiseService
  ) {}

  ngOnInit(): void {
    this.franchiseId = JSON.parse(this.storageService.getItem("FRANCHISE_ID"));
    this.createGrid();
    this.exportCompleteUrl = this.exportBaseUrl;
  }

  createGrid() {
    this.columns.push({
      field: "firstName",
      width: "150px",
      header: this.commonBindingDataService.getLabel("First Name"),
      sort: false,
      resize: true,
      class: "eclipse",
    });

    this.columns.push({
      field: "lastName",
      width: "150px",
      header: this.commonBindingDataService.getLabel("Last Name"),
      sort: false,
      resize: true,
      class: "eclipse",
    });

    this.columns.push({
      field: "email",
      width: "210px",
      header: this.commonBindingDataService.getLabel("label_email"),
      sort: false,
      resize: true,
      class: "eclipse",
    });

    this.columns.push({
      field: "phoneNumber",
      width: "160px",
      header: this.commonBindingDataService.getLabel("label_phone"),
      sort: false,
      resize: true,
      class: "eclipse",
      htmlRenderer: (data) => {
        const value = `${data.phoneNumber}`;
        const t = this.commonBindingDataService.telFormat(value);
        const finalValue = `<div title = "${value}">${t}</div>`;
        return finalValue;
      },
    });

    this.columns.push({
      field: "city",
      width: "110px",
      header: this.commonBindingDataService.getLabel("label_city"),
      sort: false,
      resize: true,
      class: "eclipse",
    });

    this.columns.push({
      field: "state",
      width: "110px",
      header: this.commonBindingDataService.getLabel("label_state"),
      sort: false,
      resize: true,
      class: "eclipse",
    });

    this.columns.push({
      field: "zipCode",
      width: "110px",
      header: this.commonBindingDataService.getLabel("Zipcode"),
      sort: false,
      resize: true,
      class: "eclipse",
    });
    this.columns.push({
      field: "ordersCount",
      width: "110px",
      header: this.commonBindingDataService.getLabel("No. of orders"),
      sort: false,
      resize: true,
      class: "eclipse",
    });
    this.columns.push({
      field: "ordersTotal",
      width: "110px",
      header: this.commonBindingDataService.getLabel("Order Total"),
      sort: false,
      resize: true,
      class: "eclipse",
    });
    this.columns.push({
      field: "Registered/Not registered",
      width: "170px",
      header: this.commonBindingDataService.getLabel("User Type"),
      sort: false,
      resize: true,
      class: "eclipse",
      htmlRenderer: (data) => {
        const value =
          data.registered == true ? "Registered User" : "Guest User";
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      },
    });

    this.columns.push({
      field: "Receive Promo",
      width: "110px",
      header: this.commonBindingDataService.getLabel("Receive Promo"),
      sort: false,
      resize: true,
      class: "eclipse",
      htmlRenderer: (data) => {
        const value = data.allowPromoNotifications == true ? "yes" : "No";
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      },
    });

    this.buttons.push({
      field: "profileImageFileId",
      label: "",
      class: "customer-status-icon",
      show: (data) => {
        if (data.activated) {
          return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
        } else {
          return '<div class="event-open-icon activate" title = "Activate"></div>';
        }
      },
    });
  }

  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case "customer-status-icon":
          this.changeCustomerStatus(data);
          break;
      }
    }
  }

  onClickFilter() {
    this.applyFilter("apply");
  }

  changeCustomerStatus(data) {
    let objectToSend = {};
    let msg = "";
    if (data.activated === true) {
      objectToSend = {
        userId: data.userId,
        activated: false,
      };
      msg = this.commonBindingDataService.getLabel("msg_customer_deactivate");
    } else {
      objectToSend = {
        userId: data.userId,
        activated: true,
      };
      msg = this.commonBindingDataService.getLabel("msg_customer_activate");
    }
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activeInactiveCustomer(objectToSend).subscribe(
          (result) => {
            this.uiService.showMessage(
              result.general[0].message,
              AppSettings.MESSAGE_TYPE.SUCCESS
            );
            this.applyFilter("apply");
          },
          (error) => {
            console.log(error);
          }
        );
      },
    });
  }

  applyFilter(event?) {
    if (event === "apply") {
      this.params = "";
      let searchValue = "";
      // let franchiseId;
      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
        searchValue = this.search.trim();
      }
      this.params = `?activated=${this.selectedStatus}`;
      if (this.selectedStatus) {
        this.params = `?applyActivatedStatus=true&activated=${this.selectedStatus}`;
        this.postData.applyActivatedStatus = true;
        this.postData.activated = this.selectedStatus;
      } else {
        this.params = `?applyActivatedStatus=false&activated=${this.selectedStatus}`;
        this.postData.applyActivatedStatus = false;
        this.postData.activated = this.selectedStatus;
      }
      this.franchiseId = JSON.parse(
        this.storageService.getItem("FRANCHISE_ID")
      );
      if (this.franchiseId) {
        this.params += `&franchiseId=${this.franchiseId}`;
        this.postData.franchiseId = this.franchiseId;
      }

      this.postData.activated = this.selectedStatus;

      this.updatedUrl = this.gridDefaultUrl + this.params;
      this.exportCompleteUrl = "/secure/customers/admin/export-xls";
      // this.exportCompleteUrl = this.exportCompleteUrl + this.params + (`&searchText=${searchValue}`);
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  exportExcel() {
    //this.gridRef.exportGridExcel();
    this.showGenerateReport = true;
  }

  clickExportFranchise(fromLink) {
    this.showGenerateReport = true;
    this.exportCompleteUrl = fromLink;
    //this.franchiseService.downloadExcelAdminReport(fromLink);
  }

  close(status: boolean) {
    this.showGenerateReport = status;
  }

  isRecordsVisible(e) {
    this.isExportVisible = e;
  }

  onAddFranchise() {
    this.router.navigate(["admin/franchise/add-franchise"]);
  }

  onSearch(e) {
    const regexStr = "^[a-zA-Z0-9_\b ]*$";
    const str = /[a-zA-Z0-9]/;
    if (
      !new RegExp(regexStr).test(e.key) ||
      e.keyCode === 16 ||
      e.keyCode === 18
    ) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === "") {
        return;
      }
      if (this.search && this.search.length >= 3 && !str.test(this.search)) {
        return;
      }
      clearTimeout(this.timer);
      this.postData.searchText = this.search;
      this.timer = setTimeout(
        () => this.applyFilter("apply"),
        AppSettings.SEARCH_DELAY_INTERVAL
      );
    }
  }

  onResetData() {
    if (this.franchiseName == "") {
      this.applyFilter("apply");
    }
  }
}
