<div class="franchise page-layout">
  <div class="signin-box-container">
    <div class="signin-box-inner-container">
      <div class="signin-image-wrapper lg:col-7 md:col-4 p-0">
        <img src="/assets/images/login_image.png" />
      </div>

      <div class="signin-box lg:col-5 md:col-8">
        <div class="company-logo">
          <img *ngIf = "!staffLogin" width="200" src="/assets/images/kona_OS_icon.png" />

          <div *ngIf = "staffLogin">
            <div style="text-align: center;">
              <img width="200" src="/assets/images/staff/staff_login_upper_logo.png" />
            </div>
            <div style="text-align: center;">
              <img width="200" src="/assets/images/staff/staff_login_lower_image.svg" />
            </div>
          </div>
        </div>

        <p-panel >
       
          <form [formGroup]="signInForm">
            <div class="form-container grid" style="justify-content: start;">
              <div class="form-errors col-12" style="padding-left: 0.5rem">
                <!-- <div [hidden]="!loginErrors.general.hasError" class="alert alert-danger">
                  {{ loginErrors.general.error?loginErrors.general.error:'' }}
                </div> -->
              </div>

              <div class="form-field col-12 lg:col-12 md:col-10">
                <!-- <div class="form-label">
                  <label for="username" translate>label_email</label>
                </div> -->
                <div class="form-control">
                  <input formControlName="username" class="full-width email-lower" type="text"
                    (keypress)="eventHandler($event)" pInputText [placeholder]="label_Email_inline" appPreventSomeSpecialCharacters allowQuote="no"/>
                </div>
                <div class="form-errors" *ngIf="
                  signInForm.controls.username.errors &&
                  (signInForm.controls.username.dirty ||
                    signInForm.controls.username.touched)
                ">
                  <div [hidden]="!signInForm.controls.username.errors.required" translate>
                    error_email_id_required
                  </div>
                  <div [hidden]="!signInForm.controls.username.errors.pattern" translate>
                    error_email_id_invalid
                  </div>
                </div>
              </div>

              <div class="form-field col-12 lg:col-12 md:col-10">
                <!-- <div class="form-label">
                  <label for="passwd" translate> label_password </label>
                </div> -->
                <div class="form-control position-rel">
                  <input formControlName="password" [type]="passwordType" class="full-width"
                    (keypress)="eventHandler($event)" pInputText placeholder="Password" autocomplete="off"/>
                  <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()"
                    >
                    <i *ngIf="passwordType === 'password'" class="hide-password-icon" aria-hidden="true"></i>
                    <i *ngIf="passwordType !== 'password'" class="show-password-icon" aria-hidden="true"></i>
                  </span>
                  <div [hidden]="!loginErrors.password.hasError" class="alert alert-danger">
                    <div *ngFor="let ErrorMessage of loginErrors.password.error">
                      {{ ErrorMessage.message }}
                    </div>
                  </div>
                </div>
                <div class="form-errors" *ngIf="
                  signInForm.controls.password.errors &&
                  (signInForm.controls.password.dirty ||
                    signInForm.controls.password.touched)
                ">
                  <div [hidden]="!signInForm.controls.password.errors.required" translate>
                    error_password_required
                  </div>
                </div>
              </div>

              <div class="col-12 lg:col-12 md:col-10 login-btn-wrapper">
                <div class="form-buttons">

                  <button pButton label="Login" type="button" class="login-btn"  (click)="doSignIn()"></button>
                  <div class="" style="padding-top: 3.7em;">
                    <a (click)="forgotPassword()" class="forgot-password-link" translate>forgot_password</a>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </p-panel>
      </div>

    </div>
  </div>
</div>
<!-- login as popup -->
<p-dialog [dismissableMask]="false"
    [modal] = "true"modal="false" styleClass="edit-popup extend-width franchise-selection-popup role-selection-popup" position="center center"  
    [(visible)]="loginProfilePopup" [closable]="false">
  <p-header>Login As</p-header>
  <div class="grid franchise-list">
    <div class="form-control">
      <div class="col-12 pad-l-0 input-radio-container">
          <input type="radio" [(ngModel)]="loginAs" name="categoryType" value="franchise" id="franchise" (change)="onClickOptions()" />
          <label class="franchise-name " for="franchise">Franchise Admin</label>
      </div>
      <div class="col-12 pad-l-0 input-radio-container">
          <input type="radio" name="categoryType"  value="staff" id="staff" [(ngModel)]="loginAs" (change)="onClickOptions()"/>
          <label class="franchise-name " for="staff">Staff</label>
      </div>
    </div>
  </div>
  <p-footer>
    <button pButton [label]="'btn_submit' | translate" type="submit" class="save-btn"
    (click)="onConfirmClick()" ></button>
    <button pButton [label]="'btn_cancel' | translate" type="submit" class="cancel-btn"
    (click)="onCancelClick()" ></button>
  </p-footer>
</p-dialog>

<!-- select franchise popup -->
<p-dialog [dismissableMask]="false"
    [modal] = "true"modal="false" styleClass="edit-popup extend-width franchise-selection-popup multi-selection-popup" position="center center" 
     [(visible)]="franchisePopup" [closable]="false">
  <p-header>Select Franchise</p-header>
  <div class="grid franchise-list">
    <div class="form-item col-12 lg:col-7 md:col-7">
      <div class="form-field">
        <div class="form-label">
          <label class="franchise-name" for="11" style="padding: 0;">Select Franchise</label>
        </div>
        <div class="form-control">
          <p-dropdown [options]="franchiseOptions" [(ngModel)]="selectedFranchise"></p-dropdown>
        </div>
      </div>
    </div>
  </div>

  <p-footer>
    <button pButton [label]="'btn_submit' | translate" type="submit" class="save-btn"
    (click)="onFranchiseSelect()" ></button>
    <button pButton [label]="'btn_cancel' | translate" type="submit" class="cancel-btn"
    (click)="onCancelClick()" ></button>
  </p-footer>
</p-dialog>


<p-dialog   [dismissableMask]="false"
    [modal] = "true"modal="false" styleClass="edit-popup extend-width forgot-popup" position="center center"  [(visible)]="forgotPopup"
  [closable]="false">
  <p-header>Forgot Password</p-header>
  <app-forgot-password (popClose)="onPopClose()" (popSave)="onPopSave()"></app-forgot-password>
</p-dialog>

<p-dialog   [dismissableMask]="false"
    [modal] = "true"modal="false" styleClass="edit-popup extend-width forgot-popup" position="center center"  [(visible)]="setPasswordPopup"
  [closable]="false">
  <p-header>Set Password</p-header>
  <app-set-password   (setPasswordPopClose)="onSetPasswordPopClose()"></app-set-password>
</p-dialog>

<p-dialog [dismissableMask]="false"
    [modal] = "true"modal="false" styleClass="edit-popup extend-width franchise-selection-popup role-selection-popup"
     [style]="{width: '70vw'}" position="center center"  [(visible)]="termsPopup" [closable]="false">
  <p-header>Terms Of Service</p-header>
  <div class="grid franchise-list">
    <div class="form-control">
      <div class="col-12 pad-l-0 input-radio-container">
         <app-aggrement-terms-of-service></app-aggrement-terms-of-service>
      </div>
    </div>
  </div>
  <p-footer>
    <button pButton [label]="'btn_reject' | translate" type="submit" class="cancel-btn"
    (click)="rejectTerms()" ></button>
    <button pButton [label]="'btn_accept' | translate" type="submit" class="save-btn"
    (click)="acceptTerms()" ></button>
  </p-footer>
</p-dialog>

<app-message></app-message>
<!-- <p-toast [style]="{marginTop: '40px'}"></p-toast> -->


<!-- <div class="franchise page-layout">
  <div class="signin-box-container">
    <div class="signin-box-inner-container">
      <div class="signin-box col-11 lg:col-3 md:col-8">
        <div class="company-logo">
          <img width="200" src="/assets/images/franchise_logo.png" />
        </div>

        <p-panel header="Login">
          <form [formGroup]="signInForm">
            <div class="form-container grid" style="justify-content: center;">
              <div class="form-errors">
                <div [hidden]="!loginErrors.general.hasError" class="alert alert-danger">
                  {{ loginErrors.general.error?loginErrors.general.error:'' }}
                </div>
              </div>

              <div class="form-field col-12 lg:col-12 md:col-10">
                <div class="form-label">
                  <label for="username" translate>label_enter_email_address</label>
                </div>
                <div class="form-control">
                  <input formControlName="username" class="full-width email-lower" type="text" pInputText
                    (keypress)="eventHandler($event)" />
                </div>
                <div class="form-errors" *ngIf="
                  signInForm.controls.username.errors &&
                  (signInForm.controls.username.dirty ||
                    signInForm.controls.username.touched)
                ">
                  <div [hidden]="!signInForm.controls.username.errors.required" translate>
                    error_email_id_required
                  </div>
                  <div [hidden]="!signInForm.controls.username.errors.pattern" translate>
                    error_email_id_invalid
                  </div>
                </div>
              </div>

              <div class="form-field col-12 lg:col-12 md:col-10">
                <div class="form-label">
                  <label for="passwd" translate> label_enter_password </label>
                </div>
                <div class="form-control position-rel">
                  <input formControlName="password" [type]="passwordType" class="full-width"
                    (keypress)="eventHandler($event)" pInputText />
                  <span class="eye-icon sign-in-eye" (click)="tooglePasswordType()"
                    style="position: absolute;top: 12px;right: 5px;">
                    <i *ngIf="passwordType === 'password'" class="hide-password-icon" aria-hidden="true"></i>
                    <i *ngIf="passwordType !== 'password'" class="show-password-icon" aria-hidden="true"></i>
                  </span>
                  <div [hidden]="!loginErrors.password.hasError" class="alert alert-danger">
                    <div *ngFor="let ErrorMessage of loginErrors.password.error">
                      {{ ErrorMessage.message }}
                    </div>
                  </div>
                </div>
                <div class="form-errors" *ngIf="
                  signInForm.controls.password.errors &&
                  (signInForm.controls.password.dirty ||
                    signInForm.controls.password.touched)
                ">
                  <div [hidden]="!signInForm.controls.password.errors.required" translate>
                    error_password_required
                  </div>
                </div>
              </div>

              <div class="col-12 lg:col-12 md:col-10" style="margin-left: 2%;">
                <div class="form-buttons">
                  <a (click)="forgotPassword()" class="forgot-password-link" translate>forgot_password</a>
                  <button pButton label="Login" type="button" [disabled]="!signInForm.valid" (click)="doSignIn()" style="width: 100%;"></button>
                </div>
              </div>
            </div>
          </form>
        </p-panel>
      </div>
    </div>
  </div>
</div> -->
