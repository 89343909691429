import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Input,
  Output,
  EventEmitter, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';


import { UiService } from './../../services/ui.service';
import { GridService } from './grid.service';
import { GridModel } from './grid-model';
// import { OverlayPanel } from 'primeng';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { LoaderService } from '../loader/loader.service';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { AppSettings } from '../../app.settings';
import { StorageService } from '../../services/storage.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { Table } from 'primeng/table';
import { UserCommonService } from '../../services/user-common.service';
@Component({
  selector: 'app-mi-grid-v2',
  providers: [GridService],
  templateUrl: './mi-grid.component.html'
})
export class MiGridComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  subscribedVariable: Subscription;
  subscribeBrand: Subscription;
  items: MenuItem[];
  rowData: any[] = [];
  search = '';
  labelNoRecordFound = this.commonService.getLabel('label_no_records_found');
  @Output() rowClickedEvent = new EventEmitter<any>();
  @Output() actionBtnClickedEvent = new EventEmitter<any>();
  @Output() getGridReference: EventEmitter<any> = new EventEmitter();
  @Output() dropDownEvent: EventEmitter<any> = new EventEmitter();
  @Output() isRecordsVisible: EventEmitter<any> = new EventEmitter();
  @Output() isEmptyGrid: EventEmitter<boolean> = new EventEmitter(false);
  @Output() selectAllCheckbox = new EventEmitter<any>();
  @Output() selectCheckbox = new EventEmitter<any>();
  @Input() url: any = '';
  @Input() searchBar: string;
  @Input() exportButton: string;
  @Input() rowSelection = 'single';
  @Input() printUrl: any = '';
  @Input() exportUrl: any = '';
  @Input() columnDefs: any[] = [];
  @Input() actionButtonsDefs: any[] = [];
  @Input() pagination: any = true;
  @Input() paginationPageSize: any = 10;
  @Input() paginationStartPage: any = 0;
  @Input() defaultSortField = '';
  @Input() divClass = '';
  @Input() isExportVisible = true;
  @Input() useV2 = false;
  @Input() isPost = false;
  @Input() isPostData;
  @Input() footerTotal;
  @Input() noRecordsMsgFromParent;
  @Input() isGrouping = false;
  @Input() actionColumnWidth: '';
  @Input() uniqueId: '';
  @Input() fromCancelOffset: any = false;
  @Input() isInputBrandId = false;

  enableResizer = 'false';
  @Input() overlayLoadingTemplate: any =
    '<span class="ag-overlay-loading-center" "style=background:red">' +
    'Please wait while your rows are loading</span>';
  datasource = [];
  model: GridModel;
  totalRecords = 0;
  sortF = '';
  sortO = '';
  sortOrderVal = 1;
  offset = 0;
  setRowLength = 10;
  searchText = '';
  
  selectedRecords: any = [];
  paginationRecord: any;
  gridUrlParams: string;
  selectedValues: any[] = [];
  allSelectFlag = false;
  aggreToLostSelection = false;
  @ViewChild('dt') dt: Table;
  menuModel = false;
  loggedInUserId: any;
  notificationColorDetails: any;
  sendColor: any;
  notSendColor: any;
  rowGroupMetadata: any;
  // commonBindingDataService: any;
  headerSelectedBrands: any ='';
  
  constructor(
    private elm: ElementRef,
    private loaderService: LoaderService,
    private uiService: UiService,
    private gridService: GridService,
    private storageService: StorageService,
    private commonService: CommonBindingDataService,
    private commonBindingDataService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private franchiseService: FranchiseService,
    private userCommonService: UserCommonService
    ) {
    this.headerSelectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
    this.headerSelectedBrands = this.headerSelectedBrands?this.headerSelectedBrands:'';
    // this.getAndSetOffset();
    this.subscribeBrand = this.userCommonService.changeBrand.subscribe((res:any)=>{
      this.headerSelectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
      this.updateUrl(this.url);
    });
  }

  ngOnInit() {
    // this.getAndSetOffset();
    this.loggedInUserId = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA))?.franchiseId;
    this.getNotificationBgColor();
    if (this.noRecordsMsgFromParent) {
      this.labelNoRecordFound = this.noRecordsMsgFromParent;
      // console.log(this.labelNoRecordFound);
    }
    this.subscription = this.uiService.sidebarToggledEvent.subscribe(
      (state: string) => {
        // this.refreshList();
      }
    );
    this.items = [{
      items: [{
        label: 'Update',
        icon: 'pi pi-refresh',
        command: () => {
          //  this.update();
        }
      },
      {
        label: 'Delete',
        icon: 'pi pi-times',
        command: () => {
          //  this.delete();
        }
      }
      ]
    },
    ];

    this.subscribedVariable = this.franchiseService.salesTotalChange.subscribe(result => {
      this.footerTotal = [];
      this.footerTotal = result;
      // console.log(result);
    });
  }

  toggleMenu() {
    this.menuModel = !this.menuModel;
  }

  onResize(event: any) {
    // this.refreshList();
  }

  refreshList() {
    this.fetchAndLoadData();
  }

  fetchAndLoadData() {
    // console.log('isfromcancle', this.fromCancelOffset);
    this.getAndSetOffset();

    const that = this;
    let params =
      'searchText=' +
      encodeURIComponent(this.searchText) +
      '&offset=' +
      this.offset +
      '&limit=' +
      this.setRowLength;
      if(this.isInputBrandId){
      params += `&brandIds=${this.headerSelectedBrands}`;
      }

    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    let buildUrl = this.url;

    if (!this.isPost) {
      if (this.url.lastIndexOf('?') < 0) {
        buildUrl += '?';
      } else {
        buildUrl += '&';
      }
      buildUrl += params;
    } else {
      this.isPostData.sortColumn = this.sortF;
      this.isPostData.sortType = this.sortO;
      this.isPostData.offset = this.offset;
      this.isPostData.limit = this.setRowLength;
    }
    this.setOffsetInStorage();
    this.gridService.loadGridData(buildUrl, this.useV2, this.isPost, this.isPostData).subscribe(result => {
      if (screen.width < 740) {
        if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
          document.getElementsByTagName('body')[0].classList.toggle('closed');  
        }
      }
      // this.uiService.sidebarToggled();
      this.loaderService.hide();
      const model = <GridModel>result;
      if (result !== undefined) {
        if(model.count == 0){
          this.isEmptyGrid.emit(true);
        }else{
          this.isEmptyGrid.emit(false);
        }
        // model.count == 0 ? this.isEmptyGrid.emit(true) : this.isEmptyGrid.emit(false);
        this.totalRecords = model.count; // model.pagination.totalRecords;
        if (+this.totalRecords === 0) {
          this.pagination = false;
          this.isRecordsVisible.emit(false);
        } else {
          this.pagination = true;
          this.isRecordsVisible.emit(true);
        }

        this.paginationRecord = {
          pageNum: this.offset + 1,
          onePageEntries: (this.offset) + (model.data ? model.data.length : 0),
          totalEntries: this.totalRecords
        };
        that.datasource = [];
        model.data.forEach(e => {
          that.columnDefs.forEach(c => {
            if (c && c.cellRenderer && !c.htmlRenderer) {
              e[c.field] = c.cellRenderer(e[c.field]);
            }
            if (c && c.htmlRenderer) {
              e[c.field] = c.htmlRenderer(e);
            }
            if (c && c.colorPicker) {
              e[c.colorPicker] = c.colorPicker(e);
            }
          });

          that.actionButtonsDefs.forEach(d => {
            if (d.show) {
              e[d.field] = d.show(e);
            }
          });
          that.datasource.push(e);
        });
        // this.selectedValues = [];
        this.allSelectFlag = false;
        setTimeout(() => {
          this.onChangeSelection();
        }, 500);

      }
      this.dt.first = this.offset;
      const pageSize = this.dt.rows;
      const page = ((this.dt.first + this.dt.rows) / this.dt.rows) - 1;
      this.updateRowGroupMetaData(page, pageSize);
    });

  }

  public onQuickFilterChanged(event) {
    // if ($event.key === 'Enter' && this.search.length >=3) {
    if (event && (this.search.length >= 3 || this.search.length === 0)) {
      this.applySearch(this.search);
    }
  }

  updateUrl(url: string) {
    // this.getAndSetOffset();
    this.offset = 0;
    this.setRowLength = 10;
    this.url = url;
    this.dt.first = this.offset;
    this.refreshList();
  }

  setOffsetInStorage() {
    if (this.uniqueId) {
      const storedObject = JSON.parse(this.storageService.getItem('listNavigatedOffset'));
      const tempObject = storedObject ? { ...storedObject } : {};
      tempObject[this.uniqueId] = this.offset;
      tempObject['sortField'] = this.sortF;
      tempObject['sortOrder'] = this.sortO;
      this.storageService.setItem('listNavigatedOffset', tempObject);
      if (this.uniqueId === 'menu_Franchise_Template_List_01') {
        setTimeout(() => {
          const element = document.getElementById(`${this.uniqueId}`);
          element.scrollIntoView();
        }, 100);
      }
    }
  }

  getAndSetOffset() {
      const storedObject = JSON.parse(this.storageService.getItem('listNavigatedOffset'));
    if (this.uniqueId && this.fromCancelOffset) {
      const storedOffset = storedObject && storedObject[this.uniqueId] ? storedObject[this.uniqueId] : 0;
      this.offset = (storedOffset && storedOffset >= 0 && this.offset == 0) ? storedOffset : this.offset;
      this.sortF = (storedObject && storedObject.sortField) ? storedObject.sortField : this.sortF;
      this.sortO = (storedObject && storedObject.sortOrder) ? storedObject.sortOrder : this.sortO;
    }
    this.sortOrderVal = this.sortO == 'asc'? 1: -1;
  }

  loadDatasourceLazy(event: LazyLoadEvent) {

    this.sortF = this.defaultSortField;
    this.offset = event.first;
    this.setRowLength = event.rows;
    if (event.sortField !== undefined) {
      this.sortF = event.sortField;
    }
    this.sortO = event.sortOrder > 0 ? 'asc' : 'desc';
    this.fetchAndLoadData();
  }

  onRowSelect(event) {
    this.rowClickedEvent.emit(event);
    // overlaypanel.toggle(event);
  }

  onRowUnselect(event) {
    this.rowClickedEvent.emit(event);
  }

  actionBtnClick(data: any, action: string) {
    const obj = { row: data, class: action };
    this.actionBtnClickedEvent.emit(obj);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.subscribedVariable.unsubscribe();
    this.subscribeBrand.unsubscribe();
  }

  getSelectedRecords() {
    return this.selectedRecords;
  }

  applySearch(searchText: string) {
    this.searchText = searchText;
    this.fetchAndLoadData();
  }

  onDropDownChange(event: any, data, type?: any) {
    event.data = data;
    event.type = type;
    this.dropDownEvent.emit(event);
  }

  confirmToLostSelection() {
    this.confirmationService.confirm({
      message: this.commonService.getLabel('will lost record selection for this page'),
      accept: () => {
        this.aggreToLostSelection = true;
      }, reject: () => {
        this.aggreToLostSelection = false;
      }
    });
  }

  onChangeSelection() {
    // if (this.selectedValues.length >= this.datasource.length) {
    // this.allSelectFlag = true;
    // } else {
    //   this.allSelectFlag = false;
    // }
    this.allSelectFlag = true;
    this.datasource.forEach(element => {
      const index = this.selectedValues.indexOf(element.id);
      if (index === -1) {
        this.allSelectFlag = false;
      }
    });
    const uniqueArray = [...new Set(this.selectedValues)];
    this.selectedValues = uniqueArray;

    this.selectCheckbox.emit(this.selectedValues);
  }

  onSelectionAll() {
    let allIds: any[] = [];
    if (this.allSelectFlag) {
      allIds = this.datasource.map(e => e.id);
      allIds.forEach(element => {
        this.selectedValues = [...this.selectedValues, element];
      });
    } else {
      allIds = [];
      this.datasource.forEach(element => {
        const index = this.selectedValues.indexOf(element.id);
        if (index > -1) {
          this.selectedValues.splice(index, 1);
        }
      });
    }

    const uniqueArray = [...new Set(this.selectedValues)];
    this.selectedValues = uniqueArray;

    this.selectAllCheckbox.emit(this.selectedValues);

    // // this.selectedValues = allIds;
    // if (this.selectedValues.length > 0 || this.allSelectFlag) {
    //   allIds.forEach(element => {
    //     this.selectedValues = [...this.selectedValues, element];
    //   });
    // } else {
    //   this.selectedValues = allIds;
    // }
  }


  getDropdownColor(rowData) {
    if (rowData.orderStatus === 'NEW') {
      return 'cond-color blue';
    } else if (rowData.orderStatus === 'IN_PROGRESS') {
      return 'cond-color yellow';
    } else if (rowData.orderStatus === 'COMPLETED') {
      return 'cond-color green';
    } else if (rowData.orderStatus === 'CANCELLED') {
      return 'cond-color red';
    } else {
      return '';
    }
  }

  getNotificationBgColor() {
    if (this.loggedInUserId) {
      // this.franchiseService.getSettings(this.loggedInUserId).subscribe(result => {
      //   if (result) {
      //     this.notificationColorDetails = result;
      //     this.sendColor = this.notificationColorDetails.notificationSendColor;
      //     this.notSendColor = this.notificationColorDetails.notificationNotSendColor;
      //   }
      // })
      this.sendColor = this.franchiseService.franchiseSettings.yesColor;
      this.notSendColor = this.franchiseService.franchiseSettings.noColor;
    }
  }

  getStaffColorBgColor(rowData) {
    if (rowData.staffsList && rowData.staffsList.length > 0) {
      return '';
    } else {
      return this.notSendColor;
    }
  }

  getStaffColorBgClass(rowData) {
    if (rowData.staffsList && rowData.staffsList.length > 0) {
      return '';
    } else {
      return 'statusbox';
    }
  }

  getMobileStaffColorBgColor(rowData) {
    if (rowData.staffNamesList !== null) {
      return '';
    } else {
      return this.notSendColor;
    }
  }

  getMobileStaffColorBgClass(rowData) {
    if (rowData.staffNamesList !== null) {
      return '';
    } else {
      return 'statusbox';
    }
  }

  getEquipmentColorBgColor(rowData) {
    if (rowData.assetsList && rowData.assetsList.length > 0) {
      return '';
    } else {
      return this.notSendColor;
    }
  }

  getEquipmentColorBgClass(rowData) {
    if (rowData.assetsList && rowData.assetsList.length > 0) {
      return '';
    } else {
      return 'statusbox';
    }
  }

  getMobileEquipmentColorBgColor(rowData) {
    if (rowData.assetNamesList !== null) {
      return '';
    } else {
      return this.notSendColor;
    }
  }

  getMobileEquipmentColorBgClass(rowData) {
    if (rowData.assetNamesList !== null) {
      return '';
    } else {
      return 'statusbox';
    }
  }

  getNotificationColor(rowData) {
    if (rowData.bookedEmailSent === true) {
      return this.sendColor;
    } if (rowData.bookedEmailSent === false) {
      return this.notSendColor;
    } else {
      return '';
    }
  }

  getNotificationColor1(rowData) {
    if (rowData.confirmedEmailSent === true) {
      return this.sendColor;
    } if (rowData.confirmedEmailSent === false) {
      return this.notSendColor;
    } else {
      return '';
    }
  }

  getNotificationColor2(rowData) {
    if (rowData.thankYouEmail === true) {
      return this.sendColor;
    } if (rowData.thankYouEmail === false) {
      return this.notSendColor;
    } else {
      return '';
    }
  }

  getNotificationColor3(rowData) {
    if (rowData.clientInvoice === true && rowData.paymentTerm === '<div title = "Invoice">Invoice</div>') {
      return this.sendColor;
    } else if (rowData.clientInvoice === false && rowData.paymentTerm === '<div title = "Invoice">Invoice</div>') {
      return this.notSendColor;
    } else {
      return '';
    }
  }

  getNotificationColor4(rowData) {
    if (rowData.givebackPaid === true && rowData.givebackPercentage > 0) {
      return this.sendColor;
    } if (rowData.givebackPaid === false && rowData.givebackPercentage > 0) {
      return this.notSendColor;
    } else {
      return '';
    }
  }
  getNotificationColor5(rowData) {
    if (rowData.taskCompleteStatus === true) {
      return this.sendColor;
    } if (rowData.taskCompleteStatus === false) {
      return this.notSendColor;
    } else {
      return '';
    }
  }

  showCheckBox(rowData) {
    if (rowData.email) {
      return 'show';
    } else {
      return 'hidden';
    }
  }

  onSort() {
    setTimeout(() => {
      const pageSize = this.dt.rows;
      const page = ((this.dt.first + this.dt.rows) / this.dt.rows) - 1;
      this.updateRowGroupMetaData(page, pageSize);
    }, 1000);

  }

  updateRowGroupMetaData(page: number, pageSize: number) {
    this.rowGroupMetadata = {};
    if (this.datasource) {
      for (let i = 0; i < this.datasource.length; i++) {
        const rowData = this.datasource[i];
        const rowGroup = rowData.eventDate;
        const actualRowIndex: number = i + (page * pageSize);
        // console.log('rowdata',rowData);
        // console.log('dayGroup',rowGroup);
        // console.log('page',page);
        // console.log('pageSize',pageSize);
        // console.log('actualIndex',actualRowIndex);
        if (i === 0) {
          this.rowGroupMetadata[rowGroup] = { index: actualRowIndex, size: 1, rowGroup };
        } else {
          const previousRowData = this.datasource[i - 1];
          const previousRowGroup = previousRowData.eventDate;
          if (rowGroup === previousRowGroup)
            this.rowGroupMetadata[rowGroup].size++;
          else
            this.rowGroupMetadata[rowGroup] = { index: actualRowIndex, size: 1 };
        }
      }
      // console.log(this.rowGroupMetadata);
    }
  }



  getUrlExport(url) {
    // const that = this;
    let params = 'searchText=' + encodeURIComponent(this.searchText);

    params += '&sortColumn=';
    params += this.sortF;
    params += '&sortType=';
    params += this.sortO;
    params += '&offset=0&limit=0';
    let buildUrl = url;

    if (!this.isPost) {
      if (url.lastIndexOf('?') < 0) {
        buildUrl += '?';
      } else {
        buildUrl += '&';
      }
      if (this.url.lastIndexOf('?') < 0) {
        this.gridUrlParams = '';
      } else {
        this.gridUrlParams = this.url.substring(this.url.lastIndexOf('?'));
        this.gridUrlParams = this.gridUrlParams.replace('?', '&');
      }
      buildUrl += params;
    } else {
      this.isPostData.sortColumn = this.sortF;
      this.isPostData.sortType = this.sortO;
      this.isPostData.offset = this.offset;
      this.isPostData.limit = this.setRowLength;
      buildUrl = url;
    }

    return buildUrl;
  }

  exportGridExcel() {
    const that = this;
    // this.gridService.downloadExcelGridData(that.getUrlExport(that.exportUrl) + this.gridUrlParams);
    let params = '';
    if (!this.isPost) {
      if (this.exportUrl.indexOf('?') > -1) {
        params = '';
      } else {
        params = '?';
      }
      params += '&searchText=' + encodeURIComponent(this.searchText);
      params += '&sortColumn=';
      params += this.sortF;
      params += '&sortType=';
      params += this.sortO;
      params += '&offset=0&limit=0';
    } else {
      this.isPostData.sortColumn = this.sortF;
      this.isPostData.sortType = this.sortO;
      this.isPostData.offset = this.offset;
      this.isPostData.limit = this.setRowLength;
    }

    this.gridService.downloadExcelGridData(that.exportUrl + params, this.isPost, this.isPostData);
  }

  exportGridPdf() {
    const that = this;
    this.gridService.downloadPdfGridData(that.getUrlExport(that.printUrl) + this.gridUrlParams);
  }

  checkDataPresent() {
    if (+this.totalRecords === 0) {
      return false;
    } else {
      return true;
    }
  }
}
