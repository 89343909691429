import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-footer-terms-content',
  templateUrl: './footer-terms-content.component.html',
})
export class FooterTermsContentComponent {
  @Output() openPrivacy: EventEmitter<any> = new EventEmitter();
  constructor() { }

  showPrivacy() {
    this.openPrivacy.emit();
  }

}
