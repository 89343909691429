import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-print-week-events-list',
  templateUrl: './print-week-events-list.component.html',
  styleUrls: ['./print-week-events-list.component.scss']
})
export class PrintWeekEventsListComponent {
  @Input() eachEvent: any;
  @Input() events: any;
  @Input() task: any;
  constructor() { }

}
