<div class="container">
    @if(!this.zeroArray){
    <div class="graph-container">
        <canvas #barCanvas style="height: 35vh; width: 35vw"></canvas>
    </div>
    }@else{
    <div class="graph-no-data" translate>
        msg_no_sales_data
    </div>
    }
</div>