import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
declare const google: any;
@Directive({
  selector: '[appSearchLocation]'
})
export class SearchLocationDirective {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  private _el: HTMLElement;
  autocomplete: any;
  constructor(el: ElementRef) {
    this._el = el.nativeElement;
    const input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, {});
    this.autocomplete.setComponentRestrictions(
      { 'country': environment.supportedCountry });
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const placeObj = {
        'name': place.name,
        'address': place.formatted_address,
        'lat': place.geometry.location.lat(),
        'lng': place.geometry.location.lng(),
        'place': place.address_components,
        'id': input.id
      };
      this.invokeEvent(placeObj);

    });
  }

  invokeEvent(place: object) {
    this.setAddress.emit(place);
  }
}
