<div class="container">
    @if(this.inputDataObject && this.inputDataObject.eventSalesDataSet1.length > 0 
    && this.inputDataObject.eventSalesDataSet2.length > 0){
    <div class="graph-container" *ngIf="inputDataObject.eventSalesDataSet1.length>0">
        <canvas #barCanvas style="height:9vh; width:10vw"></canvas>
    </div>
    }@else{
    <div class="graph-no-data" translate>
        msg_no_sales_data
    </div>
    }
</div>