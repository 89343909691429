import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { StorageService } from '../../services/storage.service';


@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit {

  @Input() clientDetails: any;
  @Input() clientId: any;
  clientDashboardData: any;
  clientDashboardSalesTotal: any;
  clientDashboardEventTotal: any;
  clientDashboardGivebackTotal: any;
  hrefUrl: any;


  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private franchiseService: FranchiseService,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {

    this.fetchClientDashboard();
    //console.log(encodeURIComponent(this.clientDetails.businessName))

    setTimeout(() => {
      // console.log(this.clientDetails);
      // console.log(this.clientId);
      // this.hrefUrl = '/#/franchise/reports/sales?clientId=' + this.clientId + '&clientBusinessName=' + encodeURIComponent(this.clientDetails.businessName) + '&timeframe=THIS YEAR';
    }
      , AppSettings.SEARCH_DELAY_INTERVAL);
  }

  fetchClientDashboard() {
    this.franchiseService.fetchClientDashboard(this.clientId).subscribe((result) => {
      if (result.data && result.data.length > 0) {
        this.clientDashboardData = result.data;
        result.data.forEach(clientDashboardDataResult => {
          this.clientDashboardSalesTotal = (typeof this.clientDashboardSalesTotal === 'undefined') ? clientDashboardDataResult.eventSalesCollected : this.clientDashboardSalesTotal + clientDashboardDataResult.eventSalesCollected;
          this.clientDashboardEventTotal = (typeof this.clientDashboardEventTotal === 'undefined') ? parseInt(clientDashboardDataResult.eventCount) : this.clientDashboardEventTotal + parseInt(clientDashboardDataResult.eventCount);
          this.clientDashboardGivebackTotal = (typeof this.clientDashboardGivebackTotal === 'undefined') ? clientDashboardDataResult.giveback : this.clientDashboardGivebackTotal + clientDashboardDataResult.giveback;
        });
        this.clientDashboardData.push({ years: 'Total', giveback: this.clientDashboardGivebackTotal, eventSalesCollected: this.clientDashboardSalesTotal, eventCount: this.clientDashboardEventTotal });
      }
    });
  }

  goToSales() {
    this.router.navigate(['/franchise/reports/sales'], {
      queryParams: {
        clientId: this.clientId, clientBusinessName: this.clientDetails.businessName,
        timeframe: AppSettings.TIMEFRAME_LIST_FILTER_OPTIONS_VALUES.THIS_YEAR
      }
    });
  }
}
