import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FranchiseService } from '../../services/franchise-service.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettings } from '../../../shared/app.settings';
import { UiService } from '../../../shared/services/ui.service';
import { ErrorBindingService } from '../../../shared/services/error-binding.service';

@Component({
  selector: 'app-order-refund',
  templateUrl: './order-refund.component.html',
  styleUrls: ['./order-refund.component.css']
})
export class OrderRefundComponent implements OnInit {

  @Input() foodTotalAmount;
  @Input() grandTotal;
  @Input() cancelObject;
  @Output() toggleRefundDialog = new EventEmitter<any>();
  submitted: boolean;
  refundForm: UntypedFormGroup;

  constructor(
    private franchiseService: FranchiseService,
    private formBuilder: UntypedFormBuilder,
    private uiService: UiService,
    private errorBindingService: ErrorBindingService
  ) { }

  ngOnInit(): void {
    this.initRefundForm();
  }

  initRefundForm() {
    this.refundForm = this.formBuilder.group({
      refundAmount: [{ value: this.grandTotal, disabled: true },
      [Validators.required, Validators.min(1.00), Validators.max(this.grandTotal)]],
      refundOptions: ['totalRefund', [Validators.required]]
    });
  }

  toggleRefundCheck(event) {
    if (event == 'totalRefund') {
      this.refundForm.controls['refundAmount'].disable();
      this.refundForm.controls.refundAmount.setValue(this.grandTotal);
    } else if (event == 'partialRefund') {
      this.refundForm.controls['refundAmount'].enable();
      this.refundForm.controls.refundAmount.setValue('');
      this.makeDecimalAmount('refundAmount');
    }
  }

  makeDecimalAmount(fieldName) {
    if (this.refundForm.controls[fieldName].value) {
      const roundValue = Number(this.refundForm.controls[fieldName].value).toFixed(2);
      this.refundForm.controls[fieldName].setValue(roundValue);
    }
  }

  onConfirmClick() {
    this.submitted = false;
    if (this.refundForm.valid) {
      const ref = this.refundForm.controls.refundOptions.value;
      const obj = {
        'id': this.cancelObject.id,
        'orderCode': this.cancelObject.orderCode,
        'orderStatus': 'CANCELLED',
        'refundAmount': this.refundForm.controls.refundAmount.value,
        'campaignShareRefund': false,
        'orderAmountRefund': false,
        'totalRefund': (ref == 'totalRefund' ? true : false),
        'partialRefund': (ref == 'partialRefund' ? true : false)
      };

      this.franchiseService.changeOrderStatus(obj).subscribe((result) => {
        this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
        this.initRefundForm();
        this.refundForm.reset();
        this.toggleRefundDialog.emit(false);
      }, error => {
        this.refundForm.reset();
        this.toggleRefundDialog.emit(false);
        console.log(error);
      });

    } else {
      this.submitted = true;
      this.errorBindingService.getFormValidationErrors(this.refundForm);
    }
  }

  onCancelClick() {
    this.refundForm.reset();
    this.toggleRefundDialog.emit(false);
  }


}
