import { Injectable } from '@angular/core';
import { RestApiService } from '../../services/rest-api.service';
import { Observable } from 'rxjs';


@Injectable()
export class GridService {

  constructor(private restApiService: RestApiService) { }

  public loadGridData(url: string, useV2?, isPost?, isPostData?): Observable<object> {
    if (isPost) {
      return this.restApiService.post('mi-grid', url, isPostData, 'page-center');
    } else {
      if (useV2) {
        return this.restApiService.get('mi-grid', url, 'page-center', useV2);
      }
      return this.restApiService.get('mi-grid', url, 'page-center');
    }

  }

  public downloadExcelGridData(url: string, isPost?, isPostData?) {
    if (isPost) {
      return this.restApiService.excelAndDownload(url, 'export.xls', 'page-center', isPostData);
    } else {
      return this.restApiService.excel(url, 'export.xls', 'page-center');
    }

  }

  public downloadPdfGridData(url: string) {
    return this.restApiService.downloadFile(url, 'print.pdf', 'page-center');
  }
}
