import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { StorageService } from '../../../services/storage.service';
import { UserCommonService } from '../../../services/user-common.service';
import { FranchiseService } from '../../../../franchise/services/franchise-service.service';

@Component({
  selector: 'app-dashboard-filters',
  templateUrl: './dashboard-filters.component.html',
  styleUrls: ['./dashboard-filters.component.scss']
})
export class DashboardFiltersComponent implements OnInit {

  @Input() showGranularity = true;
  @Input() showCompare = true;
  @Output() filterObjectChangeEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() noCompareEmmiter: EventEmitter<any> = new EventEmitter();
  filterTimeframeOptions = AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS;
  ViewOptions = AppSettings.DASHBOARD_GRANULARITY_WEEK;
  CompareTimeframeOptions = AppSettings.DASHBOARD_COMPAIR_TIMEFRAME_LIST_FILTER_OPTIONS;
  startDayOfWeek = this.userCommonService.getUpdatedStoredValue(AppSettings.START_DAY_OF_WEEK_FOR_MONTH);

  timeframeview = 'day';
  fromDate = null;
  toDate = null;
  minDate: Date = null;
  maxDate: Date = new Date();
  defaultToday = new Date();
  enableDateStatus: boolean;
  rangeDates: [any, any];

  compareRangeDates: [any, any];
  comparedtoDate = null;
  comparedfromDate = null;
  timeframe: any;
  comparetimeframe: any;

  constructor(
     private storageService: StorageService,
     private userCommonService: UserCommonService,
     public franchiseService: FranchiseService,) {
    this.timeframe = 'MONTH';
    this.fromDate = new Date();
    this.fromDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth() - 1, 1);
    this.fromDate.setHours(0, 0, 1);

    this.toDate = new Date();
    this.toDate = new Date((new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth(), this.defaultToday.getDate()).setDate(0)));
    this.toDate.setHours(23, 59, 59);
    this.enableDateStatus = true;
    this.rangeDates = [this.fromDate, this.toDate];

    this.comparedfromDate = new Date();
    this.comparedfromDate = new Date(this.comparedfromDate.getFullYear(), this.comparedfromDate.getMonth() - 1, 1);
    this.comparedfromDate.setHours(0, 0, 1);

    this.comparedtoDate = new Date();
    this.comparedtoDate = new Date((new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth(), this.defaultToday.getDate()).setDate(0)));
    this.comparedtoDate.setHours(23, 59, 59);
    this.enableDateStatus = true;
    this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
  }

  ngOnInit(): void {
    // this.timeframe = 'MONTH';
    this.timeframe = this.franchiseService.dashboardFilters.timeframe;
    this.toDate = this.franchiseService.dashboardFilters.toDate;
    this.fromDate = this.franchiseService.dashboardFilters.fromDate;
    this.rangeDates = [this.fromDate, this.toDate];
    
    this.comparetimeframe = 'YESTERDAY';
    setTimeout(() => {
      if(this.franchiseService.dashboardFilters.timeframe !== '') {
        const obj ={
          value: this.timeframe
        };
        this.onChangeTimeframe(obj);
      }else {
        this.enableDateStatus = false;
      }
      this.prepareObjectToSend(true);

    }, 300);
    // console.log('startDayOfWeek'+this.startDayOfWeek);
  }

  onChangeTimeframe(event) {
    // console.log(event.value);
    const today = new Date();
    this.toDate = today;
    this.fromDate = new Date();
    this.rangeDates = ['', ''];
    this.enableDateStatus = true;
    switch (event.value) {
      case 'TODAY': {
        this.fromDate = new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setHours(0, 0, 1));
        this.toDate = new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setHours(23, 59, 59));
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'WEEK': {
        this.fromDate = new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 6).setHours(0, 0, 1));
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'MONTH': {
        this.fromDate = new Date();
        this.fromDate = new Date(this.fromDate.getFullYear(), this.fromDate.getMonth() - 1, 1);
        this.fromDate.setHours(0, 0, 1);
        this.toDate = new Date(new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setDate(0)).setHours(23, 59, 59));
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'QUARTER': {
        this.getQuarter();
        break;
      }
      case 'YEAR': {
        this.fromDate = new Date(new Date(new Date(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).setDate(1)).setHours(0, 0, 1)).setMonth(0));
        this.toDate = new Date(new Date(new Date(new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()).setMonth(12)).setDate(0)).setHours(23, 59, 59));
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'THIS MONTH': {
        this.fromDate = new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setHours(0, 0, 1));
        this.fromDate.setDate(1);
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'THIS QUARTER': {
        this.fromDate = new Date(new Date(today.getFullYear(), today.getMonth() - 3, 1, today.getDate()).setHours(0, 0, 1));
        this.fromDate.setDate(1);
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      case 'THIS YEAR': {
        this.fromDate = new Date(new Date(today.getFullYear(), today.getMonth(), today.getDate()).setHours(0, 0, 1));
        this.fromDate.setMonth(0);
        this.fromDate.setDate(1);
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
      default: {
        this.fromDate = new Date();
        this.toDate = new Date();
        this.enableDateStatus = false;
        this.rangeDates = [this.fromDate, this.toDate];
        break;
      }
    }
    this.franchiseService.dashboardFilters.timeframe = this.timeframe;
    this.franchiseService.dashboardFilters.fromDate = this.fromDate;
    this.franchiseService.dashboardFilters.toDate = this.toDate;
    // this.onSelectDates('','');
    this.setGranularity(event);
    this.prepareObjectToSend(true); // newly added
    // this.comparetimeframe = 'YESTERDAY'; // old working
    // this.compareChangeTimeframe(event, this.fromDate, this.toDate); // old working
  }

  getQuarter() {
    const d = new Date();
    const quarter = Math.floor((d.getMonth() / 3));
    this.fromDate = new Date(new Date(d.getFullYear(), quarter * 3 - 2, 1).setHours(0, 0, 1));
    this.toDate = new Date(new Date(this.fromDate.getFullYear(), this.fromDate.getMonth() + 3, 0).setHours(23, 59, 59));
    this.rangeDates = [this.fromDate, this.toDate];
  }

  onSelectDates() {
    // this.rangeDates;
    this.fromDate = this.rangeDates[0];
    this.toDate = this.rangeDates[1];
    let event = this.timeframe;
    event = 'YESTERDAY';
    this.compareChangeTimeframe(event, this.fromDate, this.toDate);
    this.franchiseService.dashboardFilters.fromDate = this.fromDate;
    this.franchiseService.dashboardFilters.toDate = this.toDate;
  }

  onCompareChangeTimeframe(event) {
    if (event.value === 'YESTERDAY') {
      event.value = this.timeframe;
      this.compareChangeTimeframe(event, this.fromDate, this.toDate);
    } else {
      this.compareChangeTimeframe(event, this.fromDate, this.toDate);
    }

    // this.compareChangeTimeframe(event, this.fromDate, this.toDate);

  }

  compareChangeTimeframe(event?, fromDate?, toDate?) {
    const fromDay = fromDate;
    const toDay = toDate;

    // if(firstChanged){
    //   this.comparetimeframe = '';
    //   this.comparedfromDate = '';
    //   this.comparedtoDate = '';
    //   this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
    //   this.prepareObjectToSend();
    //   return;
    // }
    this.comparedfromDate = fromDay;
    this.comparedtoDate = toDay;
    this.compareRangeDates = ['', ''];
    // this.enableDateStatus = true;
    switch (event.value) {
      case 'TODAY': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth(), fromDay.getDate() - 1).setHours(0, 0, 1));
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate() - 1).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'WEEK': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth(), fromDay.getDate() - 7).setHours(0, 0, 1));
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth(), toDay.getDate() - 7).setHours(0, 0, 1));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'MONTH': {
        this.comparedfromDate = new Date(fromDay.getFullYear(), fromDay.getMonth() - 1, 1);
        this.comparedfromDate.setHours(0, 0, 1);
        this.comparedtoDate = new Date(toDay.getFullYear(), toDay.getMonth() - 0, 0);
        this.comparedtoDate.setHours(23, 59, 59);
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'QUARTER': {
        this.getComparedQuarter();
        break;
      }
      case 'YEAR': {
        this.comparedfromDate = new Date(new Date(new Date(new Date(fromDay.getFullYear() - 1, fromDay.getMonth(), fromDay.getDate()).setDate(1)).setHours(0, 0, 1)).setMonth(0));
        this.comparedtoDate = new Date(new Date(new Date(new Date(toDay.getFullYear() - 1, toDay.getMonth(), toDay.getDate()).setMonth(12)).setDate(0)).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'THIS MONTH': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth() - 1, fromDay.getDate()).setHours(0, 0, 1));
        this.comparedfromDate.setDate(1);
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth() - 1, toDay.getDate()).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'THIS QUARTER': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth() - 3, 1, fromDay.getDate()).setHours(0, 0, 1));
        this.comparedfromDate.setDate(1);
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth() - 3, toDay.getDate()).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'THIS YEAR': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear() - 1, fromDay.getMonth(), fromDay.getDate()).setHours(0, 0, 1));
        this.comparedfromDate.setMonth(0);
        this.comparedfromDate.setDate(1);
        this.comparedtoDate = new Date(new Date(toDay.getFullYear() - 1, toDay.getMonth(), toDay.getDate()).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      // compare dropdown
      case 'PREVIOUS MONTH': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth() - 1, fromDay.getDate()).setHours(0, 0, 1));
        // ** old line **// this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth() - 1, toDay.getDate()-1).setHours(23, 59, 59));
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth() - 1, toDay.getDate()+1).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'PREVIOUS QUARTER': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear(), fromDay.getMonth() - 3,  fromDay.getDate()).setHours(0, 0, 1));
        this.comparedtoDate = new Date(new Date(toDay.getFullYear(), toDay.getMonth() - 3, toDay.getDate()).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      case 'PREVIOUS YEAR': {
        this.comparedfromDate = new Date(new Date(fromDay.getFullYear() - 1, fromDay.getMonth(), fromDay.getDate()).setHours(0, 0, 1));
        this.comparedtoDate = new Date(new Date(toDay.getFullYear() - 1, toDay.getMonth(), toDay.getDate()).setHours(23, 59, 59));
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }
      default: {
        this.comparedfromDate = null;
        this.comparedtoDate = null;
        // this.enableDateStatus = false;
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
        break;
      }

    }

    this.prepareObjectToSend();
  }


  getComparedQuarter() {
    const d = new Date();
    const quarter = Math.floor((d.getMonth() / 3));
    this.comparedfromDate = new Date(new Date(d.getFullYear(), quarter * 3 - 5, 1).setHours(0, 0, 1));
    this.comparedtoDate = new Date(new Date(this.comparedfromDate.getFullYear(), this.comparedfromDate.getMonth() + 3, 0).setHours(23, 59, 59));
    this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
  }

  setGranularity(event) {
    // console.log(event);
    if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[0].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_DAY;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[1].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_DAY;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[2].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_WEEK;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[3].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_MONTH;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[4].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_YEAR;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[5].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_WEEK;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[6].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_MONTH;
    } else if (event.value === AppSettings.DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS[7].value) {
      this.ViewOptions = AppSettings.DASHBOARD_GRANULARITY_YEAR;
    }
    const prevTimeFrameExist = this.ViewOptions.find(e => e.value == this.timeframeview);
    if(!prevTimeFrameExist){
      this.timeframeview = this.ViewOptions[0].value;
    }
    // console.log ('Granularity', this.timeframeview);
  }

  // only change granulity on select to load graph data
  handleGranularityChange() {
    this.prepareObjectToSend();
  }

  prepareObjectToSend(firstCalled?) {
    // console.log('prepareObjectToSend called');
    if (firstCalled) {
      // this.comparetimeframe = '';
      // this.comparedfromDate = null;
      // this.comparedtoDate = null;
      // this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
      if(this.franchiseService.dashboardFilters.comparetimeframe){
        this.comparetimeframe = this.franchiseService.dashboardFilters.comparetimeframe;
        this.comparedfromDate = this.franchiseService.dashboardFilters.comparedfromDate;
        this.comparedtoDate = this.franchiseService.dashboardFilters.comparedtoDate;
        this.compareRangeDates = [this.comparedfromDate, this.comparedtoDate];
      }
    }

    const reqObj = {
      'fromDate': this.fromDate,
      'toDate': this.toDate,
      'comparedfromDate': this.comparedfromDate,
      'comparedtoDate': this.comparedtoDate,
      'granuality': this.timeframeview,
      'compare': this.comparetimeframe ? true : false,
    };
    if (!this.comparetimeframe || this.comparetimeframe == '') {
      // this.noCompareEmmiter.emit(reqObj);
    } else {
      // this.filterObjectChangeEmmiter.emit(reqObj);
      this.franchiseService.dashboardFilters.comparetimeframe = this.comparetimeframe;
      this.franchiseService.dashboardFilters.comparedfromDate = this.comparedfromDate;
      this.franchiseService.dashboardFilters.comparedtoDate = this.comparedtoDate;
    }
    this.filterObjectChangeEmmiter.emit(reqObj);
  }

}
