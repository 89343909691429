import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';
import { UserCommonService } from '../../services/user-common.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-menu-items-list',
  templateUrl: './menu-items-list.component.html',
  styleUrls: ['./menu-items-list.component.scss']
})
export class MenuItemsListComponent implements OnInit {

  gridurl = '/secure/menus/new/grid-data';
  gridBaseUrl = `/secure/menus/new/grid-data`;
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  exportButton = 'top';
  // selectedBrands = [];
  // subscribeBrand: Subscription;

  search;
  params;
  items: any = [];
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  breadCrumbArray = [];
  pageHeading = this.commonService.getLabel('label_manage_food_extra_item');
  showAddMenu = false;
  timer = null;
  itemDetails;
  loginData;
  uniqueId = 'menu_items_List_01';
  saveFromCancelOffset: boolean = false;
  brandNameOptions = [];
  selectedBrandFromFilter;
  loginUserRole;
  isInputBrandId: boolean = false;
  isAdminLogin = true;
  previousBrand = [];
  readOnly = true;
  readWrite = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,
    private storageService: StorageService,
    private userCommonService: UserCommonService,
    private adminService: AdminService) {
    this.readOnly = commonBindingDataService.checkIfHavePermission('MANAGE_MENU', true);
    this.readWrite = commonBindingDataService.checkIfHavePermission('MANAGE_MENU', true, true);
    // this.subscribeBrand = this.userCommonService.changeBrand.subscribe((res:any)=>{
    //   this.selectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
    //   this.gridurl = this.gridBaseUrl;
    //   this.onClickFilter();
    // })
  }

  ngOnInit() {
    this.loginUserRole = this.commonService.getLoginRole();
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
      this.isInputBrandId = true;
      this.isAdminLogin = false;
    } else {
      this.isInputBrandId = false;
      this.isAdminLogin = true;
    }
    // this.selectedBrands = JSON.parse(this.storageService.getItem('selectedBrands'));
    this.commonService.clearTemplateStorage();
    // this.gridurl = this.gridurl+`?brandIds=${this.selectedBrands}`;
    this.getBrandList();
    this.route.queryParams
      .subscribe(params => {
        if (params.fromCancel) {
          this.saveFromCancelOffset = this.franchiseService.onlyPageOffSetSave;
          this.checkIfPreviousFilters();
        } else {
          this.saveFromCancelOffset = false;
          this.franchiseService.MenuCommonSearchFilters.search = '';
        }
      });
    this.createGrid();
    this.getMenuImagesList();
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    setTimeout(() => {
      this.franchiseService.onlyPageOffSetSave = false;
      this.saveFromCancelOffset = false;
    }, 1000);
  }

  getBrandList() {
    this.adminService.getAllBrandsList().subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.id, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      this.onClickFilter();
    });
  }

  onSelectBrand(e) {
    if (this.selectedBrandFromFilter.length === 0) {
      this.previousBrand[0] = e.itemValue;
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
      }, 300);
    }
    setTimeout(() => {
      this.onClickFilter();
    }, 300);
  }

  createGrid() {
    this.columns.push({
      field: 'image', width: '90px',
      header: this.commonService.getLabel('label_photo'), sort: false, resize: true, class: 'eclipse', center: true,
      htmlRenderer: (data) => {
        const value = data.signUrl ? data.signUrl : AppSettings.DEFAULT_NO_IMAGE;
        const finalValue = `<div class = "image-div">
            <img src="${value}" class="menu-image-list" /> </div>`;
        return finalValue;
      }
    });
    this.columns.push({
      field: 'name', width: '150px',
      header: this.commonService.getLabel('label_item'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'brandName', width: '100px',
      header: this.commonService.getLabel('label_brandName'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'description', width: '280px',
      header: this.commonService.getLabel('label_description'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'price', width: '90px',
      header: this.commonService.getLabel('label_selling_price'), sort: true, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        return '<div>$ ' + data.price.toFixed(2) + '</div>';
      }
    });
    if (this.readOnly) {
      this.buttons.push({ icon: '', class: 'menu-extra-itms-icon', label: 'Manage Food Extra Items' });
    }
    if (this.readWrite) {
      this.buttons.push({ icon: '', class: 'edit-pencil-icon', label: 'Edit' });
      this.buttons.push({
        field: 'eventHold',
        label: '',
        class: 'event-hold-icon', show: (data) => {
          if (data.activated) {
            return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
          } else {
            return '<div class="event-open-icon activate" title = "Activate"></div>';
          }
        }
      });
      this.buttons.push({ icon: '', class: 'event-delete-icon', label: 'Delete' });
    }
  }

  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'edit-pencil-icon':

          this.getMenuItemDetails(data.id);
          break;
        case 'event-hold-icon':
          this.changeEventStatus(data);
          break;
        case 'menu-extra-itms-icon':
          if (this.loginData) {
            const loginData = JSON.parse(this.loginData);
            const selectedMenu = {
              templateId: '',
              menu: data,
              from: 'menu'
            };
            this.storageService.setItem(AppSettings.MENU_ITEM_SELECTED, selectedMenu);
            if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
              this.franchiseService.onlyPageOffSetSave = true;
              this.router.navigate(['franchise/menu-section/menu-items/menu-details'], { queryParams: { id: data.id } });
              break;
            } else if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
              this.franchiseService.onlyPageOffSetSave = true;
              this.router.navigate(['admin/menu-section/menu-items/menu-details'], { queryParams: { id: data.id } });
              break;
            }
          }
          break;
        case 'event-delete-icon':
          this.deleteItem(data);
          break;
      }
    }
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^' + qoutes + ' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      // this.onReloadMenuList();
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  checkIfPreviousFilters() {
    this.saveFromCancelOffset = true;
    const previousFilters = this.franchiseService.MenuCommonSearchFilters;
    if (previousFilters.search) {
      this.search = previousFilters.search ? previousFilters.search.trim() : '';
      setTimeout(() => this.applyFilter('apply', true), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  applyFilter(event?, fromCancel?) {
    if (fromCancel !== true) {
      this.storageService.removeItem('listNavigatedOffset');
      this.gridRef.offset = 0;
    }
    if (event === 'apply') {
      this.params = '';
      // const searchValue = '';
      // if (this.selectedBrands) {
      //   this.params = `?brandIds=${this.selectedBrands}`;
      // }

      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
        this.params = '';
      } else {
        this.params += `?brandIds=${this.selectedBrandFromFilter}`;
      }

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
        // searchValue = this.search.trim();
        this.franchiseService.MenuCommonSearchFilters.search = this.search.trim();
        // this.franchiseService.clientsFilters.search = searchValue;
      }
      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }


  onAddNewClick() {
    this.showAddMenu = true;
  }

  onCancelAddNew() {
    this.itemDetails = '';
    this.showAddMenu = false;
  }

  getMenuItemDetails(itemId) {
    this.franchiseService.getMenuItemDetails(itemId).subscribe((result) => {
      this.itemDetails = result;
      this.onAddNewClick();
    });
  }

  changeEventStatus(data) {
    let objectToSend = {};
    let msg = this.commonService.getLabel('msg_activate_menu');
    if (data.activated) {
      msg = this.commonService.getLabel('msg_deactivate_menu');
    }
    objectToSend = {
      id: data.id,
      activated: !data.activated,
    };
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activeInactiveMenu(objectToSend).subscribe((result) => {
          this.onReloadMenuList();
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
        }, (error) => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      }
    });
  }

  deleteItem(data) {
    let objectToSend = {};
    objectToSend = {
      id: data.id,
      deleted: true,
    };
    this.confirmationService.confirm({
      message: this.commonService.getLabel('msg_food_extra_item_delete'),
      accept: () => {
        this.franchiseService.deleteMenuItem(objectToSend).subscribe((result) => {
          this.onReloadMenuList();
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
        }, (error) => {
          this.uiService.showMessage(error.general[0].message, AppSettings.MESSAGE_TYPE.ERROR);
        });
      }
    });
  }


  getMenuImagesList() {
    this.franchiseService.getMenuImageList().subscribe((result) => {
      this.franchiseService.menuItemsImageList = result;
    });
  }

  onReloadMenuList() {
    this.onCancelAddNew();
    this.saveFromCancelOffset = true;
    setTimeout(() => {
      // this.onClickFilter();
      this.gridRef.updateUrl(this.updatedUrl);
      this.saveFromCancelOffset = false;
    }, 300);

  }

  hideNewAddPopup() {
    this.itemDetails = '';
    this.showAddMenu = false;
  }


}

