import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserCommonService } from '../../modules/shared/services/user-common.service';

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  state: string;
  code: string;
  constructor(
    private router: Router,
    private userCommonService: UserCommonService,
    private activatedRoute: ActivatedRoute
  ) { }
  ngOnInit(): void {
    this.state = this.activatedRoute.snapshot.queryParams.state;
    this.code = this.activatedRoute.snapshot.queryParams.code;
    if (this.state && this.code) {
      this.callApi();
    }
  }

  callApi() {
    this.userCommonService.fetchStripe(this.code, this.state).subscribe(() => {
      // console.log(result);
      this.router.navigate(['/franchise/profile']);
    });
  }

}
