
<div class="page-container event-page">
  <div class="page-header">
    <div class="col-9">
      <h1 class="heading" translate>{{pageHeading}}</h1>
    </div>
    <div class="page-actions">
      <button pButton  (click)="onSave()" type="button" 
                  [label]="'btn_save'|translate"></button>
      <button pButton (click)="onCancel()" type="button" [label]="'btn_cancel'|translate"></button>
    </div>

  </div>
  <div class="page-body">
    <form [formGroup]="eventForm">
      <div class="form-container col-12 lg:col-8">
        <div class="form-item  col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="roleId" translate>label_role_type<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="roleId" [options]="roles"
                (onChange)="getUserList(eventForm.controls.roleId.value)"></p-dropdown>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.roleId.errors  && (eventForm.controls.roleId.dirty || eventForm.controls.roleId.touched ||
                                 submitted)">
              <div [hidden]="!eventForm.controls.roleId.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.roleId.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.roleId.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
        <div class="form-item col-12 lg:col-5 md:col-5" *ngIf = "showOrderCount">
          <div class="form-field">
            <div class="form-label">
              <label for="orderCount" translate>label_order_count<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <input formControlName="orderCount"  class="full-width" type="text"  appTwoDecimal
                (keyup)="getUserList(eventForm.controls.orderCount.value,eventForm.controls.orderCount.value)" appTwoDecimal  pInputText />
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.orderCount.errors  && (eventForm.controls.orderCount.dirty || eventForm.controls.orderCount.touched ||
                                 submitted)">
              <div [hidden]="!eventForm.controls.orderCount.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.orderCount.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.orderCount.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-container col-12 lg:col-8">
        <div class="form-item col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="userIds" translate>label_user<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-multiSelect [options]="users" appendTo="body" formControlName="userIds" [style]="{'width': '100%','height': '33px','padding-right': '0'}" filter="true" selectedItemsLabel="{0} items selected">
              </p-multiSelect>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.userIds.errors  && (eventForm.controls.userIds.dirty || eventForm.controls.userIds.touched ||
                                       submitted)">
              <div [hidden]="!eventForm.controls.userIds.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.userIds.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.userIds.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>

        <div class="form-item  col-12 lg:col-5 md:col-5">
          <div class="form-field">
            <div class="form-label">
              <label for="segmentName" translate>label_segment_name<span class="required-star">*</span></label>
            </div>
            <div class="form-control">
              <p-dropdown formControlName="segmentName" [options]="roles"
                (onChange)="getUserList(eventForm.controls.segmentName.value)"></p-dropdown>
            </div>
            <div class="form-errors" *ngIf="eventForm.controls.segmentName.errors  && (eventForm.controls.segmentName.dirty || eventForm.controls.segmentName.touched ||
                                           submitted)">
              <div [hidden]="!eventForm.controls.segmentName.errors.required" translate>
                field_is_required
              </div>
              <div [hidden]="!eventForm.controls.segmentName.errors.minlength" translate>
                min_length_1char
              </div>
              <div [hidden]="!eventForm.controls.segmentName.errors.maxlength" translate>
                max_length_50char
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>
  </div>
</div>