import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';
import { Subscription } from 'rxjs';
import { UserCommonService } from '../../services/user-common.service';
import { AdminService } from '../../../admin/services/admin.service';

@Component({
  selector: 'app-menu-food-extra-category-list',
  templateUrl: './menu-food-extra-category-list.component.html',
  styleUrls: ['./menu-food-extra-category-list.component.scss']
})
export class MenuFoodExtraCategoryListComponent implements OnInit {

  loginData;
  gridurl = '/secure/food-extra-categories/grid-data?applyActivatedStatus=false&activated=false';
  exportBaseUrl = '/secure/campaigns/export-xls?';
  updatedUrl = '';
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  exportButton = 'top';
  ID;
  search;
  params;
  valuesOptions: any = [];
  selectedValue = '';
  timer = null;
  uniqueId = 'menu_foodExtra_category_List_01';
  saveFromCancelOffset: boolean = false;
  selectedBrands = [];
  subscribeBrand: Subscription;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  brandNameOptions: any = [];
  selectedBrandFromFilter = [];
  showBrandfilter: boolean;
  loggedInUserId;
  loginUserRole;
  franchiseLogin: boolean = false;
  isAdminLogin = true;
  previousBrand = [];
  readOnly = true;
  readWrite = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
    private commonBindingDataService: CommonBindingDataService,
    private franchiseService: FranchiseService,
    private storageService: StorageService,
    private userCommandService: UserCommonService,
    private adminService: AdminService) {
    this.readOnly = commonBindingDataService.checkIfHavePermission('MANAGE_MENU', true);
    this.readWrite = commonBindingDataService.checkIfHavePermission('MANAGE_MENU', true, true);
  }

  ngOnInit() {
    this.loginUserRole = this.commonBindingDataService.getLoginRole();
    this.ID = this.route.snapshot.queryParams.id;
    if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
      this.franchiseLogin = true;
      this.isAdminLogin = false;
    } else {
      this.franchiseLogin = false;
      this.isAdminLogin = true;
    }
    this.getBrandList();
    this.route.queryParams
      .subscribe(params => {
        if (params.fromCancel) {
          this.saveFromCancelOffset = this.franchiseService.onlyPageOffSetSave;
          this.checkIfPreviousFilters();
        } else {
          this.saveFromCancelOffset = false;
          this.franchiseService.MenuCommonSearchFilters.search = '';
        }
      });
    this.createGrid();
    this.getMenuImagesList();
    setTimeout(() => {
      this.franchiseService.onlyPageOffSetSave = false;
      this.saveFromCancelOffset = false;
    }, 1000);
  }

  createGrid() {
    this.columns.push({
      field: 'categoryName', width: '150px',
      header: this.commonBindingDataService.getLabel('label_food_extra_category'), sort: true, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'brandName', width: '150px',
      header: this.commonBindingDataService.getLabel('label_brandName'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'type', width: '120px',
      header: this.commonBindingDataService.getLabel('label_type'), sort: true, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const address = (data.type === 'optional') ? 'Optional' : 'Mandatory';
        return address;
      }
    });

    this.columns.push({
      field: 'minQtyAllowed', width: '90px',
      header: this.commonBindingDataService.getLabel('label_min_quentity'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const address = (data.minQtyAllowed > 0) ? data.minQtyAllowed : 'NA';
        return address;
      }
    });

    this.columns.push({
      field: 'maxQtyAllowed', width: '90px',
      header: this.commonBindingDataService.getLabel('label_max_quentity'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const address = (data.maxQtyAllowed > 0) ? data.maxQtyAllowed : 'NA';
        return address;
      }
    });

    if (this.readOnly && !this.readWrite) {
      this.buttons.push({ icon: '', class: 'details-icon', label: 'View Items' });
    }
    if (this.readWrite) {
      this.buttons.push({ icon: '', class: 'edit-pencil-icon', label: 'Edit' });
      this.buttons.push({
        field: 'eventHold',
        label: '',
        class: 'event-hold-icon', show: (data) => {
          if (data.activated) {
            return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
          } else {
            return '<div class="event-open-icon activate" title = "Activate"></div>';
          }
        }
      });
      this.buttons.push({ icon: '', class: 'event-delete-icon', label: 'Delete' });
      // this.buttons.push({ icon: '', class: 'details-icon', label: 'View Items' });
    }
  }

  getBrandList() {
    this.adminService.getAllBrandsList().subscribe((result) => {
      this.brandNameOptions = result.map(element => ({
        label: element.brandName, value: element.id, brandId: element.brandId,
        activated: element.activated, color: element.color,
        deleted: element.deleted, inactive: false,
      }));
      this.selectedBrandFromFilter = this.brandNameOptions.map((item) => item.value.trim());
      this.onClickFilter();
    });
  }

  onSelectBrand(e) {
    if (this.selectedBrandFromFilter.length === 0) {
      this.previousBrand[0] = e.itemValue;
      setTimeout(() => {
        this.selectedBrandFromFilter = this.previousBrand;
      }, 300);
    }
    setTimeout(() => {
      this.onClickFilter();
    }, 300);
  }

  onActionBtnClicked(e: any) {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'details-icon':
          this.editClicktHandling(data);
          break;
        case 'edit-pencil-icon':
          this.editClicktHandling(data);
          break;
        case 'event-hold-icon':
          this.changeEventStatus(data);
          break;

        // case 'details-icon': // old code
        //   if (this.loginData) {
        //     const loginData = JSON.parse(this.loginData);
        //     if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        //       this.router.navigate(['franchise/menu-section/food-extras/food-extra-items'], {
        //         queryParams: {
        //           id: data.id, name: data.categoryName, type: data.type,
        //           minQtyAllowed: data.minQtyAllowed, maxQtyAllowed: data.maxQtyAllowed
        //         }
        //       });
        //     } else {
        //       this.router.navigate(['admin/menu-section/food-extras/food-extra-items'], {
        //         queryParams: {
        //           id: data.id, name: data.categoryName, type: data.type,
        //           minQtyAllowed: data.minQtyAllowed, maxQtyAllowed: data.maxQtyAllowed
        //         }
        //       });
        //     }
        //   }
        case 'event-delete-icon':
          this.deleteTemplate(data);
          break;
      }
    }
  }

  editClicktHandling(data) {
    if (this.loginData) {
      const loginData = JSON.parse(this.loginData);
      if (loginData.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || loginData.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        this.franchiseService.onlyPageOffSetSave = true;
        this.router.navigate(['franchise/menu-section/food-extras/edit-category'], { queryParams: { id: data.id } });
      } else {
        this.franchiseService.onlyPageOffSetSave = true;
        this.router.navigate(['admin/menu-section/food-extras/edit-category'], { queryParams: { id: data.id } });
      }
    }
  }

  deleteTemplate(data) {
    let objectToSend = {};

    objectToSend = {
      id: data.id,
      deleted: true,
    };
    this.confirmationService.confirm({
      message: this.commonBindingDataService.getLabel('msg_food_extra_cat_delete'),
      accept: () => {
        this.franchiseService.deleteFoodExtraCategory(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.gridRef.updateUrl(this.gridurl);
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  changeEventStatus(data) {
    let objectToSend = {};
    let msg = this.commonBindingDataService.getLabel('msg_food_extra_cat_open');
    if (data.activated) {
      msg = this.commonBindingDataService.getLabel('msg_food_extra_cat_hold');
    }
    objectToSend = {
      id: data.id,
      activated: !data.activated
    };
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activeInactiveFoodExtraCategory(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.gridRef.updateUrl(this.gridurl);
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^' + qoutes + ' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
      // this.applyFilter('apply');
    }
  }

  checkIfPreviousFilters() {
    this.saveFromCancelOffset = true;
    const previousFilters = this.franchiseService.MenuCommonSearchFilters;
    if (previousFilters.search) {
      this.search = previousFilters.search ? previousFilters.search.trim() : '';
      setTimeout(() => this.applyFilter('apply', true), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  applyFilter(event?, fromCancel?) {
    if (fromCancel !== true) {
      this.storageService.removeItem('listNavigatedOffset');
      this.gridRef.offset = 0;
    }
    if (event === 'apply') {
      this.params = '';

      if (this.loginUserRole === AppSettings.USER_ROLE_FRANCHISE || this.loginUserRole == AppSettings.STAFF_ROLES['Manager']) {
        this.params = '';
      } else {
        this.params += `&brandIds=${this.selectedBrandFromFilter}`;
      }

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
        this.franchiseService.MenuCommonSearchFilters.search = this.search.trim();
      }

      this.updatedUrl = this.gridurl + this.params;
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  onResetFilter() {
    this.gridRef.searchText = '';
    this.search = '';
    this.gridRef.updateUrl(this.gridurl);
  }

  onAdd() {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE || data.roles[0].roleCode === AppSettings.STAFF_ROLES['Manager']) {
        this.router.navigate(['franchise/menu-section/food-extras/add-category']);
      } else {
        this.router.navigate(['admin/menu-section/food-extras/add-category']);
      }
    }
  }

  getMenuImagesList() {
    this.franchiseService.getMenuImageList('foodExtraItemFileIds').subscribe((result) => {
      this.franchiseService.foodExtraItemsImageList = result;
    });
  }

  goBack() {
    window.history.back();
  }

}

