import { Component, OnInit } from '@angular/core';

import { AppSettings } from './../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { SidebarService } from './../../services/sidebar.service';
import { StorageService } from './../../services/storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class SidebarComponent implements OnInit {
  // items: MenuItem[];
  subscription: Subscription;
  user;
  items: MenuItem[];
  loginData: any;
  versionCode: any;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonBindingDataService,
    private sidebarService: SidebarService,
    private franchiseService: FranchiseService,
  ) { }

  ngOnInit() {
    this.versionCode = '';
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    // this.initSidebarItems();
    // this.manageActiveStatus();
    this.checkRole();
    // this.createMenuList();
    this.getVersionData();
  }

  checkRole() {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const staffAsManager = JSON.parse(this.storageService.getItem('staffAsManager'));
    // console.log(staffAsManager)
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE) {
        this.createMenuList(AppSettings.USER_ROLE_FRANCHISE);
      } else if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.createMenuList(AppSettings.USER_ROLE_ADMIN);
      } else if (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager'] && staffAsManager === "true") {
        this.createMenuList(AppSettings.USER_ROLE_FRANCHISE);
      } else if (data.roles[0].roleCode == AppSettings.STAFF_ROLES['Driver'] || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Manager']
        || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Server'] || data.roles[0].roleCode == AppSettings.STAFF_ROLES['Worker']) {
        this.createMenuList(data.roles[0].roleCode);
      }
    }
  }

  resetDate() {
    this.franchiseService.eventViewDate.startDateTime = null;
    this.franchiseService.eventViewDate.endDateTime = null;
    if(this.franchiseService && this.franchiseService.eventsFilters){
      this.franchiseService.eventsFilters.fromEditInnerPage = false;
    }
    // console.log('sidebar',this.franchiseService.eventsFilters.fromEditInnerPage);
  }

  createMenuList(roleId?) {
    if (roleId === AppSettings.USER_ROLE_FRANCHISE) {
      this.franchiseSideMenuItems();
      // if (screen.width < 740) {
      //   this.franchiseSideMenuItemsResponsive();
      // }else{
      //   this.franchiseSideMenuItems();
      // }
    }
    if (roleId === AppSettings.USER_ROLE_ADMIN) {
      this.adminSideMenuItems();

    }
    if (roleId == AppSettings.STAFF_ROLES['Driver'] || roleId == AppSettings.STAFF_ROLES['Manager']
      || roleId == AppSettings.STAFF_ROLES['Server'] || roleId == AppSettings.STAFF_ROLES['Worker']) {
      this.staffSideMenuItems();
    }
  }

  reSetStorageListOffset() {
    this.storageService.removeItem('listNavigatedOffset');
  }

  franchiseSideMenuItems() {
    const tempItems = [
      // may need in future
      // {
      //   label: this.commonService.getLabel('sidebar_lbl_campaign'),
      //   icon: 'fa sidebar-campaign-icon',
      //   routerLink: ['campaign'],
      // },

      {
        label: this.commonService.getLabel('label_dashboard'),
        icon: 'fa sidebar-icon sidebar-dashboard-icon',
        routerLink: ['dashboard-info'],
        title: "MANAGE_DASHBOARD"
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_client'),
        icon: 'fa sidebar-icon sidebar-clients-icon',
        routerLink: ['client'],
        title: "MANAGE_CLIENT",
        command: () => {
          // console.log('client');
          this.reSetStorageListOffset();
        }
      },
      {
        command: () => {
          this.resetDate(); this.reSetStorageListOffset(); 
        }, //console.log('events')
        label: this.commonService.getLabel('sidebar_lbl_events'),
        icon: 'fa sidebar-icon sidebar-event-icon',
        routerLink: ['events'],
        title: "MANAGE_EVENT"
      },
      {
        label: this.commonService.getLabel('Invoice'),
        icon: 'fa sidebar-icon sidebar-invoice-icon',
        routerLink: ['invoice'],
        title: "MANAGE_INVOICE",
        command: () => {
          // console.log('client');
          this.reSetStorageListOffset();
        }
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_menu'),
        icon: 'fa sidebar-icon sidebar-menu-icon',
        title: "MANAGE_MENU",
        items: [

          {
            label: 'Manage Template', icon: 'manage-template-menu', routerLink: ['menu-section/templatelist'], command: () => {
            this.reSetStorageListOffset(); 
          }},

          {
            label: 'Manage Category', icon: 'manage-category-menu', routerLink: ['menu-section/menu-categories'], command: () => {
            this.reSetStorageListOffset(); 
          }},

          {
            label: 'Menu Items', icon: 'menu-items-menu', routerLink: ['menu-section/menu-items'], command: () => {
            this.reSetStorageListOffset(); 
          }},

          {
            label: 'Extras Category', icon: 'extra-category-menu', routerLink: ['menu-section/food-extras'], command: () => {
            this.reSetStorageListOffset(); 
          }},

          {
            label: 'Extras Items ', icon: 'extra-items-menu', routerLink: ['menu-section/food-extra-items'], command: () => {
            this.reSetStorageListOffset(); 
          }},
        ]
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_asset'),
        icon: 'fa sidebar-icon sidebar-assets-icon',
        routerLink: ['assets'],
        title: "MANAGE_EQUIPMENT"
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_staff'),
        icon: 'fa sidebar-icon sidebar-staff-icon',
        title: "MANAGE_STAFF",
        items: [
          {
            label: 'Manage Staff', icon: 'sub-menu-staff', routerLink: ['staff/manage'],
            title: "MANAGE_STAFF",
          },
          {
            label: 'Schedule', icon: 'sub-menu-schedule', routerLink: ['staff/schedule'],
            title: "MANAGE_SHIFT_SCHEDULE",
          },
          {
            label: 'Requests', icon: 'sub-menu-staff', routerLink: ['staff/time-off'],
            title: "MANAGE_TIME_OFF",
          },
          {
            label: 'Notify Staff', icon: 'sub-menu-staff', routerLink: ['staff/notifications'],
            title: "MANAGE_NOTIFY_STAFF",
          },
        ]
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_reports'),
        icon: 'fa sidebar-icon sidebar-reports-icon',
        title: "MANAGE_REPORT",
        // routerLink: ['reports'],
        items: [
          { label: 'Orders', icon: 'sub-menu-order', routerLink: ['reports/orders'] },
          { label: 'Payout Report', icon: 'sub-menu-payout', routerLink: ['reports/payout'] },
          //{
          //  label: this.commonService.getLabel('label_manage_customers'),
          //  icon: 'fa sidebar-icon sidebar-franchise-icon',
          //  routerLink: ['customers'],
          //},
          {
            label: 'Sales',
            icon: 'sub-menu-sales',
            routerLink: ['reports/sales']
          },
          { label: 'Equipment', icon: 'sub-menu-report-equipment', routerLink: ['reports/equipment'] },
          { label: 'Client Ranking', icon: 'sub-menu-report-clientranking', routerLink: ['reports/client-ranking'] },
          { label: 'Staff', icon: 'sub-menu-staffreport', routerLink: ['reports/staff'] }
          //{ label: 'Sales', icon: 'sub-menu-payout', routerLink: ['reports/sales'] },
          //{ label: 'Give Back', icon: 'sub-menu-payout', routerLink: ['reports/give-back'] },
          // { label: 'Campaign', icon: 'pi pi-pi pi-bars', routerLink: ['reports/campaign-reports'] },
          // { label: 'All Donations', icon: 'pi pi-pi pi-bars', routerLink: ['reports/all-donations'] }
        ]
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_marketing'),
        icon: 'fa sidebar-icon sidebar-marketing-icon',
        title: "MANAGE_NO_REPLIES",
        // title: "MANAGE_MARKETING",
        items: [
          {label: this.commonService.getLabel('label_no_replies'), icon: 'fa sidebar-icon sidebar-no-reply-icon', title: 'MANAGE_NO_REPLIES', routerLink: ['futureBookings'],
           command: () => {
          this.reSetStorageListOffset(); 
          }},
          { label: 'Campaign', icon: 'fa sidebar-icon sidebar-campaign-icon', title:'MANAGE_CAMPAIGN', routerLink: ['marketing/campaign'] },
          { label: 'Legacy Data', icon: 'fa sidebar-icon sidebar-contacts-icon', title: 'MANAGE_LEGACY_DATA', routerLink: ['marketing/legacy-data'] },
          {label: 'Aging Report', icon: 'sub-menu-report-clientranking', title: 'AGING_REPORT', routerLink: ['marketing/aging-report'] },
        ]
        // routerLink: ['marketing/campaign'],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_settings'),
        icon: 'fa sidebar-icon sidebar-settings-icon',
        routerLink: ['settings'],
        title: "MANAGE_SETTINGS"
      },
    ];

    const temp1 = [...tempItems];
   
    const loginRole =  this.commonService.getLoginRole(); 
    if (loginRole == AppSettings.USER_ROLE_MANAGER) {  
      // const marketingIndex = temp1.findIndex(ele=> ele.label === 'Marketing');
      // if(marketingIndex > 0) {
      //   temp1.splice(marketingIndex, 1);
      // }
      // console.log(temp1)
      tempItems.forEach(element => {
        // let menuPresent = true;
        if (!this.commonService.checkIfHavePermission(element.title, true)) {
          const foundIndex = temp1.findIndex(e => e.title == element.title);
          // let checkFor;
          if (foundIndex > -1) {
            if (element.title == 'MANAGE_EVENT' && this.checkIfHasTask()) {
              return;
            }
            temp1.splice(foundIndex, 1);
            // menuPresent = false;
            // console.log('temp1', temp1, foundIndex);
          }
        }
        if (element.items) {
          const temp2 = [];
          let submenuFound = false;
          element.items.forEach(eachSubMenu => {
            if (eachSubMenu['title']) {
              if (this.commonService.checkIfHavePermission(eachSubMenu['title'], true)) {
                temp2.push(eachSubMenu);
                submenuFound = true;
              }
              if (eachSubMenu['title'] == 'MANAGE_TIME_OFF') { //** special case */
                if (this.commonService.checkIfHavePermission('MANAGE_SHIFT_SWAP',true) 
                && !this.commonService.checkIfHavePermission('MANAGE_TIME_OFF',true)) {
                  temp2.push(eachSubMenu);
                  submenuFound = true;
                }
              }
            }
          });
          if (temp2.length > 0 && submenuFound) {
            element.items = [...temp2];
            const itemIndex = temp1.findIndex(e => e.title == element.title);
            // console.log('in',itemIndex)
            temp1.splice(itemIndex,1,element);
            // temp1.push(element);
            // console.log('tempItems2', element);
          }
        }

        // special cases 
        // if(hasSinglePermition) { // add events module in array 
        //   let foundIndex = temp1.findIndex(e => e.title == 'MANAGE_EVENT');
        //   if (foundIndex == -1) { // if not in temp1
        //     let foundEvent = tempItems.find(e => e.title == 'MANAGE_EVENT');
        //     temp1.push(foundEvent);
        //   }
        // }
      });

    }

    // console.log('tempItems0', temp1);
    
    this.items = [...temp1];
    // this.items =  [{
    //     label: this.commonService.getLabel('label_dashboard'),
    //     icon: 'fa sidebar-icon sidebar-dashboard-icon',
    //     routerLink: ['dashboard-info'],
    //     title: "MANAGE_DASHBOARD"
    //   }]
    
    // this.commonService.sidebarItems = [...temp1];
    // if (screen.width < 740) {
    //   let dashboardIndex = this.items.findIndex(e => e.routerLink[0] == 'dashboard-info');
    //   if(dashboardIndex > -1){
    //     this.items.splice(0,1)
    //   }
    // }

    // console.log('items', this.items);
  }

  checkIfHasTask() {
    const taskPermitions = ['MANAGE_TASK', 'ASSIGN_STAFF_TO_TASK', 'ASSIGN_EQUIPMENT_TO_TASK'];
    let hasSinglePermition = false;
    taskPermitions.forEach(eachTaskPer => {
      if (this.commonService.checkIfHavePermission(eachTaskPer, true)) {
        hasSinglePermition = true;
      }
    });
    return hasSinglePermition;
  }

  adminSideMenuItems() {
    this.items = [
      // {
      //   label: this.commonService.getLabel('label_dashboard'),
      //   icon: 'fa sidebar-icon sidebar-dashboard-icon',
      //   routerLink: ['dashboard-info'],
      // },
      {
        label: this.commonService.getLabel('sidebar_lbl_franchise'),
        icon: 'fa sidebar-icon sidebar-franchise-icon',
        routerLink: ['franchise'],
      },

      {
        label: this.commonService.getLabel('sidebar_lbl_menu'),
        icon: 'fa sidebar-icon sidebar-menu-icon',
        items: [

          { label: 'Manage Template', icon: 'manage-template-menu', routerLink: ['menu-section/templatelist'], command: () => {
 this.reSetStorageListOffset(); 
} },

          { label: 'Manage Category', icon: 'manage-category-menu', routerLink: ['menu-section/menu-categories'], command: () => {
 this.reSetStorageListOffset(); 
} },

          { label: 'Menu Items', icon: 'menu-items-menu', routerLink: ['menu-section/menu-items'], command: () => {
 this.reSetStorageListOffset(); 
} },

          { label: 'Extras Category', icon: 'extra-category-menu', routerLink: ['menu-section/food-extras'], command: () => {
 this.reSetStorageListOffset(); 
} },

          { label: 'Extras Items ', icon: 'extra-items-menu', routerLink: ['menu-section/food-extra-items'], command: () => {
 this.reSetStorageListOffset(); 
} },
        ]
      },

      {
        label: this.commonService.getLabel('sidebar_lbl_reports'),
        icon: 'fa sidebar-icon sidebar-reports-icon',
        routerLink: ['dashboard'],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_marketing'),
        icon: 'fa sidebar-icon sidebar-marketing-icon',
        items: [
          { label: 'Campaign', icon: 'fa sidebar-icon sidebar-campaign-icon', routerLink: ['marketing/campaign'] },
        ]
        // routerLink: ['marketing/campaign'],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_migration'),
        icon: 'fa sidebar-icon sidebar-migration-icon',
        routerLink: ['franchise-migration'],
        // items: [
        //   { label: 'Partial Migration', icon: 'fa sidebar-icon sidebar-migration-icon', routerLink: ['migration/partial-migration'] },
        //   { label: 'Partial Migration New', icon: 'fa sidebar-icon sidebar-migration-icon', routerLink: ['migration/partial-migration-new'] },
        // ]
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_admin_setting'),
        icon: 'fa sidebar-icon sidebar-waffle-icon',
        routerLink: ['manage-waffle'],
      },
      {
        label: this.commonService.getLabel('label_settings'),
        icon: 'fa sidebar-icon sidebar-settings-icon',
        routerLink: ['admin-settings'],
      },
      
      /*  {
         label: this.commonService.getLabel('label_manage_customers'),
         icon: 'fa sidebar-icon sidebar-customer-icon',
         routerLink: ['customers'],
       }, */
      // {
      //   label: this.commonService.getLabel('sidebar_lbl_marketing'),
      //   icon: 'fa sidebar-reports-icon',
      //   items: [
      //     { label: 'Notifications', icon: 'pi pi-pi pi-bars', routerLink: ['marketing/notifications'] },
      //     { label: 'Segments', icon: 'pi pi-pi pi-bars', routerLink: ['marketing/segments'] },
      //   ]
      // },
    ];
  }

  staffSideMenuItems() {
    this.items = [
      // dashboard menu hide
      // {
      //   label: this.commonService.getLabel('sidebar_lbl_dashboard'),
      //   icon: 'fa sidebar-icon sidebar-dashboard-icon',
      //   routerLink: ['/staff/dashboard'],

      //   // command: (event) => {
      //   //   //event.originalEvent: Browser event
      //   //   //event.item: menuitem metadata
      //   //   console.log(event.originalEvent);
      //   // }
      // },
      {
        label: this.commonService.getLabel('sidebar_lbl_schedule'),
        icon: 'fa sidebar-icon sub-menu-schedule',
        routerLink: ['/staff/event-schedules'],
        command: () => {
 this.resetFromCancleDate(); 
},
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_clockin_out'),
        icon: 'fa sidebar-icon sidebar-clock-icon',
        routerLink: ['/staff/clock-in-out'],
      },
      {
        label: this.commonService.getLabel('sidebar_lbl_availability'),
        icon: 'fa sidebar-icon sidebar-franchise-icon', // sidebar-availabity-icon
        routerLink: ['/staff/availability'],
        command: () => {
 this.resetFromCancleDate(); 
},
      },

      {
        label: this.commonService.getLabel('sidebar_lbl_request_time_off'),
        icon: 'fa sidebar-icon sidebar-clock-icon', // sidebar-request-icon
        routerLink: ['/staff/request-time-off'],
      },

      // {
      //   label: this.commonService.getLabel('sidebar_lbl_menu'),
      //   icon: 'fa sidebar-icon sidebar-menu-icon',
      //   routerLink: ['franchise'],
      // },
      // {
      //   label: this.commonService.getLabel('sidebar_lbl_chat'),
      //   icon: 'fa sidebar-icon sidebar-franchise-icon',
      //   routerLink: ['franchise'],
      // },
      {
        label: this.commonService.getLabel('Profile'),
        icon: 'fa sidebar-icon sidebar-settings-icon',
        routerLink: ['/franchise/profile'],
      },

      // {
      //   label: this.commonService.getLabel('Profile'),
      //   icon: 'fa fa-sign-out',
      //   routerLink: ['/franchise/profile'],
      // },

    ];
  }

  initSidebarItems() {
    this.items = [];
    this.user = this.storageService.getItem(AppSettings.USER_DETAILS);
    this.subscription = this.sidebarService.sideState.subscribe(
      (state: any) => {
        this.items = state;
        this.items = this.removeDuplicates(this.items, 'label');
      }
    );
    if (this.items.length <= 0) {
      if (this.user !== null && this.user !== undefined) {
        this.items = this.getMenu();
      }
    }
    this.manageActiveStatus();
  }


  removeDuplicates(myArr, prop) {
    return myArr.filter((obj, pos, arr) => {
      return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
  }

  private manageActiveStatus() {
    for (let i = 0; i < this.items.length; i++) {
      const item = this.items[i];
      if (item.routerLink) {
        if (
          item.routerLink.length >= 1 &&
          document.location.pathname === item.routerLink[0]
        ) {
          item.expanded = true;
        }
      }
    }
  }

  getMenu() {
    return this.sidebarService.adminSideBar;
  }

  goToHome() {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    const data = JSON.parse(this.loginData);
    this.redirectToDashboard(data);
  }

  redirectToDashboard(data) {
    if (data.roles[0].roleCode === AppSettings.USER_ROLE_FRANCHISE) {
      if (screen.width < 740) {
        this.router.navigate(['/franchise/events']);
      } else {
        this.router.navigate(['/franchise/client']);
      }

    }
    if (data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  redirectTo() {
    // console.log('inside redirectTo')
    this.router.navigate(['/staff/dashboard']);
  }

  openSidebar() {
    if (screen.width >= 768) {
      document.getElementsByTagName('body')[0].classList.toggle('closed');
    }
    // document.getElementsByTagName('body')[0].classList.remove('closed');
  }

  closeSidebar() {
    if (screen.width >= 768) {
      document.getElementsByTagName('body')[0].classList.add('closed');
    }
  }

  getVersionData() {
    this.franchiseService.getVersion().subscribe((result) => {
      this.versionCode = result.versionsDto.version;
    });
  }

  resetFromCancleDate() {
    this.storageService.removeItem('fromCancelDate');
  }

}
