import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings } from '../../../shared/app.settings';
import { StorageService } from './../../services/storage.service';
import { LoaderService } from './../../components/loader/loader.service';
import { CommonBindingDataService } from './../../services/common-binding-data.service';
import { UiService } from '../../../shared/services/ui.service';
// import { Message } from 'primeng/api';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-file-uploader',
  templateUrl: './file.uploader.component.html',
  styleUrls: ['./file.uploader.component.scss']
})
export class FileUploaderComponent implements OnInit {
  @Input() accept;
  @Input() uploadButtonLabel;
  @Input() inputUrl;
  @Output() beforeFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadEvent: EventEmitter<any> = new EventEmitter();
  @Output() afterFileUploadFailed: EventEmitter<any> = new EventEmitter();
  @Input() maxFileSize;
  @Input() maxFileSizeMessage;
  fileUploadUrl: string = AppSettings.UPLOAD_FILE_URL;
  maxSizeMessage;
  maxMessage;
  message: Message[] = [];
  cred = true;
  acceptfile = ".xls, .xlsx";
  maxSize = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  disableBackground = false;
  appheaders: any;

  constructor(
    private storageService: StorageService,
    private translate: TranslateService,
    private loaderService: LoaderService,
    private uiService: UiService,
    private commonService: CommonBindingDataService,
  ) { 
    // this.appheaders =  new HttpHeaders().set("Authorization", 'Bearer ' + this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY));
  }

  ngOnInit() {
    if (this.maxFileSize) {
      this.maxSize = this.maxFileSize;
    }
    if (this.inputUrl) {
      this.fileUploadUrl = this.inputUrl;
    }

    if (this.accept) {
      this.acceptfile = this.accept;
    }

    this.maxSizeMessage = this.commonService.getLabel('max_file_upload_size'); // 3mb
    if (this.maxFileSizeMessage) {
      this.maxSizeMessage = this.maxFileSizeMessage;
    }
  }

  validateFileSize($event: any): void {
    if ($event.files[0].size > this.maxFileSize) {
      this.uiService.showMessage(this.maxSizeMessage, AppSettings.MESSAGE_TYPE.ERROR);
      return;
    } else {
      this.disableBackground = true;
      this.loaderService.show('page-center');
    }
    this.acceptFile($event);
  }

  onUpload(event) {
     this.loaderService.show('page-center');
    this.beforeFileUploadEvent.emit(event);
    // const xhr = event.xhr;
    // const response = JSON.parse(xhr.response);
    // this.afterFileUploadEvent.emit(response);

    // const xhr = event;
    // const response = JSON.parse(xhr.response);
    this.afterFileUploadEvent.emit(event);
    this.loaderService.hide();
    this.disableBackground = false;
  }

  onBeforeSend(event) {
    this.loaderService.show('page-center');
    const xhr = event.xhr;
    xhr.setRequestHeader(
      AppSettings.HEADER_AUTHORIZATION,
      this.storageService.getItemFromCookies(AppSettings.TOKEN_KEY)
    );
    // this.loaderService.show('page-center');
  }

  onUploadError(event: any) {
    this.loaderService.show('page-center');
    this.message = [];
    this.message.push({
      severity: 'error',
      summary: 'Error',
      detail: this.commonService.getLabel('file_upload_error')
    });
    this.afterFileUploadFailed.emit(event);
    this.loaderService.hide();
    this.disableBackground = false;
  }
  acceptFile($event) {
    const file_extension = $event.files[0].name.split('.').pop().toLowerCase();
    // console.log("hiiii",file_extension);
    const position = this.acceptfile.search(file_extension);
    // console.log('position',position)
    if(position == -1){
      this.uiService.showMessage("Files allowed: "  + this.acceptfile, AppSettings.MESSAGE_TYPE.ERROR);
     // this.loaderService.hide();
      this.disableBackground = false;
      this.loaderService.hide();
      return;
    } else{
     // this.loaderService.hide();
    }
  }

}
