import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { CommonBindingDataService } from './services/common-binding-data.service';

@Injectable()
export class AppSettings {

  constructor(private commonBindingDataService: CommonBindingDataService) {
   
  }
  public static BASE_URL = environment.basePath;
  public static BASE_URL_CUSTOMER = environment.basePathCustomer;
  public static IS_IDLE_TIME = environment.idleTimeEnable;
  // public static IDLE_TIME = 60000;
  //public static IDLE_TIME_ON_CONFIRM_MSG = 60000;

  public static BASE_BROWSER_URL = environment.baseBrowserUrl;
  public static mapKey = environment.mapKey;
  public static DEFAULT_COUNTRY_CODE = environment.countryCode;
  public static LOGIN_SECRET = environment.loginSecret;
  public static SUPPORTED_COUNTRY: ['IN'];
  public static SUPPORTED_MAP_CENTER_LAT: '40.7127753';
  public static SUPPORTED_MAP_CENTER_LONG: '-74.0059728';
  public static IDLE_TIME = 288; // one day min
  public static IDLE_TIME_DAYS = 15;
  public static IDLE_TIME_ON_CONFIRM_MSG = 240000;
  public static INTERVAL = 'interval';
  public static SEARCH_DELAY_INTERVAL = 1000;
  public static HEADER_CONTENT_TYPE = 'application/json';
  public static HEADER_AUTHORIZATION = 'Authorization';
  public static HEADER_TIMEZONE = 'Timezone';
  public static HEADER_TIMEZONE_VALUE = '';
  public static HEADER_ACCEPT_LANGUAGE = 'en-US';
  public static HEADER_AUTHORIZATION_VALUE = '';
  public static USER: any = null;
  public static TOKEN_KEY = 'Token';
  public static USER_DETAILS = 'userDetails';
  public static ORDER_DETAILS = 'orderDetails';
  public static FOOD_EXTRA_MAPPING = 'foodExtraMapping';
  public static FOOD_EXTRA_SELECTED = 'foodExtraSelected';
  public static MENU_ITEM_SELECTED = 'MenuItemSelected';
  public static MENU_ITEMS_FOR_TEMPLATE = 'MenuItemsForTemplate';
  public static MENU_ITEMS_SELECTED_FOR_TEMPLATE = 'MenuItemSelectedForTemplate';
  public static FOOD_EXTRA_FOR_MENU_ITEM_SELECTED = 'foodExtraForMenuItemSelected';
  public static EVENT_FORM_RAW_DATA = 'eventFormRawData';
  public static TEMPLATE_FORM_RAW_DATA = 'templateFormRawData';
  public static MENU_LIST_FOR_STORAGE = 'menuListForStorage';
  public static PREV_MENU_TREE_STRUCTURE = 'previousMenuTreeStructure';
  public static EVENT_FORM_LATEST_POLYGON = 'latestPoligonCoOrdinates';
  public static EVENT_FORM = 'eventForm';
  public static DONATION_ORDER_DETAILS = 'donationOrderDetails';
  public static USER_DETAILS_NAME = 'user_details_name';
  public static USER_DETAILS_EMAIL = 'user_details_email';
  public static USER_DETAILS_USER_PROFILE = 'userProfile';
  public static USER_DETAILS_LOGIN_DATA = 'loginData';
  public static USER_DETAILS_PROFILE_TIMEZONE = 'userProfileTimeZone';
  public static USER_DETAILS_LOGIN_USER_ID = 'loginUserId';
  public static USER_ROLE_FRANCHISE = 'Franchise';
  public static USER_ROLE_ADMIN = 'Admin';
  public static USER_ROLE_MANAGER = 'Manager';
  public static START_DAY_OF_WEEK_FOR_MONTH = 'startDayOfWeekForMonth';
  public static MERGE_CLIENT_DATA = 'mergeClientData';
  public static GRID_OFFSET = 'gridOffset';

  public static FILE_UPLOAD_ID = '';
  public static FILE_UPLOAD_NAME = '';
  public static PROFILE_UPLOAD_ID = '';
  public static PROFILE_UPLOAD_NAME = '';
  public static FAQ: any = null;
  public static ACCESS_LIST = 'ACCESS_LIST';
  public static ACCESS_MENU = 'ACCESS_MENU';
  public static TIME_SETTINGS = 'TIME_SETTINGS';
  public static DOWNLOAD_ID = 'DOWNLOAD_ID';
  public static MIN_DISTANCE = 0.1;
  public static MAX_DISTANCE = 1;
  public static MIN_PERCENT = 0;
  public static MAX_PERCENT = 100;
  public static MILES_METER_CONVERSION = 1609.4;
  // public static UPLOAD_FILE_URL = AppSettings.BASE_URL + '/secure/file';
  public static UPLOAD_FILE_URL = AppSettings.BASE_URL + '/secure/files?type=profile';
  public static GET_FILE_URL = AppSettings.BASE_URL + '/file';
  public static GET_FILE_THUMB_URL = AppSettings.BASE_URL + '/file/thumbnail_';
  public static EXPORT_URL = AppSettings.BASE_URL + '/';
  public static PRINT_URL = AppSettings.BASE_URL + '/';
  public static DEFAULT_PROFILE_IMAGE_URL = '/assets/images/default_profile.png';
  public static DEFAULT_USER_IMAGE = 'assets/images/default_user.png';
  public static DEFAULT_NO_IMAGE = 'assets/images/dummy_image150.png';

  public static DEFAULT_COUNTRY_STRING = 'USA';
  public static DEFAULT_COUNTRY_STRING_PAYMENT = 'US';
  public static DEFAULT_TIME_ZONE_FROM_CONFIG = 'America/Chicago';
  public static DEFAULT_SCHEDULE_WITHOUT_ASSET_COLOR = '#C9C8C8';
  public static DEFAULT_EQUIPMENT_WITHOUT_COLOR = '#33FF00';

  public static Store = '+1';
  public static CLEAR_NOTIFICATION_INTERVAL: any;
  public static NOTIFICATION_INTERVAL: 600;
  public static IGNORE_LINK = [];

  public static EVENT_DETAILS = 'eventDetails';
  public static EVENTS_LIST: any;
  public static SELECTED_PRODUCT = 'selectedProduct';
  public static SELECTED_SLOTS: any;
  public static SELECTED_MENU_FOR_FE = 'selectedProductForFoodExtra';
  public static SELECTED_MAIN_MENU_LIST = 'selectedMainMenuList';
  public static ORDER_ID = 'orderId';
  public static CUSTOMER_DATA = 'customerDetails';
  public static WEBSITE_PATTERN = new RegExp([
    '^(http://www\\.|https://www\\.|http://|https://)?',
    '[a-z0-9]+([\\-.]{1}[a-z0-9]+)*\\.[a-z]{2,5}(:[0-9]{1,5})?',
    '(/.*)?$'
  ].join(''));
  public static NAME_PATTERN = new RegExp(['^[A-Za-z\\d\\-_\\s]*$'].join(''));
  public static ROLE_PATTERN = new RegExp(['^[A-Za-z\\d\\-_\\s/\\\\\]*$'].join(''));
  public static PHONE_PATTERN: any = '^[0-9][0-9]*$';
  public static NUMBER_PATTERN = /^\+?[0-9\-]+$/;
  public static NUMBER_WITHOUT_SPECIAL_PATTERN = /^\+?[0-9]+$/;
  public static AMOUNT_PATTERN = new RegExp(['^[0-9\\d\.]*$'].join(''));
  public static NUMBER_FOUR_DECIMAL = new RegExp(/^\d*\.?\d{0,4}$/);
  public static PERCENT_PATTERN = /^100(.00)?|([1-9]?[0-9]{1,2})(\.[0-9]{2})?$/;
  public static PHONE_CODE = /([0-9]{4,})|[1-9]/;
  public static MOBILE_PATTERN: any = '^([0-9]+-)*[0-9]+$';
  // public static EMAIL_PATTERN = new RegExp(['[a-z|A-Z|0-9]+[@]+[a-z|A-Z|0-9]+[.]+[a-z|A-Z|0-9]+'].join(''));
  // public static EMAIL_PATTERN = new RegExp(['/^\S+@\S+\.\S+$/'].join(''));
  public static MULTI_EMAIL_PATTERN = '^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})(, [a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,})*';
  public static PASSWORD_VALIDATIONS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,20}$/;
  public static EMAIL_PATTERN = new RegExp(['^\\s*(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@',
    '((\\[[\\d]{1,3}\\.[\\d]{1,3}\\.[\\d]{1,3}\\.[\\d]{1,3}])|(([a-zA-Z\\-\\d]+\\.)+[a-zA-Z]{2,15}))\\s*$'].join(''));
  public static NO_WHITESPACE = new RegExp(['.*[^ ].*'].join(''));
  public static ZIPCODE_PATTERN = new RegExp(['^[0-9]'].join(''));
  public static MULTI_ZIPCODE_PATTERN = '^[0-9]{5}(,[0-9]{5})*$';
  public static DIGIT_PATTERN = new RegExp(['^[\\d]*$'].join(''));
  public static RADIUS_DIGIT_PATTERN = /^[0-9|.|0-9]+$/;
  public static NUMBER_NOT_ZERO = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static FEE_PATTERN = new RegExp(['^\\d+(\.\\d{1,2})?$'].join(''));
  public static ALPHA_NUMERIC = new RegExp(['^[A-Za-z0-9]'].join(''));
  public static VALUE_ONE_TO_HUNDRED = new RegExp(['^[1-9][0-9]?$|^100$'].join(''));
  // public static VALUE_0_TO_999 = new RegExp(['^[0-9][0-9]{0,2}$|^\d$'].join(''));
  public static VALUE_0_TO_999 = new RegExp(['^(16[0-8]|1[01][0-9]|[1-9]?[0-9])$'].join(''));
  public static NON_ZERO_VALUES = new RegExp(['^[1-9][0-9]*$'].join(''));
  public static HOTEL_PROFILE_UPLOAD_ID = '';
  public static HOTEL_PROFILE_UPLOAD_NAME = '';
  public static PERSON_NAME_PATTERN = '^[a-zA-Z][a-zA-Z\\s\-\_]+$';
  public static COMPANY_AND_PERSON_NAME_PATTERN = '^[a-zA-Z0-9][a-zA-Z0-9\\s\-\_]+$';
  public static FAX_PATTERN = /^\+?[0-9\-]+$/;
  public static TIME_ZONE_FIRST_STRING = /[(]/gi;
  public static TIME_ZONE_SECOND_STRING = /[)]/gi;
  
   public static DOMAIN_VALUES = {
    KONA: environment.brandIds.kona.domain,
    KONA_BRAND_ID: environment.brandIds.kona.brandId,
    TOMS: environment.brandIds.toms.domain,
    TOMS_BRAND_ID: environment.brandIds.toms.brandId,
    ANNS: environment.brandIds.anns.domain,
    ANNS_BRAND_ID: environment.brandIds.anns.brandId,
  };
  
  public static DAYS_COLLECTION = [
    { label: 'Sun', value: '1' },
    { label: 'Mon', value: '2' },
    { label: 'Tue', value: '3' },
    { label: 'Wed', value: '4' },
    { label: 'Thu', value: '5' },
    { label: 'Fri', value: '6' },
    { label: 'Sat', value: '7' }
  ];

  public static LOGGED_IN_ROLE = '';
  public static roles = [
    {
      roleCode: 'System Admin',
      redirect: '/admin-dashboard'
    }
  ];

  public static STAFF_ROLES = {
    'Driver': 'Driver',
    'Manager': 'Manager',
    'Server': 'Server',
    'Worker': 'Worker',
  };

  public static TEMPLATE_TYPES = [
    { label: 'Select', value: '' },
    { label: 'General Inquiry', value: 'General Inquiry' },
    { label: 'School', value: 'School' },
    { label: 'Corporate', value: 'Corporate' },
    { label: 'Youth Sports', value: 'Youth Sports' },
    { label: 'Daycare', value: 'Daycare' },
    { label: 'Party', value: 'Party' }
  ];

  public static TEMPLATE_TYPES_DEFAULT = [
    { label: 'Default', value: 'Default' }
  ];

  public static DATE_FORMATTER = 'MMM DD, YYYY hh:mm A';

  public static COOKIE_EXPIRY = (1 * 24 * 60 * 60 * 1000);

  public static FILE_UPLOAD_MAX_SIZE_IN_BYTE = 10000000;

  public static FILE_UPLOAD_MAX_SIZE_FOR_ASSETS_IN_BYTE = 3000000;
  public static FILE_UPLOAD_MAX_SIZE_FOR_MAIL_ATTACHMENTS_IN_BYTE = 5242880; // 5MB 
  public static FILE_UPLOAD_MAX_SIZE_FOR_SIGNATURE_IN_BYTE = 2000000;

  //10mb for no_reply email template
  public static FILE_UPLOAD_MAX_SIZE_FOR_NOREPLY_MAIL_ATTACHMENTS_IN_BYTE = 5500000; 

  public static SESSION_KEY_NAME = 'X-SESSION-KEY';

  public static COUNTRY_CODE = [
    { label: '+1', value: '+1' },
    { label: '+7', value: '+7' },
    { label: '+20', value: '+20' },
    { label: '+27', value: '+27' },
    { label: '+30', value: '+30' },
    { label: '+31', value: '+31' },
    { label: '+32', value: '+32' },
    { label: '+33', value: '+33' },
    { label: '+34', value: '+34' },
    { label: '+36', value: '+36' },
    { label: '+39', value: '+39' },
    { label: '+40', value: '+40' },
    { label: '+41', value: '+41' },
    { label: '+43', value: '+43' },
    { label: '+44', value: '+44' },
    { label: '+45', value: '+45' },
    { label: '+46', value: '+46' },
    { label: '+47', value: '+47' },
    { label: '+48', value: '+48' },
    { label: '+49', value: '+49' },
    { label: '+51', value: '+51' },
    { label: '+52', value: '+52' },
    { label: '+53', value: '+53' },
    { label: '+54', value: '+54' },
    { label: '+55', value: '+55' },
    { label: '+56', value: '+56' },
    { label: '+57', value: '+57' },
    { label: '+58', value: '+58' },
    { label: '+60', value: '+60' },
    { label: '+61', value: '+61' },
    { label: '+62', value: '+62' },
    { label: '+63', value: '+63' },
    { label: '+64', value: '+64' },
    { label: '+65', value: '+65' },
    { label: '+66', value: '+66' },
    { label: '+81', value: '+81' },
    { label: '+82', value: '+82' },
    { label: '+84', value: '+84' },
    { label: '+86', value: '+86' },
    { label: '+90', value: '+90' },
    { label: '+91', value: '+91' },
    { label: '+92', value: '+92' },
    { label: '+93', value: '+93' },
    { label: '+94', value: '+94' },
    { label: '+95', value: '+95' },
    { label: '+98', value: '++98' },
    { label: '+212', value: '+212' },
    { label: '+213', value: '+213' },
    { label: '+216', value: '+216' },
    { label: '+218', value: '+218' },
    { label: '+220', value: '+220' },
    { label: '+221', value: '+221' },
    { label: '+222', value: '+222' },
    { label: '+223', value: '+223' },
    { label: '+224', value: '+224' },
    { label: '+225', value: '+225' },
    { label: '+226', value: '+226' },
    { label: '+227', value: '+227' },
    { label: '+228', value: '+228' },
    { label: '+229', value: '+229' },
    { label: '+230', value: '+230' },
    { label: '+231', value: '+231' },
    { label: '+232', value: '+232' },
    { label: '+233', value: '+233' },
    { label: '+234', value: '+234' },
    { label: '+235', value: '+235' },
    { label: '+236', value: '+236' },
    { label: '+237', value: '+237' },
    { label: '+238', value: '+238' },
    { label: '+239', value: '+239' },
    { label: '+240', value: '+240' },
    { label: '+241', value: '+241' },
    { label: '+242', value: '+242' },
    { label: '+243', value: '+243' },
    { label: '+244', value: '+244' },
    { label: '+245', value: '+245' },
    { label: '+248', value: '+248' },
    { label: '+249', value: '+249' },
    { label: '+250', value: '+250' },
    { label: '+251', value: '+251' },
    { label: '+252', value: '+252' },
    { label: '+253', value: '+253' },
    { label: '+254', value: '+254' },
    { label: '+255', value: '+255' },
    { label: '+256', value: '+256' },
    { label: '+257', value: '+257' },
    { label: '+258', value: '+258' },
    { label: '+260', value: '+260' },
    { label: '+261', value: '+261' },
    { label: '+262', value: '+262' },
    { label: '+263', value: '+263' },
    { label: '+264', value: '+264' },
    { label: '+265', value: '+265' },
    { label: '+266', value: '+266' },
    { label: '+267', value: '+267' },
    { label: '+268', value: '+268' },
    { label: '+269', value: '+269' },
    { label: '+290', value: '+290' },
    { label: '+291', value: '+291' },
    { label: '+297', value: '+297' },
    { label: '+298', value: '+298' },
    { label: '+299', value: '+299' },
    { label: '+350', value: '+350' },
    { label: '+351', value: '+351' },
    { label: '+352', value: '+352' },
    { label: '+353', value: '+353' },
    { label: '+355', value: '+355' },
    { label: '+356', value: '+356' },
    { label: '+357', value: '+357' },
    { label: '+358', value: '+358' },
    { label: '+359', value: '+359' },
    { label: '+370', value: '+370' },
    { label: '+371', value: '+371' },
    { label: '+372', value: '+372' },
    { label: '+373', value: '+373' },
    { label: '+374', value: '+374' },
    { label: '+375', value: '+375' },
    { label: '+376', value: '+376' },
    { label: '+377', value: '+377' },
    { label: '+378', value: '+378' },
    { label: '+380', value: '+380' },
    { label: '+381', value: '+381' },
    { label: '+382', value: '+382' },
    { label: '+385', value: '+385' },
    { label: '+386', value: '+386' },
    { label: '+387', value: '+387' },
    { label: '+389', value: '+389' },
    { label: '+420', value: '+420' },
    { label: '+421', value: '+421' },
    { label: '+423', value: '+423' },
    { label: '+500', value: '+500' },
    { label: '+501', value: '+501' },
    { label: '+502', value: '+502' },
    { label: '+503', value: '+503' },
    { label: '+504', value: '+504' },
    { label: '+505', value: '+505' },
    { label: '+506', value: '+506' },
    { label: '+507', value: '+507' },
    { label: '+508', value: '+508' },
    { label: '+509', value: '+509' },
    { label: '+590', value: '+590' },
    { label: '+591', value: '+591' },
    { label: '+592', value: '+592' },
    { label: '+593', value: '+593' },
    { label: '+595', value: '+595' },
    { label: '+597', value: '+597' },
    { label: '+598', value: '+598' },
    { label: '+670', value: '+670' },
    { label: '+672', value: '+672' },
    { label: '+673', value: '+673' },
    { label: '+674', value: '+674' },
    { label: '+675', value: '+675' },
    { label: '+676', value: '+676' },
    { label: '+677', value: '+677' },
    { label: '+678', value: '+678' },
    { label: '+679', value: '+679' },
    { label: '+680', value: '+680' },
    { label: '+681', value: '+681' },
    { label: '+682', value: '+682' },
    { label: '+683', value: '+683' },
    { label: '+685', value: '+685' },
    { label: '+686', value: '+686' },
    { label: '+687', value: '+687' },
    { label: '+688', value: '+688' },
    { label: '+689', value: '+689' },
    { label: '+690', value: '+690' },
    { label: '+691', value: '+691' },
    { label: '+692', value: '+692' },
    { label: '+850', value: '+850' },
    { label: '+852', value: '+852' },
    { label: '+853', value: '+853' },
    { label: '+855', value: '+855' },
    { label: '+856', value: '+856' },
    { label: '+870', value: '+870' },
    { label: '+880', value: '+880' },
    { label: '+886', value: '+886' },
    { label: '+960', value: '+960' },
    { label: '+961', value: '+961' },
    { label: '+962', value: '+962' },
    { label: '+963', value: '+963' },
    { label: '+964', value: '+964' },
    { label: '+965', value: '+965' },
    { label: '+966', value: '+966' },
    { label: '+967', value: '+967' },
    { label: '+968', value: '+968' },
    { label: '+971', value: '+971' },
    { label: '+972', value: '+972' },
    { label: '+973', value: '+973' },
    { label: '+974', value: '+974' },
    { label: '+975', value: '+975' },
    { label: '+976', value: '+976' },
    { label: '+977', value: '+977' },
    { label: '+992', value: '+992' },
    { label: '+993', value: '+993' },
    { label: '+994', value: '+994' },
    { label: '+995', value: '+995' },
    { label: '+996', value: '+996' },
    { label: '+998', value: '+998' }
  ];

  public static EVENT_LINK_CONSTANT = 'https://konaschoolsidedev.mi2.in/#/events/';
  public static MESSAGE_TYPE = { SUCCESS: 'success', ERROR: 'error', WARNING: 'warning', INFO: 'info' };
  public static DUMMY_VEHICLES_FILTER = [
    { label: 'All', value: '' },
    { label: 'Actual', value: 'ACTUAL' },
    { label: 'Dummy', value: 'DUMMY' },
  ];

  public static ZIP_CODE_MAX_LENGTH = 5;
  public static ZIP_CODE_MIN_LENGTH = 5;
  public static ADDRESS_MAX_LENGTH = 100;
  public static twentyThreeFiftyNineLapse = 86399000;
  public static ORDER_STATUS_LIST = [
    { label: 'New', value: 'NEW' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ];
  public static ORDER_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'New', value: 'NEW' },
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
    { label: 'Cancelled', value: 'CANCELLED' },
  ];

   public static DAYS_LIST_FILTER_OPTIONS = [
    { label: '30 Days', value: '30' },
    { label: '60 Days', value: '60' },
    { label: '90 Days', value: '90' },
    { label: 'Custom', value: 'custom' },
  ];
  public static MESSAGE_RECEIVER_OPTIONS = [
    { label: 'Franchise Only', value: ['3f3c80363afa4bcdabe1e366c0289ce3',]},
    { label: 'Everyone', value: ['3f3c80363afa4bcdabe1e366c0289ce3','fe9018580813488eb8fa9759dc72fc84']},
  ];
  
  public static PAYMENT_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Pending', value: 'PENDING' },
    { label: 'Success', value: 'SUCCESS' },
    { label: 'Partial Refund Success', value: 'PARTIAL REFUND SUCCESS'},
    { label: 'Refund Success', value: 'REFUND SUCCESS' },
    { label: 'Refund Failed', value: 'REFUND FAILED' },
    { label: 'Invoice', value: 'INVOICE' },
    { label: 'On Site', value: 'ONSITE' },
    { label: 'Invoice Refund', value: 'INVOICE REFUND' },
    { label: 'On Site Refund', value: 'ONSITE REFUND' },
    { label: 'Failed', value: 'FAILED' },
  ];

  public static ORDER_PLATFORM_LIST = [
    { label: 'All', value: 'all' },
    { label: 'POS', value: 'pos' },
    { label: 'Prepay', value: 'prepay' },
  ];


  public static ORDER_STATUS_LIST_BULK_CHANGE_OPTIONS = [
    { label: 'In Progress', value: 'IN_PROGRESS' },
    { label: 'Completed', value: 'COMPLETED' },
  ];

  public static DELIVERY_OPTIONS = [
    { label: 'Delivery', value: 'DELIVERY' },
    { label: 'Pickup', value: 'PICKUP' },
  ];

  public static DELIVERY_VALUES = {
    DELIVERY: 'DELIVERY',
    PICKUP: 'PICKUP'
  };

  public static EMAIL_TEMPLATE_TYPES = {
    BOOKING: 'BOOKING',
    CONFIRMATION: 'CONFIRMATION',
    THANKYOU: 'THANKYOU',
    CUSTOM:'CUSTOM'
  };

  public static COUNTRY_LIST = [
    { label: 'United States of America', value: 'USA' },
  ];

  public static EVENTS_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All Events and Tasks', value: 'allEventsAndTask', disabled: false},
    { label: 'All Events', value: ''  ,disabled: false},
    { label: 'All Tasks', value: 'allTasks' ,disabled: false },
    // { label: 'Active', value: 'Active' ,disabled: false },
    { label: 'Deleted Event', value: 'Deleted' ,disabled: false },
    // { label: 'Deleted Task', value: 'deletedTask' ,disabled: false },
    { label: 'PrePay Event', value: 'PrePayEvent' ,disabled: false },
    { label: 'Kurbside Event', value: 'KurbsideEvent' ,disabled: false },
  ];

  public static EVENTS_STATUS_LIST_FILTER_VALUES = {
    All_EVENTS_AND_TASK: 'allEventsAndTask',
    All_EVENTS: '',
    All_TASK: 'allTasks',
    DELETED_TASK: 'deletedTask',
  };

  public static STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'POS Customer', value: 'true' },
    { label: 'Prepay Customer', value: 'false' },
  ];

  public static GENERAL_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Active', value: 'true' },
    { label: 'Inactive', value: 'false' },
  ];

  public static MIGRATION_TYPE_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Copy', value: 'Copy' },
    { label: 'Move', value: 'Move' },
    { label: 'Aggregate', value: 'Aggregate' },
  ];

  public static YES_NO_OPTIONS = [
    { label: 'No', value: false },
    { label: 'Yes', value: true },
  ];

  public static READ_UNREAD_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Unread', value: false },
    { label: 'Read', value: true },
  ];

  public static FBR_EVENT_TYPE_OPTIONS = [
    { label: 'General Inquiry', value: 'General Inquiry' },
    { label: 'School', value: 'School' },
    { label: 'Corporate', value: 'Corporate' },
    { label: 'Youth Sports', value: 'Youth Sports' },
    { label: 'Daycare', value: 'Daycare' },
    { label: 'Party', value: 'Party' },
  ];

  public static FBR_MANUAL_STATUS_OPTIONS = [
    { label: 'Pending', value: 'pending' },
    { label: 'Contacted', value: 'contacted' },
    { label: 'Awaiting Details', value: 'awaiting_details' },
    { label: 'Complete', value: 'complete' },
    { label: 'Deleted', value: 'deleted' },
  ];

  public static CAMPAIGN_COMMENTS_OPTIONS = [
    { label: 'No', value: 'NO' },
    { label: 'Most Recent', value: 'Most Recent' },
    { label: 'From Top Donors', value: 'From Top Donors' },
  ];

  public static ADD_FOOD_EXTRA_ITEM_SLECTION_OPTIONS = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' },
  ];

  public static ccPercentCharges = 2;

  public static EVENT_TYPE_OPTIONS = [
    { label: 'Private', value: 'private' },
    { label: 'Public', value: 'public' },
  ];

  public static ADMIN_TIME_SLOTS_OPTIONS = [
    { label: 'Select', value: '' },
    { label: '10', value: 10 },
    { label: '15', value: 15 },
    { label: '30', value: 30 },
    { label: '60', value: 60 },
  ];

  public static PAYMENT_TERMS_OPTIONS = [
    { label: 'Menu', value: 'menu' },
    { label: 'Invoice', value: 'invoice' },
    { label: 'On Site', value: 'onsite' },
  ];

  public static MONTH_START_DAY_OPTIONS = [
    { label: 'Sunday-Saturday', value: 0 },
    { label: 'Monday-Sunday', value: 1 },
  ];

  public static DASHBOARD_RECORDS_BY = [
    { label: 'Today', value: 'daily' },
    { label: 'This Week', value: 'weekly' },
    { label: 'This Month', value: 'monthly' },
  ];

  public static CLIENT_INDUSTRY = [
    { label: 'IT', value: 'IT' },
    { label: 'Mecahnical', value: 'Mecahnical' },
  ];

  public static TASK_FREQUENCY = [
    { label: 'Do Not Repeat', value: 'DNR' },
    // { label: 'Daily', value: 'daily' },
    { label: 'Weekdays (Mon-Fri)', value: 'weekdays' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Custom', value: 'custom' },
  ];

  //newly added for #165
  public static EVENT_FREQUENCY = [
    { label: 'Do Not Repeat', value: 'DNR' },
    { label: 'Daily', value: 'daily' },
    { label: 'Weekly', value: 'weekly' },
    { label: 'Monthly', value: 'monthly' },
    { label: 'Weekdays (Mon-Fri)', value: 'weekdays' },
    { label: 'Bi-Weekly', value: 'biweekly' },
    { label: 'Custom', value: 'custom' },
  ];

  public static CUSTOM_RECURRING = [
    { label: 'Day', value: 'day' },
    { label: 'Week', value: 'weekly' },
    { label: 'Month', value: 'monthly' },
  ];

  public static EVENT_DISPLAY_TYPE = [
    { label: 'List', value: 'list', disabled: false },
    { label: 'Day', value: 'day', disabled: false },
    { label: 'Week', value: 'week', disabled: false },
    { label: 'Month', value: 'month', disabled: false },
  ];

  public static EVENT_DISPLAY_TYPE_VALUES = {
    LIST: 'list',
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month'
  };

  public static WEEKDAYS = [
    { label: 'S', value: 'SUN' },
    { label: 'M', value: 'MON' },
    { label: 'T', value: 'TUE' },
    { label: 'W', value: 'WED' },
    { label: 'T', value: 'THU' },
    { label: 'F', value: 'FRI' },
    { label: 'S', value: 'SAT' },
  ];


  public static STAFF_SCHEDULE_TIME_SLOT = [
    { label: '00:00', value: '00:00' },
    { label: '00:15', value: '00:15' },
    { label: '00:30', value: '00:30' },
    { label: '00:45', value: '00:45' },
    { label: '01:00', value: '01:00' },
    { label: '01:15', value: '01:15' },
    { label: '01:30', value: '01:30' },
    { label: '01:45', value: '01:45' },
    { label: '02:00', value: '02:00' },
    { label: '02:15', value: '02:15' },
    { label: '02:30', value: '02:30' },
    { label: '02:45', value: '02:45' },
    { label: '03:00', value: '03:00' },

  ];

  public static contactTypes = [
    { label: 'Primary', value: 'primary' },
    { label: 'Secondary', value: 'secondary' },
    { label: 'Other', value: 'other' },
  ];

  public static EVENT_STATUS_LIST = [
    { label: 'Pending', value: 'pending'},
    { label: 'Booked', value: 'booked'},
    { label: 'Confirmed', value: 'confirmed'},
    { label: 'Cancelled', value: 'cancelled'},
    { label: 'Transferred', value: 'transferred'},
    { label: 'Flagged', value: 'flagged'},
    { label: 'Skipped', value: 'skipped'},
    { label: 'Waitlist', value: 'waitlist'},
  ];

  public static EMAIL_STATUS_LIST = [
    { label: 'Booking email not sent', value: 'bookedEmailSent' },
    { label: 'Confirmation email not sent', value: 'confirmedEmailSent' },
    { label: 'Thank you email not sent', value: 'thankYouEmail' },
    { label: 'Invoice not sent', value: 'clientInvoice' },
    { label: 'Invoice not paid', value: 'invoiceNotPaid' },
    { label: 'Giveback not sent', value: 'givebackPaid' },
    { label: 'Giveback not settled', value: 'givebackNotSettled' },


  ];

  public static SALES_TYPE_OPTIONS = {
    // { label: 'Collected amounts', value: 'collected' },
    // { label: 'Package fee', value: 'package_fee' },
    // { label: 'cup count', value: 'cup_count' },
    'collected': 'aafc6f2ff133458e84e4d88495ac6446',
    'package_fee': '046a441cbc6e48029620228a990893bb',
    'cup_count': '1368a9a8ece845dc85cd032ddf745000',
  };

  public static STAFF_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'Hours Worked Summary', value: 'HOURS WORKED SUMMARY' },
    { label: 'Employee Time Card', value: 'EMPLOYEE TIME CARD' },
  ];

  public static TIMESTATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: 'All' },
    { label: 'Reviewed', value: 'Reviewed' },
    { label: 'Unreviewed', value: 'Unreviewed' },
  ];

  public static TIMEFRAME_LIST_FILTER_OPTIONS = [
    { label: 'Today', value: 'TODAY' },
    { label: 'Yesterday', value: 'LAST DAY' },
    { label: 'Last Week', value: 'WEEK' },
    { label: 'Last Month', value: 'MONTH' },
    { label: 'Last Quarter', value: 'QUARTER' },
    { label: 'Last Year', value: 'YEAR' },
    { label: 'This Month', value: 'THIS MONTH' },
    { label: 'This Year', value: 'THIS YEAR' },
    { label: 'Date Range', value: '' },
  ];

  public static TIMEFRAME_LIST_FILTER_OPTIONS_VALUES = {
    TODAY: 'TODAY',
    LAST_DAY: 'LAST DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    QUARTER: 'QUARTER',
    YEAR: 'YEAR',
    THIS_MONTH: 'THIS MONTH',
    THIS_YEAR: 'THIS YEAR',
    DTE_RANGE: '',
  };

  public static DASHBOARD_TIMEFRAME_LIST_FILTER_OPTIONS = [
    { label: 'Today', value: 'TODAY' },
    { label: 'Last Week', value: 'WEEK' },
    { label: 'Last Month', value: 'MONTH' },
    { label: 'Last Quarter', value: 'QUARTER' },
    { label: 'Last Year', value: 'YEAR' },
    { label: 'Month to Date', value: 'THIS MONTH' },
    { label: 'Quarter to Date', value: 'THIS QUARTER' },
    { label: 'Year to Date', value: 'THIS YEAR' },
    { label: 'Date Range', value: '' },
  ];

  public static DASHBOARD_COMPAIR_TIMEFRAME_LIST_FILTER_OPTIONS = [
    { label: 'Previous Period', value: 'YESTERDAY' },
    { label: 'Previous Month', value: 'PREVIOUS MONTH' },
    { label: 'Previous Quarter', value: 'PREVIOUS QUARTER' },
    { label: 'Previous Year', value: 'PREVIOUS YEAR' },
    { label: 'No Comparison', value: '' },
  ];

  public static STAFF_LIST_FILTER_OPTIONS = [
    { label: 'Ganesh Patil', value: 'Ganesh Patil' },
    { label: 'Ketan Tambekar', value: 'Ketan Tambekar' },
  ];

  public static DEFAULT_STAFF_TYPE = 'HOURS WORKED SUMMARY';

  public static SALES_OPTION_LIST_FILTER_OPTIONS = [
    { label: 'Event Sales', value: 'GIVEBACK' },
    { label: 'Event Sales w/ Equipment', value: 'SALES HISTORY' },
  ];

  public static DEFAULT_SALE_REPORT_TYPE = 'GIVEBACK';

  public static DASHBOARD_LIST_TIMEFRAME_FILTER = [
    { label: 'Today', value: 'today' },
    { label: 'This Week', value: 'weekly' },
    { label: 'This Month', value: 'monthly' },
    { label: 'This Year', value: 'yearly' },
    { label: 'Date Range', value: '' },
  ];
  public static DASHBOARD_LIST_TIMEFRAME_FILTER_TASK = [
    { label: 'Today', value: 'today' },
    { label: 'This Week', value: 'weekly' },
    { label: 'This Month', value: 'monthly' },
    { label: 'This Year', value: 'yearly' },
    { label: 'Date Range', value: '' },
  ];
  public static CUSTOM_RECURRING_WITHOUT_DAY = [
    { label: 'Week', value: 'weekly' },
    { label: 'Month', value: 'monthly' },
  ];

  public static DASHBOARD_GRANULARITY_DAY = [
    { label: 'Daily', value: 'day' }
  ];

  public static DASHBOARD_GRANULARITY_WEEK = [
    { label: 'Daily', value: 'day' },
    { label: 'Weekly', value: 'week' },
  ];

  public static DASHBOARD_GRANULARITY_MONTH = [
    { label: 'Daily', value: 'day' },
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
  ];

  public static DASHBOARD_GRANULARITY_YEAR = [
    { label: 'Weekly', value: 'week' },
    { label: 'Monthly', value: 'month' },
  ];

  public static INVOICE_STATUS_LIST_FILTER_OPTIONS = [
    { label: 'All', value: '' },
    { label: 'Draft', value: 'draft'},
    { label: 'Sent', value: 'sent'},
    { label: 'Paid', value: 'paid'},
    { label: 'Overdue', value: 'overdue'},
  ];

  public static INVOICE_REMINDER_OPTIONS = [
    { label: 'Select', value: '' },
    { label: 'Reminder1', value: 'reminder1'},
    { label: 'Reminder2', value: 'reminder2'},
    { label: 'Reminder3', value: 'reminder3'},
  ];

  public static INVOICE_STATUS_FILTER_VALUES = {
     EMPTY:'',
     DRAFT:'draft',
     SENT:'sent',
     PAID:'paid',
     OVERDUE:'overdue',
  };

  public static GRAPH_SECONDARY_COMPARED_DEFAULT_COLOR = 'rgb(255, 99, 132, 0.7)';
  public static GRAPH_SECONDARY_COMPARED_DEFAULT_BORDER_COLOR = 'rgb(255, 99, 132, 1)';

  public static NOTIFICATION_REMINDER :any = [
    { label: '00', value: '00' },
    { label: '01', value: '01' },
    { label: '02', value: '02' },
    { label: '03', value: '03' },
    { label: '04', value: '04' },
    { label: '05', value: '05' },
    { label: '06', value: '06' },
    { label: '07', value: '07' },
    { label: '08', value: '08' },
    { label: '09', value: '09' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },
    { label: '31', value: '31' },
    { label: '32', value: '32' },
    { label: '33', value: '33' },
    { label: '34', value: '34' },
    { label: '35', value: '35' },
    { label: '36', value: '36' },
    { label: '37', value: '37' },
    { label: '38', value: '38' },
    { label: '39', value: '39' },
    { label: '40', value: '40' },
    { label: '41', value: '41' },
    { label: '42', value: '42' },
    { label: '43', value: '43' },
    { label: '44', value: '44' },
    { label: '45', value: '45' },
    { label: '46', value: '46' },
    { label: '47', value: '47' },
    { label: '48', value: '48' },
    { label: '49', value: '49' },
    { label: '50', value: '50' },
    { label: '51', value: '51' },
    { label: '52', value: '52' },
    { label: '53', value: '53' },
    { label: '54', value: '54' },
    { label: '55', value: '55' },
    { label: '56', value: '56' },
    { label: '57', value: '57' },
    { label: '58', value: '58' },
    { label: '59', value: '59' },
    { label: '60', value: '60' },
    { label: '61', value: '61' },
    { label: '62', value: '62' },
    { label: '63', value: '63' },
    { label: '64', value: '64' },
    { label: '65', value: '65' },
    { label: '66', value: '66' },
    { label: '67', value: '67' },
    { label: '68', value: '68' },
    { label: '69', value: '69' },
    { label: '70', value: '70' },
    { label: '71', value: '71' },
    { label: '72', value: '72' },
  ];

  public static Date_Dropdown :any = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5'},
    { label: '6', value: '6'},
    { label: '7', value: '7' },
    { label: '8', value: '8' },
    { label: '9', value: '9' },
    { label: '10', value: '10' },
    { label: '11', value: '11' },
    { label: '12', value: '12' },
    { label: '13', value: '13' },
    { label: '14', value: '14' },
    { label: '15', value: '15' },
    { label: '16', value: '16' },
    { label: '17', value: '17' },
    { label: '18', value: '18' },
    { label: '19', value: '19' },
    { label: '20', value: '20' },
    { label: '21', value: '21' },
    { label: '22', value: '22' },
    { label: '23', value: '23' },
    { label: '24', value: '24' },
    { label: '25', value: '25' },
    { label: '26', value: '26' },
    { label: '27', value: '27' },
    { label: '28', value: '28' },
    { label: '29', value: '29' },
    { label: '30', value: '30' },


  ];

  public static CAMPAIGN_STATUS = [
    {label: 'All', value: ''},
    {label: 'Sent', value: 'sent'},
    {label: 'Scheduled', value: 'schedule'},
    {label: 'Paused', value: 'paused'}
  ];
  public static CALENDAR_VIEWS = {
    daily: 'daily',
    weekly: 'weekly',
    monthly:'monthly',
    yearly: 'yearly'
  };

  public static TWENTY_MINUTES = '1200000';

  public static MIGRATION_OPTIONS = [
    { label: 'Clients and Events', value: 'Clients and Events' },
    { label: 'Staff', value: 'Staff' },
    { label: 'Equipment', value: 'Equipment' },
    { label: 'Menu', value: 'Menu' },
    { label: 'Legacy', value: 'Legacy'},
    { label: 'No-Reply Data', value: 'No-Reply Data' },
  ];

  public static MIGRATION_TYPES = [
    { label: 'Select', value: null },
    { label: 'Copy', value: 'Copy' },
    { label: 'Move', value: 'Move' },
  ];

  public static TIMEZONE_TIME = '300000';

  public static REMINDER_DROPDOWN :any = [
    { label: 'On due date', value: '0' },
    { label: '7', value: '7' },
    { label: '14', value: '14' },
    { label: '30', value: '30' },
    { label: '90', value: '90'}
  ];

  public static DUE_DAYS: any = [
    { label: 'Before', value: 'before' },
    { label: 'After', value: 'after' }
  ];

  public static EMAIL_REMINDERS: any = [
    { label: 'Select', value: '' },
    { label: 'On Due Date Reminder', value: 'On Due Reminder' },
    { label: 'After Due Date Reminder', value: 'After Reminder' },
    { label: 'Before Due Date Reminder', value: 'Before Reminder' }
  ];
}
