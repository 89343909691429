import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../../shared/app.settings';
import { MiGridComponent } from '../../../shared/components/mi-grid/mi-grid.component';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { StorageService } from '../../../shared/services/storage.service';
import { UiService } from '../../../shared/services/ui.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-customer-list-report',
  templateUrl: './customer-list-report.component.html',
  styleUrls: ['./customer-list-report.component.scss']
})
export class CustomerListReportComponent implements OnInit {
  params: any = '';
  // Grid params
  gridurl = '/secure/customers/details/grid-data';
  exportBaseUrl = '/secure/customers/admin/export-xls';
  exportCompleteUrl = '/secure/customers/admin/export-xls';
  updatedUrl = '';
  //params;
  columns: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  isExportVisible = false;
  loginData: any;
  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  // Form params
  defaultToday = new Date();
  franchiseName: string = "";
  franchiseList = [];
  franchiseId;
  search: string = "";
  timer = null;
  showGenerateReport: boolean;
  generalStatusOptions = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS;
  selectedStatus = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS[0].value;
  StatusOptions = AppSettings.STATUS_LIST_FILTER_OPTIONS;
  postData: any = {
    sortColumn: null,
    sortType: null,
    searchText: '',
    activated: this.selectedStatus === '' ? true : false,
    franchiseId: null,
    applyActivatedStatus: '',
    assetTypeIdList: [],
  };
  activated: boolean;

  constructor(
    private router: Router,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    private uiService: UiService,
    private franchiseService: FranchiseService,
    private adminService: AdminService,
  ) {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);

    if (this.loginData) {
      const data = JSON.parse(this.loginData);

      if (data.roles && data.roles.length > 0 && data.roles[0].roleCode === AppSettings.USER_ROLE_ADMIN) {
        this.gridurl = '/secure/customers/admin/grid-data';
      }
    }

  }

  ngOnInit(): void {
    this.createGrid();
    this.exportCompleteUrl = this.exportBaseUrl;

  }

  createGrid() {
    this.columns.push({
      field: 'firstName', width: '190px',
      header: this.commonBindingDataService.getLabel('First Name'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'lastName', width: '190px',
      header: this.commonBindingDataService.getLabel('Last Name'), sort: false, resize: true, class: 'eclipse'
    });

    this.columns.push({
      field: 'email', width: '170px',
      header: this.commonBindingDataService.getLabel('label_email'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'phoneNumber', width: '120px',
      header: this.commonBindingDataService.getLabel('label_phone'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const value = `${data.phoneNumber}`;
        const t = this.commonBindingDataService.telFormat(value);
        const finalValue = `<div title = "${value}">${t}</div>`;
        return finalValue;
      }
    });

    this.columns.push({
      field: 'city', width: '110px',
      header: this.commonBindingDataService.getLabel('label_city'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'state', width: '110px',
      header: this.commonBindingDataService.getLabel('label_state'), sort: false, resize: true, class: 'eclipse',
    });

    this.columns.push({
      field: 'zipCode', width: '110px',
      header: this.commonBindingDataService.getLabel('Zipcode'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'ordersCount', width: '110px',
      header: this.commonBindingDataService.getLabel('No. of orders'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'ordersTotal', width: '110px',
      header: this.commonBindingDataService.getLabel('Order Total'), sort: false, resize: true, class: 'eclipse',
    });
    this.columns.push({
      field: 'Registered/Not registered', width: '170px',
      header: this.commonBindingDataService.getLabel('User Type'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {

        const value = data.registered == true ? 'Registered User' : 'Guest User';
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      }
    });

    this.columns.push({
      field: 'Receive Promo', width: '110px',
      header: this.commonBindingDataService.getLabel('Receive Promo'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {

        const value = data.allowPromoNotifications == true ? 'yes' : 'No';
        const finalValue = `<div title = "${value}">${value}</div>`;
        return finalValue;
      }
    });


    this.buttons.push({
      field: 'profileImageFileId',
      label: '',
      class: 'customer-status-icon', show: (data) => {
        if (data.activated) {
          return '<div class="event-hold-icon deactivate" title = "Deactivate"></div>';
        } else {
          return '<div class="event-open-icon activate" title = "Activate"></div>';
        }
      }
    });

  }

  searchFranchise(event) {
    this.adminService.getFranchiseListBySearchText(event.query).subscribe((result) => {
      if (result.data.length > 0) {
        this.franchiseList = result.data.map((item) => ({
          franchiseName: item.franchiseName,
          franchiseId: item.id
        }));
      } else {
        this.franchiseList = [];
      }
    });
  }
  setSelectedFranchiseDetails(event) {
    this.franchiseName = event.franchiseName;
    this.franchiseId = event.franchiseId;
    this.applyFilter('apply');
  }


  onActionBtnClicked(e: any) {
    const actionType = e.class;
    const data = e.row;
    if (actionType !== undefined) {
      switch (actionType) {
        case 'customer-status-icon':
          this.changeCustomerStatus(data);
          break;
      }
    }
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  changeCustomerStatus(data) {
    let objectToSend = {};
    let msg = '';
    if (data.activated === true) {
      objectToSend = {
        'userId': data.userId,
        'activated': false
      };
      msg = this.commonBindingDataService.getLabel('msg_customer_deactivate');
    } else {
      objectToSend = {
        'userId': data.userId,
        'activated': true
      };
      msg = this.commonBindingDataService.getLabel('msg_customer_activate');
    }
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.franchiseService.activeInactiveCustomer(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.applyFilter('apply');
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.params = '';
      // let searchValue = '';
      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
        // searchValue = this.search.trim();
      }
      this.params = `?activated=${this.selectedStatus}`;
      this.postData.activated = (this.selectedStatus === 'true' ? true : false);

      this.updatedUrl = this.gridurl + this.params;
      this.exportCompleteUrl = '/secure/customers/admin/export-xls';
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  exportExcel() {
    this.showGenerateReport = true;
  }

  clickExportFranchise(fromLink) {
    this.showGenerateReport = true;
    this.exportCompleteUrl = fromLink;
  }

  close(status: boolean) {
    this.showGenerateReport = status;
  }

  isRecordsVisible(e) {
    this.isExportVisible = e;
  }


  onAddFranchise() {
    this.router.navigate(['admin/franchise/add-franchise']);
  }

  onSearch(e) {
    const regexStr = '^[a-zA-Z0-9_\b ]*$';
    const str = /[a-zA-Z0-9]/;
    const SHIFT_KEY_CODE = 16;
    const ALT_KEY_CODE = 18;
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === SHIFT_KEY_CODE || e.keyCode === ALT_KEY_CODE) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      if ((this.search && this.search.length >= 3) && !str.test(this.search)) {
 return; 
}
      clearTimeout(this.timer);
      this.postData.searchText = this.search;
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

}
