import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { ErrorDto } from '../models/error-dto';
import { FieldErrorDto } from '../models/field-error-dto';
import { ErrorResponseDto } from '../models/error-response-dto';
import { throwError } from 'rxjs';

import { ConfirmationService, MessageService } from 'primeng/api';

import { CommonMessageTransferService } from '../services/common-message-transfer.service';
import { LoaderService } from './../components/loader/loader.service';
import { UiService } from './ui.service';
import { CommonBindingDataService } from './common-binding-data.service';

export type HandleError = <T>(
  identifier?: string,
  result?: T
) => (error: HttpErrorResponse) => Observable<T>;
@Injectable()
export class HttpErrorHandler {
  constructor(
    private router: Router,
    private messageService: MessageService,
    private loaderService: LoaderService,
    private commonMsgTransferService: CommonMessageTransferService,
    private confirmationService: ConfirmationService,
    private commonService: CommonBindingDataService,
    private uiService: UiService,
  ) { }
  createHandleError = () => <T>(identifier = 'unknown', result = {} as T) =>
    this.handleError(identifier, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param identifier - name of the identifier that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(identifier = 'unknown', result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      this.messageService.clear();
      this.loaderService.hide();
      if (error.status === 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Server Unreachable.',
          detail: 'Sorry, seems like server is unreachable.'
        });
        return throwError(result);
      }

      if (error.status === 500) {
        this.parseFieldErrors(error, identifier, 500);
        // console.log('error',error, error.status);
        // this.router.navigate(['/signin']);
        return throwError(result);
      }

      if (error.status === 403) {
        this.confirmationService.confirm({
          header: 'Unauthorized!',
          message: 'You are not authorized to view this page.',
          accept: () => {
            this.commonService.getUnAuthorized.next(true);
           },
          reject:() => {
            this.commonService.getUnAuthorized.next(true);
           },
          rejectVisible:false,
          acceptLabel: 'Ok',
        });
        return throwError(result);
      }

      if (error.status === 401) {
        this.messageService.add({
          severity: 'error',
          summary: 'Sign in required!',
          detail: 'Please login again to access the application.'
        });
        this.router.navigate(['/signin']);
        return throwError(result);
      }

      if (error.error !== undefined && error.error !== null) {
        if (error.status === 400) {
          this.parseFieldErrors(error, identifier, 400);
          // let errorMsg = error
          // this.messageService.add({
          //   severity: 'error',
          //   summary: 'Sign in required!',
          //   detail: error.error.general[0].message,
          //   sticky:true
          // });
          return throwError(result);
        }
      }

      return throwError(result);
    };
  }

  manageGeneralMsg(generalDto: ErrorDto, code: number) {
    let errorHeader = 'Login Error';
    if (code === 500) {
      errorHeader = 'Server Error';
    }
    if (code === 400) {
      errorHeader = 'Invalid Input';
    }
    this.messageService.add({
      severity: 'error',
      summary: errorHeader,
      detail: generalDto.message,
      // sticky:true
    });
    this.commonMsgTransferService.throwGeneralError(generalDto);
  }

  parseFieldErrors(error: HttpErrorResponse, identifier: string, code: number) {
    const fieldResponse: FieldErrorDto[] = [];
    const keys = Object.keys(error.error);
    let isGeneralError = false;
    keys.forEach(key => {
      if (isGeneralError) {
        return;
      }
      const filedErrors: ErrorDto[] = error.error[key];
      if (key === 'general') {
        isGeneralError = true;
        filedErrors[0].identifier = identifier;
        this.manageGeneralMsg(filedErrors[0], code);
        return;
      } else {
        // isGeneralError = true;
        filedErrors[0].identifier = identifier;
        this.manageGeneralMsg(filedErrors[0], code);
        return;
      }
      const dto: FieldErrorDto = new FieldErrorDto();
      dto.fieldName = key;
      dto.fieldsErrors = filedErrors;
      fieldResponse.push(dto);
    });
    if (isGeneralError) {
      return;
    }
    const errorResponse: ErrorResponseDto = new ErrorResponseDto();
    errorResponse.fieldsErrors = fieldResponse;
    errorResponse.identifier = identifier;
    this.commonMsgTransferService.throwFieldsError(errorResponse);
  }
}
