import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AdminService } from '../../../admin/services/admin.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { StorageService } from '../../services/storage.service';
import { UiService } from '../../services/ui.service';
import { MiGridComponent } from '../mi-grid/mi-grid.component';

@Component({
  selector: 'app-admin-setting-list',
  templateUrl: './admin-setting-list.component.html',
  styleUrls: ['./admin-setting-list.component.scss']
})
export class AdminSettingListComponent implements OnInit {

  // Grid params
  params = '?applyActivatedStatus=&activated=';
  gridDefaultUrl = '/secure/waffle-menu';
  gridurl = `${this.gridDefaultUrl}${this.params}`;
  
  
  updatedUrl = '';
  cols: any[] = [];
  buttons: any[] = [];
  searchBar = 'top';
  isExportVisible = false;
  showGenerateReport: boolean;
  defaultToday = new Date();
  products: any;
  

  @ViewChild(MiGridComponent) gridRef: MiGridComponent;
  // Form params
  generalStatusOptions = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS;
  selectedStatus = AppSettings.GENERAL_STATUS_LIST_FILTER_OPTIONS[1].value;
  postData: any = {
    sortColumn: null,
    sortType: null,
    searchText: '',
    applyActivatedStatus: true,
    activated: this.selectedStatus,
   // toDate: new Date((new Date(new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth(), this.defaultToday.getDate()).setDate(0))).getTime()).setHours(23, 59, 59),
    //fromDate: new Date((new Date(new Date(this.defaultToday.getFullYear(), this.defaultToday.getMonth()-1,1, this.defaultToday.getDate()).setDate(1))).getTime()).setHours(0, 0, 1),
    
  };
  search;
  timer = null;

 

  constructor(
    private router: Router,
    // private formBuilder: FormBuilder,
    private confirmationService: ConfirmationService,
    private storageService: StorageService,
    private commonBindingDataService: CommonBindingDataService,
    // private errorBindingService: ErrorBindingService,
    private uiService: UiService,
    private adminService: AdminService,
  ) { }

  ngOnInit(): void {
    
    this.populateList();

  }

  populateList(){
    this.cols = [];
    this.products = [];
    this.createGrid();
    this.adminService.fetchWaffleMenu().subscribe((result) => {
      this.products = result.data;  
    });
  }

  createGrid() {
    
    this.cols.push({
      field: 'signUrl', width: '190px',
      header: this.commonBindingDataService.getLabel('label_intranet_icon'), sort: false, resize: true, class: 'eclipse',
      htmlRenderer: (data) => {
        const value = data.signUrl;
        const finalValue = `<img src="${value}" style="" class = "menu-offered-img-width" />`;
        return finalValue;
      }
    });

    this.cols.push({
      field: 'menuTitle', width: '190px',
      header: this.commonBindingDataService.getLabel('label_intranet_app_display_name'), sort: false, resize: true, class: 'eclipse',
      
    });

  }

  onActionBtnClicked(type: any,row: any) {
    console.log(type,row);
    
    const data = row;
    
      switch (type) {
        case 'edit':
          this.router.navigate(['admin/manage-waffle/edit-waffle'], { queryParams: { id: data.id } });
          break;
        case 'status':
          this.changeEventStatus(data);
          break;
        case 'delete':
          this.deleteWaffle(data);
          break;
      }
    
  }

  changeEventStatus(data) {
    let objectToSend = {};
    let msg = this.commonBindingDataService.getLabel('msg_waffle_activate');
    if (data.activated) {
      msg = this.commonBindingDataService.getLabel('msg_waffle_deactivate');
    }
    objectToSend = {
      id: data.id,
      activated: !data.activated,
    };

    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.adminService.activeInactiveWaffle(objectToSend).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.populateList();
          
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  deleteWaffle(data) {
    const objectToSend = {};
    const msg = this.commonBindingDataService.getLabel('msg_waffle_delete');
    
    this.confirmationService.confirm({
      message: msg,
      accept: () => {
        this.adminService.deleteWaffle(data.id).subscribe((result) => {
          this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
          this.populateList();
        }, error => {
          console.log(error);
        }
        );
      }
    });
  }

  onClickFilter() {
    this.applyFilter('apply');
  }

  applyFilter(event?) {
    if (event === 'apply') {
      this.params = '';

      if (this.search !== undefined && this.search !== null) {
        this.gridRef.searchText = this.search.trim();
      }
      if (this.selectedStatus) {
        this.params = `?applyActivatedStatus=true&activated=${this.selectedStatus}`;
        this.postData.applyActivatedStatus=true;
        this.postData.activated= this.selectedStatus;
      } else {
        this.params = `?applyActivatedStatus=false&activated=${this.selectedStatus}`;
        this.postData.applyActivatedStatus=false;
        this.postData.activated= this.selectedStatus;
      }
      this.updatedUrl = this.gridDefaultUrl + this.params;
     // this.exportCompleteUrl = this.exportBaseUrl + this.params;
      
      this.gridRef.updateUrl(this.updatedUrl);
    }
  }

  exportExcel() {
   // this.gridRef.exportGridExcel();
    this.showGenerateReport = true;
  }

  isRecordsVisible(e) {
    this.isExportVisible = e;
  }
  clickExportFranchise(fromLink) {
    this.showGenerateReport = true;
    
    //this.franchiseService.downloadExcelAdminReport(fromLink);
  }

  close(status: boolean) {
    this.showGenerateReport = status;
  }

  onAddWaffle() {
    this.router.navigate(['admin/manage-waffle/add-waffle']);
  }

  onSearch(e) {
    const qoutes = `'"`;
    const regexStr = '^[-a-zA-Z0-9_@.!*?,()^'+qoutes+' ]*$';
    if (!new RegExp(regexStr).test(e.key) || e.keyCode === 16 || e.keyCode === 18) {
      return;
    }
    if ((this.search && this.search.length >= 3) || this.search.length === 0) {
      if (this.search.length >= 3 && this.search.trim() === '') {
        return;
      }
      clearTimeout(this.timer);
      this.postData.searchText = this.search;
      this.timer = setTimeout(() => this.applyFilter('apply'), AppSettings.SEARCH_DELAY_INTERVAL);
    }
  }

  onRowReorder(){

    for (let index = 0; index < this.products.length; index++) {
      this.products[index].indexOrder = index;
      delete this.products[index].signUrl;
      delete this.products[index].signUrlExpiry;
    }

    const data = {};
    data['waffleMenuList'] = this.products;
    console.log(data);
    this.adminService.updateWaffleMenuIndexOrder(data).subscribe((result) => {
      this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
      this.populateList();
    });

  }

}
