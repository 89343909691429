
<div class="page-container">
    <div class="page-header">
      <div class="page-heading">
        <h1 class="heading" translate>Extras Category</h1>
      </div>
      <div class="page-actions">

        <button pButton appAccessProvider accessCode="MANAGE_MENU" [readonly] = "true" [readWrite] = "true" (click)="onAdd()" class = "large-btn" type="button" [label]="'label_food_extra_add_category'|translate"></button>
      </div>
    </div>

    <div class="page-filters">
      <div class="filter-left-container grid">
        <div class="filter-item col-2">
          <div class="form-control input-container">
             <span class="p-input-icon-left">
               <i class="pi pi-search"></i>
               <input type="text" pInputText (keyup)= "onSearch($event)" [(ngModel)] = "search" class="search-input prefix-pad"
               [placeholder]="'label_search'|translate" appPreventSpecialCharacters pTooltip="{{'label_search_food_extra_cat_text' | translate}}">
             </span>
          </div>
        </div>
        <div class="filter-item col-6 lg:col-2 md:col-3 "  *ngIf="isAdminLogin">
          <div class="form-control input-container " >
            <p-multiSelect  #brandButton [options]="brandNameOptions" (onChange)="onSelectBrand($event)" [(ngModel)]="selectedBrandFromFilter"
              [maxSelectedLabels]="1" [showHeader]="false" [filter]="false" selectedItemsLabel="{0} Brands selected" placeholder="Select Brand" >
            </p-multiSelect>
          </div>
        </div>
      </div>
    </div>

    <div class="page-body">
      <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
          (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" [isExportVisible]="false"
          divClass="data-grid-lines" [searchBar]="searchBar"
          [uniqueId] = "uniqueId" [fromCancelOffset]="saveFromCancelOffset"
          [isInputBrandId] = "franchiseLogin">
      </app-mi-grid-v2>
    </div>

</div>

<!-- <div class="layout-dashboard page-layout">
    <div class = "grid padding-1per">
        <div class = "col-12 lg:col-6 md:col-6">
            <h1 class="header" translate>label_manage_food_extra</h1>
        </div>
        <div class="col-12 lg:col-6 md:col-6 p-justify-end page-buttons">
            <button pButton (click)="onAdd()" class = "marg-t-0" type="button" [label]="'label_food_extra_add_category'|translate"></button>
        </div>
    </div>

    <div class="grid form-container filter-container padding-1per pad-t-b-0">
        <div class="col-12 lg:col-3 md:col-5 form-field">
            <div class="form-label">
                <label for="companyId" class="list-filter-lbl" translate>label_search</label>
            </div>
            <div class="form-control filter-drop-down position-rel">
                <input (keyup)= "onSearch($event)" [(ngModel)] = "search"  class="search prefix-pad" type="text"
                 [placeholder]="'label_search_food_extra_cat_text'|translate" appNoWhiteSpace  pInputText appPreventSpecialCharacters />
                <label for="search-bar" class="fa fa-search input-icon search-icon"></label>
            </div>
        </div>
    </div>

    <app-mi-grid-v2 [url]="gridurl" [columnDefs]="columns"
        (actionBtnClickedEvent)="onActionBtnClicked($event)" [actionButtonsDefs]="buttons" rowSelection="multiple"
        divClass="mi-grid" [exportUrl]="exportBaseUrl" ></app-mi-grid-v2>

</div> -->
