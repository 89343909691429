import * as moment from 'moment';

import { AppSettings } from './../../shared/app.settings';
import { Injectable } from '@angular/core';
// import { RestApiService } from '../../shared/services/rest-api.service';
import { StorageService } from './../../shared/services/storage.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Injectable()
export class CommonBindingDataService {
  defaultSystemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  public isProfileUpdated = new Subject<any>();
  public isTermsclickFromCustomer = new Subject<any>();
  public getUnAuthorized = new Subject<any>();
  // public timeZone = 'Pacific/Honolulu';
  // public timeZone = this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE)?JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE)):this.defaultSystemTimeZone;
  public timeZone = (this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE) && this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE) != "undefined")
    ?JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE)):this.defaultSystemTimeZone;
  loginData;
  permissionsArray;
  sidebarItems = [
    { routerLink: ['franchise/dashboard-info'], title: "MANAGE_DASHBOARD" },
    { routerLink: ['franchise/client'], title: "MANAGE_CLIENT" },
    { routerLink: ['franchise/events'], title: "MANAGE_EVENT" },
    { routerLink: ['franchise/events'], title: "MANAGE_TASK" },
    { routerLink: ['franchise/events'], title: "ASSIGN_STAFF_TO_TASK" },
    { routerLink: ['franchise/events'], title: "ASSIGN_EQUIPMENT_TO_TASK" },
    { routerLink: ['franchise/menu-section/templatelist'], title: "MANAGE_MENU" },
    { routerLink: ['franchise/assets'], title: "MANAGE_EQUIPMENT" },
    { routerLink: ['franchise/staff/manage'], title: "MANAGE_STAFF" },
    { routerLink: ['franchise/reports/orders'], title: "MANAGE_REPORT" },
    { routerLink: ['franchise/settings'], title: "MANAGE_SETTINGS" },
  ];


  constructor(
    private router: Router,
    private storageService: StorageService,
    private translateService: TranslateService,
  ) {
    // this.setGoogleMapScript();
  }

  getSelectedMenuAccessesList() {
    this.permissionsArray = this.storageService.getItem('permissionArray');
    return this.permissionsArray;
  }

  checkIfHavePermission(accessCodeParam, readOnlyParam?, readWriteParam?) {
    const roleCode = this.getLoginRole();
    let hasAccess = true;
    // console.log('roleCode',roleCode)
    if (roleCode == AppSettings.USER_ROLE_MANAGER && accessCodeParam) {
      const permitionsArray = JSON.parse(this.getSelectedMenuAccessesList());
      let accessObj;
      if (readWriteParam) {
        accessObj = permitionsArray.find((eachEntry) => (eachEntry.accessCode == accessCodeParam &&
          eachEntry.readonly == readOnlyParam && eachEntry.readWrite == readWriteParam));
      } else {
        accessObj = permitionsArray.find((eachEntry) => (eachEntry.accessCode == accessCodeParam &&
          eachEntry.readonly == readOnlyParam));
      }
      if(accessObj){
        hasAccess = true;
      }else{
        hasAccess = false;
      }
      // accessObj ? hasAccess = true : hasAccess = false;
    }
    return hasAccess;
  }

  checkHasAccessOrNot(role) {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      return true;
      if (role === (data.roles[0].roleName) || data.roles[0].roleName == 'Admin') {
        return true;
      } else {
        this.router.navigate(['/signin']);
        return false;
      }
    }
  }

  getLoginRole() {
    this.loginData = this.storageService.getItem(AppSettings.USER_DETAILS_LOGIN_DATA);
    if (this.loginData) {
      const data = JSON.parse(this.loginData);
      if (data.roles[0].roleName == AppSettings.USER_ROLE_ADMIN) {
        return AppSettings.USER_ROLE_ADMIN;
      } if (data.roles[0].roleName == AppSettings.USER_ROLE_FRANCHISE) {
        return AppSettings.USER_ROLE_FRANCHISE;
      } if (data.roles[0].roleName == AppSettings.USER_ROLE_MANAGER) {
        return AppSettings.USER_ROLE_MANAGER;
      }
    } else {
      return null;
    }
  }

  openCloseSidebar() {
    if (screen.width < 740) {
      if (!document.getElementsByTagName('body')[0].classList.contains('closed')) {
        document.getElementsByTagName('body')[0].classList.toggle('closed');
      }
    }
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  startOfDayToGMT(e) {
    const year = e.getFullYear();
    const month = e.getMonth();
    const day = e.getDate();
    const dateFromStart = new Date(year, month, day, 0, 0, 0, 0);
    return new Date(dateFromStart.getTime() - (dateFromStart.getTimezoneOffset() * 60000)).getTime();
  }
  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  mapToDropDownState(list, state, alias, id) {
    return list.map((data) => ({ label: data[state] + ', ' + data[alias], value: data[id] }));
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }

  //** convert new Date() to TZ date */
  convertedTimeAsPerTz(inputTimeParam?) {
    // this.timeZone = this.timeZone? this.timeZone: JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // let tz = this.timeZone
    const tz = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // console.log('timeZone',tz);
    const inputTime = inputTimeParam ? inputTimeParam : new Date();
    const requireDateTime = (new Date(inputTime).toLocaleString('en', { timeZone: tz }));
    return requireDateTime;
  }

  //** convert utcTimestamp to TZtimeStamp or date*/
  convertedTimeAsPerTz1(inputTimeParam?, form?) {
    // this.timeZone = this.timeZone? this.timeZone: JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // let tz = this.timeZone;
    const tz = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // console.log('timeZone1',tz);
    const inputTime = inputTimeParam ? inputTimeParam : new Date();
    const utcDate = new Date(new Date(inputTime).toLocaleString('en-US', { timeZone: tz }));
    return utcDate;
  }

   convertedUTCTime(inputTimeParam) { // input dateTime and get converted TZ timestamp
    const browsertz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let returnVal;
    // let tz = this.timeZone?this.timeZone:JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    const tz = JSON.parse(this.storageService.getItem(AppSettings.USER_DETAILS_PROFILE_TIMEZONE));
    // console.log('timeZone2',tz);
    const machineTzOffset = this.getMachineAndTzOffset(browsertz,tz,inputTimeParam);
    
    if(machineTzOffset < 0){
      // console.log('machineTzOffset < 0',machineTzOffset);
      returnVal = inputTimeParam.getTime() + (machineTzOffset * 60000);
    }else {
      // console.log('machineTzOffset > 0',machineTzOffset);
      returnVal = inputTimeParam.getTime() + (machineTzOffset * 60000);
    }
    return returnVal;
  }

  getOffset(timeZone = 'UTC', date = new Date()) {
    const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
    const tzDate = new Date(date.toLocaleString('en-US', { timeZone }));
    return (tzDate.getTime() - utcDate.getTime()) / 6e4;
  }

  getMachineAndTzOffset(timeZone1,timeZone2,inputTimeParam?) {
    const date = inputTimeParam?new Date(inputTimeParam): new Date();
    const tz1 = new Date(date.toLocaleString('en-US', { timeZone: timeZone1 }));
    const tz2 = new Date(date.toLocaleString('en-US', { timeZone: timeZone2 }));
    return (tz1.getTime() - tz2.getTime()) / 6e4;
  }


  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }


  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  telFormat(value) {
    if (value) {
      return this.removeChar(value).replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
    } else {
      return '';


    }
  }
  removeChar(str: string) {
    return str.replace(/-/gi, '');
  }

  getState(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.name,
          value: item.stateId
        });
      }
    }
    return states;
  }


  getAgeGroup(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.ageGroupLabel,
          value: item.ageGroupId
        });
      }
    }
    return states;
  }

  getCities(response) {
    const cities = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        cities.push({
          label: item.name,
          value: item.cityId
        });
      }
    }
    return cities;
  }

  getDosageDays(response) {
    const medicationDosageDays = [];
    if (response) {
      for (const item of response) {
        medicationDosageDays.push({
          label: item.medicationDosageDayLabel,
          value: item.medicationDosageDayId
        });
      }
    }
    return medicationDosageDays;
  }

  getDosageUnits(response) {
    const medicationDosageUnits = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        medicationDosageUnits.push({
          label: item.medicationDosageUnitText,
          value: item.medicationDosageUnitId
        });
      }
    }
    return medicationDosageUnits;
  }

  getAnswerType(response) {
    const answerType = [];
    if (response) {
      for (const item of response) {
        answerType.push({
          label: item.answerTypeLabel,
          value: item.answerTypeId
        });
      }
    }
    return answerType;
  }

  getMedicatinDosageSkipReasons(response) {
    const medicationSkipReasons = [];
    if (response) {
      for (const item of response) {
        medicationSkipReasons.push({
          label: item.dosageSkipReason,
          value: item.dosageSkipReasonId
        });
      }
    }
    return medicationSkipReasons;
  }

  getMedicationFreqeuncies(response) {
    const medicationFrequencies = [{
      label: this.getLabel('lbl_please_select'),
      value: {}
    }];
    if (response) {
      for (const item of response) {
        medicationFrequencies.push({
          label: item.medicationDosageFrequencyLabel,
          value: {
            medicationDosageFrequencyId: item.medicationDosageFrequencyId,
            defaultTimes: item.defaultTimes,
          }
        });
      }
    }
    return medicationFrequencies;
  }

  msToHM(milliseconds: number) {
    // 1- Convert to seconds:
    let seconds = milliseconds / 1000;
    // 2- Extract hours:
    const hours = seconds / 3600;  // 3,600 seconds in 1 hour
    seconds = seconds % 3600; // seconds remaining after extracting hours
    // 3- Extract minutes:
    const minutes = seconds / 60; // 60 seconds in 1 minute
    // 4- Keep only seconds not extracted to minutes:
    seconds = seconds % 60;
    return hours + ':' + minutes;
  }

  msToTime(duration) {
    // const milliseconds: any = Math.floor((duration % 1000) / 100),
    let seconds: any = Math.floor((duration / 1000) % 60),
      minutes: any = Math.floor((duration / (1000 * 60)) % 60),
      hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    return hours;
    // return hours + ":" + minutes + ":" + seconds + "." + milliseconds;
  }

  msToHHMM(duration) {
    // const milliseconds: any = Math.floor((duration % 1000) / 100),
    const seconds: any = Math.floor((duration / 1000) % 60);
    let  minutes: any = Math.floor((duration / (1000 * 60)) % 60),
      hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    return hours + ":" + minutes;
  }

  msToDay(duration) {
    // const milliseconds: any = Math.floor((duration % 1000) / 100),
    /*  let seconds: any = Math.floor((duration / 1000) % 60),
      minutes: any = Math.floor((duration / (1000 * 60)) % 60),
      hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24),
      day:any= Math.floor((duration / (1000 * 60 * 60 *24))  *1);

    hours = (hours < 10) ? '0' + hours : hours;
    minutes = (minutes < 10) ? '0' + minutes : minutes;
    return day; */
    const day = moment().subtract(duration, "days").unix();
    return day;

  }


  msToHHMMSSWithName(duration) {
    // const milliseconds: any = Math.floor((duration % 1000) / 100),
    let seconds: any = Math.floor((duration / 1000) % 60),
      minutes: any = Math.floor((duration / (1000 * 60)) % 60);
    const  hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    minutes = (minutes < 10) ? '0' + minutes : minutes;
    seconds = (seconds < 10) ? '0' + seconds : seconds;
    return hours + "h " + minutes + "m " + seconds + "s";
  }

  msToHHMMWithName(duration) {
    // const milliseconds: any = Math.floor((duration % 1000) / 100),
    // let seconds: any = Math.floor((duration / 1000) % 60),
    let minutes: any = Math.floor((duration / (1000 * 60)) % 60);
    const hours: any = Math.floor((duration / (1000 * 60 * 60)) % 24);

    minutes = (minutes < 10) ? '0' + minutes : minutes;
    return hours + "h " + minutes + "m";
  }

  timeToMilliseconds(mins, hh) {
    return (mins * 60000) + (hh * 60 * 60000);
  }

  hrsToMiliseconds(hh) {
    return (hh * 60 * 60000);
  }
  hrsToMM(hh) {
    return hh * 60;
  }
  mmToMilliseconds(mins) {
    return (mins * 60000);
  }

  mmToMinutes(mins) {
    return (mins / 60000);
  }

  gmtTOHHMM(time) {
    return (moment(parseInt(time, 10)).utc().format('HH:mm'));
  }

  getTimeFromMM(date) {
    const pad = num => ("0" + num).slice(-2);
    const hours = date.getHours(),
      minutes = date.getMinutes(),
      seconds = date.getSeconds();
    return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds);
  }

  duplicatesFromArray(arr) {
    const sorted_arr = arr.slice().sort();
    const results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  close(event: any) {
    const divsToHide = document.getElementsByClassName('grid-menu');
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i]['style'].display = 'none';
    }
  }

  weekOfTheDay(start) {
    return moment(start).startOf('week').format('MMM D') + '-' + moment(start).endOf('week').format('MMM D');
  }


  millisecondsToTime(scheduledTime) {
    scheduledTime = parseInt(scheduledTime, 10);
    const hh = parseInt(moment(scheduledTime).utc().format('HH'), 10);
    const mm = parseInt(moment(scheduledTime).utc().format('mm'), 10);
    return hh + ':' + mm;
  }


  setWeeklyDays(startDate: number, endDate: number) {
    const tz = moment().utcOffset();
    const weekly = [];
    weekly.push({
      label: this.getLabel('lbl_trial_period'),
      value: {
        start: startDate,
        end: endDate
      }
    }, {
      label: this.weekOfTheDay(startDate),
      value: {
        start: moment(startDate).utc().startOf('week').valueOf(),
        end: moment(startDate).utc().endOf('week').valueOf()
      }
    });

    let start = moment(startDate).utc().startOf('week').add(7, 'd').valueOf();
    while (start < endDate) {
      weekly.push({
        label: this.weekOfTheDay(start),
        value: {
          start: moment(start).utc().startOf('week').valueOf(),
          end: moment(start).utc().endOf('week').valueOf()
        }
      });

      start = moment(start).utc().startOf('week').add(7, 'd').valueOf();
    }
    return weekly;
  }

  getUTCDateWithLocalTime(time, toDate?) {
    if (toDate) {
      // var dateString = moment.unix(toDate).format("M/D/YYYY");
      // var d = new Date(toDate);
      const startDate = moment(time).format('L');
      const endDate = moment(toDate).format('L');
      if (startDate === endDate) {
        return moment(toDate).format('hh:mmA');
      } else {
        return moment(toDate).format('M/D/YYYY hh:mmA');
      }
    } else {
      return moment(time).format('M/D/YYYY hh:mmA');
    }
  }

  getDateInFormat(date) {
    return moment(date).format('M/D/YYYY hh:mmA');
  }

  getDateTimeLocalFormat(date) {
    return moment(date).format('MMM DD, YYYY hh:mm A');
  }

  getGMTDate(time) {
    return moment(time).format('MM/DD/YYYY');
  }

  getUTCShortDateWithLocalTime(start, toDate?) {
    const d = moment(start).format('MMM DD, YYYY');
    const st = moment(start).format('h:mm A');
    const et = moment(toDate).format('h:mm A');
    const obj = {
      date: d,
      startTime: st,
      endTime: et
    };
    return obj;
  }

  getLocalDateTime(time) {
    const date = moment(time).format('MMM DD, YYYY hh:mmA');
    return date;
  }

  // new funtion added for show GMT date as it is
  getUTCDate(time) {
    const date = moment.utc(time).format('MMM DD, YYYY');
    return date;
  }

  getUTCDateTime(time) {
    const tz = moment().utcOffset();
    time = time - (tz * 60000);
    return moment(time).format('MMM DD, YYYY h:m A');
  }

  getUTCDateWithOutLocalTime(time) {
    const tz = moment().utcOffset();
    time = time - (tz * 60000);
    return moment(time).format('M/D/YYYY h:mA');
  }

  getUTCTimeWithLocalTime(time) {
    return moment(time).format('hh:mmA');
  }

  addLapseTimeInEndDate(endDate) {
    return endDate + AppSettings.twentyThreeFiftyNineLapse;
  }

  getAdress(address, city, state, zip) {
    const requiredAddressArray = [];
    if (address) {
      requiredAddressArray.push(address);
    }
    if (city) {
      requiredAddressArray.push(city);
    }
    if (state) {
      requiredAddressArray.push(state);
    }
    if (zip) {
      requiredAddressArray.push(zip);
    }
    const requiredString = requiredAddressArray.join(', ');
    return requiredString;
  }

  findEqualDateTime(date1, date2) {

    if (date1.getTime() >= date2.getTime()) {
      return true;
    }
    // if (this.millisecondsToLocalTime(date1.getTime()) === this.millisecondsToLocalTime(date2.getTime())) {
    //   return true;
    // }
    return false;
  }

  millisecondsToLocalTime(scheduledTime) {
    scheduledTime = parseInt(scheduledTime, 10);
    const hh = parseInt(moment(scheduledTime).format('HH'), 10);
    const mm = parseInt(moment(scheduledTime).format('mm'), 10);
    return hh + ':' + mm;
  }

  endOfDayToGMT(e) {
    const year = e.getFullYear();
    const month = e.getMonth();
    const day = e.getDate();
    return new Date(year, month, day, 23, 59, 59, 999);
  }

  subtractDays(givenDate, numberOfdays) {
    const minusDate = moment(givenDate).subtract(numberOfdays, 'day');
    return new Date(minusDate.toISOString());
  }

  addDays(givenDate, numberOfdays) {
    const addDate = moment(givenDate).add(numberOfdays, 'day');
    return new Date(addDate.toISOString());
  }

  setSecondsToZeroAddOneMin(givenDate) {
    const date = moment(givenDate).add(1, 'minutes').toDate();
    date.setSeconds(0);
    return date;
  }

  getMinTime(arr) {
    const sortedArr = Math.min(...this.getSortedTime(arr));
    return sortedArr;
  }

  getMaxTime(arr) {
    const sortedArr = Math.max(...this.getSortedTime(arr));
    return sortedArr;
  }

  getSortedTime(arr) {
    const returnArray = [];
    arr.forEach(element => {
      const x = element.split(':');
      const obj = {
        hh: x[0],
        mm: x[1]
      };
      const sec = x[2].split(' ');
      if (sec[1] == 'PM') {
        obj.hh = Number(obj.hh) + 12;
      }
      const y = new Date();
      y.setHours(obj.hh, obj.mm, 0);
      returnArray.push(y.getTime());
    });
    return returnArray;
  }

  getAssetAndStaffList(item, listType?, singleAsset?) { // for detail popup from events
    let assetList = item.assetsList && item.assetsList.length > 0 ? item.assetsList.map((item) => ' ' + item.assetName).toString() : 'No Equipment Assigned';
    const staffList = item.staffsList && item.staffsList.length > 0 ? item.staffsList.map((item) => ' ' + item.firstName + ' ' + item.lastName).toString() : 'No Staff Assigned';

    const list = 'Staff List: ' + staffList + ' \n' + 'Equipment List: ' + assetList;
    if (singleAsset) {
      assetList = singleAsset;
    }
    if (listType == 'asset') {
      return 'Equipment List: ' + assetList;
    } else if (listType == 'staff') {
      return 'Staff List: ' + staffList;
    } else {
      return list;
    }
  }

  createPopupHeading(item) {  // for detail popup from events
    let startDate: any = new Date(item.startDateTime);
    startDate = startDate.toLocaleString('en-US', {
      day: 'numeric', month: 'short', year: 'numeric',
      hour: 'numeric', minute: 'numeric', hour12: true
    });
    const name = item.name + ' | ' + startDate;
    return name;
  }

  checkValidTimeSlotForAddEvent(startTime, endTime, timeSlot) {  // For add/edit event validation
    const minutesDiff = ((new Date(endTime).getTime() - new Date(startTime).getTime()) / (1000 * 60));
    if (minutesDiff >= timeSlot) {
      return true;
    } else {
      return false;
    }
  }

  getStartEndOfDates(firstDate, secoundDate) {  // For add/edit event validation
    const startOfFirstDate = firstDate.setHours(0, 0, 0);
    const endToDate = secoundDate.setHours(23, 59, 59);
    return [startOfFirstDate, endToDate];
  }

  clearTemplateStorage() {
    this.storageService.removeItem(AppSettings.MENU_ITEM_SELECTED);
    this.storageService.removeItem(AppSettings.FOOD_EXTRA_FOR_MENU_ITEM_SELECTED);
    this.storageService.removeItem(AppSettings.MENU_ITEMS_SELECTED_FOR_TEMPLATE);
    this.storageService.removeItem(AppSettings.MENU_ITEMS_FOR_TEMPLATE);
    this.storageService.removeItem(AppSettings.TEMPLATE_FORM_RAW_DATA);
  }

  sortItems(array, stringKey) {
    array.sort((a, b) => {
      if (a[stringKey] && b[stringKey]) {

        const fa = a[stringKey].toLowerCase(),
          fb = b[stringKey].toLowerCase();
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
      }
      return 0;
    });
    return array;
  }

  sameDay(d1, d2) {
    if (d1 && d2) {
      return d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate();
    }
  }

  getNextRoundUp(date?) {
    const start = moment(date);
    const remainder = 15 - (start.minute() % 15);
    const dateTime = moment(start).add(remainder, 'minutes');
    const startDate = dateTime['_d'];
    return startDate;
  }

}

