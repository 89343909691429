import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { FranchiseService } from '../../../franchise/services/franchise-service.service';
import { AppSettings } from '../../app.settings';
import { CommonBindingDataService } from '../../services/common-binding-data.service';
import { ErrorBindingService } from '../../services/error-binding.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-shared-segment-editable',
  templateUrl: './shared-segment-editable.component.html',
  styleUrls: ['./shared-segment-editable.component.css']
})
export class SharedSegmentEditableComponent implements OnInit {

  @Input() fromComponent: any;
  eventDetails;
  eventForm: UntypedFormGroup;
  pageHeading = this.commonService.getLabel('label_add_segment');

  submitted = false;
  customerRole: any;
  roles: any[] = [];
  users: any[] = [];
  status: any[] = [];
  showOrderCount = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private errorBindingService: ErrorBindingService,
    private franchiseService: FranchiseService,
    private commonService: CommonBindingDataService,
    private confirmationService: ConfirmationService,
    private uiService: UiService,
  ) { }

  ngOnInit(): void {
    this.getRoles();
    this.status = this.getsegmentStatus();
    this.initForm();
  }

  getsegmentStatus() {
    return [
      { label: 'All', value: 'All' },
      { label: 'Active', value: 'Active' },
      { label: 'Deactive', value: 'InActive' },
    ];
  }

  getRoles() {
    this.franchiseService.getUserDefinedRoles().subscribe(
      (result) => {
        this.customerRole = result.filter(e => e.roleCode === 'Customer');
        this.roles = (result) ? result.map(response => ({ label: response.roleName, value: response.id })) : [];
        this.roles.unshift({ label: this.commonService.getLabel('Select'), value: '' });
      }, (error) => {
        console.log(error);
      });
  }

  getUserList(roleId) {
    this.showHideOrders();
    if (roleId !== '' && roleId !== null) {
      this.OnClearUser();
      // const isActivated = this.getActivatedStatus(activated);
      const ordercount = this.eventForm.controls.orderCount.value ? this.eventForm.controls.orderCount.value : '';

      if ((this.showOrderCount && ordercount !== '') || !this.showOrderCount) {
        this.franchiseService.getUsers(roleId, ordercount).subscribe(
          (result) => {
            this.users = (result) ? result.map(response => (
              { label: `${response.firstName} ${response.lastName}`, value: response.id }
            )) : [];
          }, (error) => {
            console.log(error);
          });
      }
    }
  }

  showHideOrders() {
    if (this.customerRole[0].id === this.eventForm.controls.roleId.value) {
      this.showOrderCount = true;
      this.eventForm.controls.orderCount.setValidators([Validators.required]);
    } else {
      this.showOrderCount = false;
      this.eventForm.controls.orderCount.setValue('');
      this.eventForm.controls.orderCount.setValidators(null);
    }
    this.eventForm.controls.orderCount.updateValueAndValidity();
  }

  getActivatedStatus(activated) {
    let isActivated = activated;
    if (isActivated !== 'All') {
      isActivated = (isActivated === 'Active');
    } else {
      isActivated = '';
    }
    return isActivated;
  }

  OnClearUser() {
    // const formControl = this.eventForm.controls;
    this.eventForm.controls.userIds.setValue([]);
  }

  initForm() {
    this.eventForm = this.formBuilder.group({
      segmentName: [(this.eventDetails && this.eventDetails.segmentName) ? this.eventDetails.segmentName : '',
      [Validators.required, Validators.minLength(1), Validators.maxLength(50)]],
      roleId: [(this.eventDetails && this.eventDetails.roleId) ? this.eventDetails.roleId : '', [Validators.required]],
      userIds: [(this.eventDetails && this.eventDetails.userIds) ? this.eventDetails.userIds : '', [Validators.required]],
      userStatus: [(this.eventDetails && this.eventDetails.userStatus) ? this.eventDetails.userStatus : 'All', [Validators.required]],
      orderCount: [(this.eventDetails && this.eventDetails.orderCount) ? this.eventDetails.orderCount : ''],
    });
  }

  onSave() {
    // const formValues = this.eventForm.getRawValue();
    this.submitted = true;
    if (this.eventForm.valid) {
      const eventModel = this.getFormData();
      this.confirmationService.confirm({
        message: this.commonService.getLabel('msg_segment_add'),
        accept: () => {
          this.franchiseService.addSegment(eventModel).subscribe((result) => {
            this.uiService.showMessage(result.general[0].message, AppSettings.MESSAGE_TYPE.SUCCESS);
            this.goToPrevious();
          }, (error) => {
            console.log(error);
          });
        }
      });
    } else {
      this.errorBindingService.getFormValidationErrors(this.eventForm);
    }
  }

  getFormData() {
    const formControl = this.eventForm.getRawValue();
    const editableEventModel: any = {
      roleId: formControl.roleId,
      userStatus: formControl.userStatus,
      userIds: formControl.userIds,
      segmentName: formControl.segmentName,
      orderCount: formControl.orderCount ? formControl.orderCount : '',
    };
    return editableEventModel;
  }

  onCancel() {
    this.goToPrevious();
  }

  goToPrevious() {
    if (this.fromComponent === 'admin') {
      this.router.navigate(['admin/marketing/segments']);
    } else {
      this.router.navigate(['franchise/marketing/segments']);
    }
  }

  goBack() {
    window.history.back();
  }

}

