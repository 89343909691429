import { Component } from '@angular/core';

@Component({
  selector: 'app-footer-ccpa-content',
  templateUrl: './footer-ccpa-content.component.html',
})
export class FooterCcpaContentComponent {

  constructor() { }
}
